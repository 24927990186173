import dayjs from "dayjs"
import { DEFAULT_VAT, PAYMENT_OPTIONS } from "../../types/invoiceTypes"
import { Item, defaultItem } from "../../types/itemTypes";
import type { Invoice } from "../../types/invoiceTypes";
import { Currency } from "../../../helpers/MoneyHelper";

export interface InitialInvoiceState extends Invoice {
	currentItem: Item;
	status: any;
	stateValid: any;
	validation: any;
}

const INITIAL_STATE: InitialInvoiceState = {
    items: [],
    currentItem: defaultItem({}),
    totalAmount: 0,
    netAmount: 0,
    paymentOptions: { ...PAYMENT_OPTIONS },
    totalVAT: {...DEFAULT_VAT},
    reference: '',
    paymentTerms: 7,
    invoiceDate: dayjs().unix(),
    dueDate: dayjs().add(7, 'days').unix(),
    invoiceNumber: '',
    currency: Currency.SEK,
	invoiceURL: '',
    customer: '',
    user: '',
    id: '',
    status: false,
	invoiceTheme: 'pinkOrange',
	stateValid: true, // Don'' save
	validation: { }, // Don't save
}

export const invoiceState = () => INITIAL_STATE