import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal';
import { Grid, IconButton, Typography, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TextButton from '../buttons/TextButton';

interface StyledModalProps {
}

const StyledModal = styled(Modal)<StyledModalProps>(({theme}) => ({
	color: '#fff',
	zIndex: theme.zIndex.drawer + 1,
	justifyContent: "center",
	alignItems: 'center',
	display: 'flex',
}))

interface StyledBoxProps {
	transparent: boolean;
	coverFull: boolean;
 }

const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'transparent' && prop !== 'coverFull',
})<StyledBoxProps>(({theme, transparent, coverFull}) => ({
	boxSizing: 'border-box',
	backgroundColor: transparent ? 'rgba(255,255,255,0.7)' : theme.palette.background.default,
	padding: '0 20px 20px 20px',
	display: 'flex',
	position: 'relative',
	justifyContent: 'flex-start',
	flexDirection: 'column',
	[theme.breakpoints.up('sm')]: {
		width: '400px',
		minHeight: '300px',
		borderRadius: '60px',
	},
	[theme.breakpoints.down('sm')] : {
		height: '100%',
		width: '100%',
	}
}))

const StyledGrid = styled(Grid)(({theme}) => ({
	height: '56px',
	marginLeft: '-20px',
	marginRight: '-20px',
	width: 'calc(100% + 40px)',
	padding: '0 9px',
}))

const ContentBox = styled(Box)(({theme}) => ({
	display: 'flex',
	flex: '1 1',
	flexDirection: 'column',
	justifyContent: 'center',
}))

const StyledIconButton = styled(IconButton)(({theme}) => ({
	position: 'absolute',
	top: '-25px',
	right: '-25px',
	backgroundColor: theme.palette.grey[300],
	borderRadius: '50%',
	['&:hover']:{
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	[theme.breakpoints.down("sm")]:{
		display: 'none',
	},
}))

interface CloseButtonProps {
	handleClose: HandleCloseFunc;
}

const RoundCloseButton = ({ handleClose }: CloseButtonProps) => {
	return (
		<StyledIconButton onClick={() => handleClose()}>
			<CloseIcon />
		</StyledIconButton>
	)
}

const StyledTextButton = styled(TextButton)(({theme}) => ({
	[theme.breakpoints.up('sm')]: {
		display: 'none',
	}
}))

const BackButton = ({ handleClose}: CloseButtonProps) => {
	return (
		<StyledTextButton onClick={handleClose}>Back</StyledTextButton>
	)
}

type HandleCloseFunc = () => void;

interface CoverModalProps extends PropsWithChildren {
	isOpen: boolean;
	title?: string;
	transparent?: boolean;
	showBack?: boolean;
	coverFull?: boolean;
	onClose: () => void;
}

const CoverModal = ({
	isOpen = false,
	showBack = true,
	transparent = false,
	coverFull = false,
	title,
	onClose = () => {},
	children,
}: CoverModalProps) => {

    const handleClose = () => {
		onClose()
    }

    return(
        <StyledModal open={isOpen} onClose={handleClose} >
			<StyledBox transparent={transparent} coverFull={false}>
				{ showBack && <RoundCloseButton handleClose={handleClose} /> }
				<StyledGrid container alignItems={'center'}>
					{ showBack && <Grid item xs={3}><BackButton handleClose={handleClose} /></Grid> }
					{ title && <Grid item xs={6}><Typography variant='h5' align="center">{title}</Typography></Grid> }
				</StyledGrid>
				<ContentBox>
					{ children }
				</ContentBox>
			</StyledBox>
        </StyledModal>
    )
}

export default CoverModal