import { Email } from "../../types/emailTypes"
import { ApiStatus } from "../../types/storeTypes";

interface InitialEmailState {
	emails: Email[];
	status: ApiStatus;
}

export const INITIAL_EMAIL_STATE: InitialEmailState = {
	emails: [],
	status: ApiStatus.SUCCESS,
}