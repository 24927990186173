import { Box, styled } from "@mui/material"
import { PropsWithChildren } from "react"

const SlideBox = styled(Box)(({theme}) => ({
	flex: '0 0 auto',
	marginRight: '16px',
	[theme.breakpoints.down("sm")]: {
		flex: '0 0 auto',
		marginRight: '0',
		height: '120px',
	},
	minWidth: 0,
	maxWidth: '100%',
	height: '240px',
	aspectRatio: 'auto',
}))

interface SlideProps extends PropsWithChildren {

}

const Slide = ({children}: SlideProps) => {

	return (
		<SlideBox className="embla__slide">
			{ children }
		</SlideBox>
	)
}

export default Slide