import SvgIcon from "@mui/material/SvgIcon"
import { SxProps, styled } from '@mui/material';
import { CSSProperties, MouseEvent, PropsWithChildren } from "react";

export type IconSize = 'large' | 'medium' | 'small' | CSSProperties['fontSize']
export type IconColors = 'lightGrey' | 'mediumGrey' | 'darkGrey' | 'black'

const SizeMap = new Map<string, CSSProperties['fontSize']>([
	['large', '4em'],
	['medium', '2em'],
	['small', '1em'],
])

const getIconSize = (size: IconSize) => {
	if (SizeMap.has(size as string)) return SizeMap.get(size as string)
	return size as CSSProperties['fontSize']
}

export interface SvgIconWrapperProps {
	onClick?: (e: MouseEvent<HTMLOrSVGElement>) => void;
	iconSize?: IconSize;
	iconColor?: IconColors;
	sx?: SxProps;
}

interface SvgIconWrapperPropsInternal extends PropsWithChildren, SvgIconWrapperProps {

}

const StyledSvgIconWrapper = styled(SvgIcon, {
	shouldForwardProp: (prop) => prop !== 'iconSize' && prop !== 'iconColor',
})<SvgIconWrapperProps>(({theme, iconSize = 'medium', iconColor}) => ({
	fontSize: getIconSize(iconSize),
	...(iconColor && {
		path: {
			fill: iconColor === 'lightGrey' ? theme.palette.grey[200]
				: iconColor === 'mediumGrey' ? theme.palette.grey[400]
				: iconColor === 'darkGrey' ? theme.palette.grey[600]
				: theme.palette.common.black
		}
	})
}))

const SvgIconWrapper = ({ onClick, iconSize, iconColor, sx, children }:SvgIconWrapperPropsInternal) => {

	const handleOnClick = (e: MouseEvent<HTMLOrSVGElement>) => {
		if (onClick) onClick(e)
	}

	return (
		<StyledSvgIconWrapper onClick={handleOnClick} iconSize={iconSize} iconColor={iconColor}>
			{ children }
		</StyledSvgIconWrapper>
	)
}

export default SvgIconWrapper