import { Box, styled } from "@mui/material"
import { PropsWithChildren, useCallback } from "react"
import { useDropzone, FileWithPath } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid';
import { UploadTask } from "../../store/types/fileTypes";

const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'bgImage'
})(() => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
}))

interface UploadComponentProps extends PropsWithChildren {
	onUploaded: (uploadTask: UploadTask) => void;
	canUpload?: boolean;
}

const UploadComponent = ({ children, onUploaded, canUpload = true, }: UploadComponentProps) => {

	const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
		acceptedFiles.forEach((file: FileWithPath) => {
			const fileData: UploadTask = {
				meta: {
					type: file.type
				},
				name: file.name,
				blobURL: URL.createObjectURL(file),
				id: uuidv4(),
			}
			onUploaded(fileData)
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const {getRootProps, getInputProps} = useDropzone({
		onDrop,
		noClick: !canUpload,
		noKeyboard: !canUpload,
		accept: { 'image/*': ['.jpeg','.png'] },
		maxFiles: 1,
	})
	
	return (
		<StyledBox {...getRootProps()}>
			<input {...getInputProps()} />
			{ children }
		</StyledBox>
	)
}

export default UploadComponent