import { Box, Grid, Typography, styled } from "@mui/material"
import { FORM_PROPS } from "../../../helpers/FormProps";
import ChipAtom from "../../atoms/chip/ChipAtom";
import { useState } from "react";
import Column from "../../layouts/column/Column";
import { useAppDispatch } from "../../../store/hooks";
import { Contact, ContactID } from "../../../store/reducers/contact/contactState";
import { removeContact } from "../../../store/reducers/contact/contactSlice";

interface ContactListProps {
	contacts: Contact[];
}

const StyledBox = styled(Box)(({theme}) => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	alignItems: 'flex-start',
	rowGap: '1em',
	paddingTop: '2em',
}))

const ContactList = ({ contacts }: ContactListProps) => {	

	const dispatch = useAppDispatch()

	const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

	const handleDelete = (contactId: ContactID) => dispatch( removeContact(contactId) )

	const handleClick = (id: string) => {
		const currentIndex = selectedContacts.indexOf(id)
		let updatedContacts: string[] = [];
		if (currentIndex > -1) {
			updatedContacts = selectedContacts.splice(currentIndex,1)
		} else {
			updatedContacts = selectedContacts.concat(id)
		}
		setSelectedContacts(updatedContacts)
	}

	return (
		<StyledBox>
			{ contacts.length > 0 ? <Typography variant="h4" textAlign="left">Contacts ({contacts.length})</Typography> : <></> }
			<Grid
				container
				direction="row"
				rowGap={FORM_PROPS.ROW_SPACING}
				columnGap={FORM_PROPS.COLUMN_SPACING}>
				{
					contacts.map((contact, index) => 
						<Grid item key={`contact-${index}`}>
							<ChipAtom
								chipStyle={selectedContacts.includes(contact.id) ? 'secondary' : 'outline'}
								label={contact.email}
								handleDelete={() => handleDelete(contact.id)}
								onClick={() => handleClick(contact.id)} />
						</Grid>
				)}
			</Grid>
			</StyledBox>
	)
}

export default ContactList