import { ComponentProps, FC } from 'react';
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer';
import { TableCell, TableCellProps, TableRow, styled } from "@mui/material"

interface StyledTableContainerProps extends TableContainerProps {}

export const StyledTableContainer: FC<StyledTableContainerProps> = styled(TableContainer)((theme) => ({
	width: '100%',
	marginLeft: '-16px',
}))

interface StyledTableRowProps extends ComponentProps<typeof TableRow> {}

export const StyledTableRow: FC<StyledTableRowProps> = styled(TableRow)(({theme}) => ({
	cursor: 'pointer'
}))

interface StyledTableCellProps extends ComponentProps<typeof TableCell> {
	isAction?: boolean;
	alignText?: 'left' | 'center' | 'right';
}

export const StyledTableCell: FC<StyledTableCellProps>= styled(TableCell, {
		shouldForwardProp: (prop) => prop !== 'isAction' && prop !== 'alignText', 
	})<StyledTableCellProps>(({theme, isAction = false, alignText = 'left'}) => ({
		border: 'none',
		padding: '8px',
		paddingLeft: '16px',
		paddingRight: '16px',
		backgroundColor: '#ffffff',
		...(isAction && {
			textDecoration: 'underline',
			cursor: 'pointer',
		}),
		...(alignText && {
			textAlign: alignText
		})
	}
))