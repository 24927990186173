import ProtectedRoute from "../helpers/auth/ProtectedRoute";
import AddEmail from "../pages/admin/AddEmail";
import AddItem from "../pages/admin/AddItem";
import Contacts from "../pages/admin/Contacts";
import CreateAccount from "../pages/admin/CreateAccount";
import EditEmail from "../pages/admin/EditEmail";
import EditItem from "../pages/admin/EditItem";
import Emails from "../pages/admin/Emails";
import Items from "../pages/admin/Items";
import Profile from "../pages/admin/Profile";
import Settings from "../pages/admin/Settings";
import PageContent from "../pages/elements/PageContent";

const AdminRouter = [
	{
		path: '/admin',
		element:
			<ProtectedRoute>
				<PageContent />
			</ProtectedRoute>,
		children: [
			{
				index: true,
				element: <Profile />,
				handle: {
					title: 'Biog',
				}	
			},
			{
				path: 'get-started',
				element: <CreateAccount />,
				handle: {
					title: 'Get started',
					hideNav: true,
				}	
			},
			{
				path: 'contacts',
				element: <Contacts />,
			},
			{
				path: 'emails',
				element: <Emails />,
			},
			{
				path: 'emails/add',
				element: <AddEmail />,
			},
			{
				path: 'emails/edit/:emailId',
				element: <EditEmail />,
			},
			{
				path: 'items',
				element: <Items />,
				handle: {
					title: 'Portfolio',
				}	
			},
			{
				path: 'settings',
				element: <Settings />,
				handle: {
					title: 'Settings',
				}	
			},
			{
				path: 'items/add',
				element: <AddItem />,
				handle: {
					title: ' ',
					showBack: true,
				},
			},
			{
				path: 'items/:itemId',
				element: <EditItem />,
				handle: {
					title: ' ',
					showBack: true,
				},
			}
		]
	},
]

export default AdminRouter