import { Box, ButtonBase, CircularProgress, TextField, Typography, styled } from "@mui/material"
import Column from "../../layouts/column/Column"
import { ChangeEvent, useEffect, useState } from "react"
import { ApiStatus } from "../../../store/types/storeTypes"
import { IS_EMAIL } from "../../../store/validation/validationTypes"
import TextButton from "../../buttons/TextButton"

const StyledBox = styled(Box)(({theme}) => ({
	display: 'flex',
	gap: '8px',
	flexDirection: 'row',
	alignItems: 'center',
	boxSizing: 'border-box',
	borderRadius: '500px',
	padding: '0px 8px 0px 16px',
	background: theme.palette.grey[400],
	width: '100%',
	[theme.breakpoints.only("sm")]: {
		maxWidth: '75%',
	},
	[theme.breakpoints.up("md")]: {
		maxWidth: '100%',
	}
}))

const InputBox = styled(Box)(({theme}) => ({
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'center',
	justifyContent: 'center',
}))

const StyledButtonBase = styled(ButtonBase)(({theme}) => ({
	borderRadius: '500px',
	padding: '8px 8px',
	minWidth: '100px',
	background: `linear-gradient(45deg, ${theme.palette.frame.col1}, ${theme.palette.frame.col2})`,
}))

const StyledTextField = styled(TextField)(({theme}) => ({
	margin: '8px',
	input: {
		'&::placeholder': theme.typography.button
	}
}))

const StyledCircularProgress = styled(CircularProgress)(({theme}) => ({
	color: theme.palette.primary.dark
}))

export enum InputStates {
	INITIAL = 'INITIAL',
	SUBMITTED = 'SUBMITTED',
	SUBMITTING = 'SUBMITTING',
}

interface InputSubmitProps {
	onSubmit?: (inputVal: string) => void;
	dismiss?: () => void;
	submitStatus: ApiStatus;
}

const InputSubmit = ({onSubmit, dismiss, submitStatus}: InputSubmitProps) => {

	const [email, setEmail] = useState<string>('');
	const [validEmail, setValidEmail] = useState<boolean>(true);
	const [submitState, setSubmitState] = useState<InputStates>(InputStates.INITIAL)

	useEffect(() => {
		if (submitStatus === ApiStatus.SUCCESS && submitState === InputStates.SUBMITTING) {
			setSubmitState(InputStates.SUBMITTED)
		}
	}, [submitStatus, submitState])

	const handleSetEmail = (e: ChangeEvent<HTMLInputElement>) => {
		if (!validEmail) setValidEmail(true)
		setEmail(e.currentTarget.value)
	}

	const handleDismiss = () => {
		if (dismiss) dismiss()
	}

	const handleSubmit = () => {
		if (!IS_EMAIL(email)) return setValidEmail(false)
		if (onSubmit) {
			setSubmitState(InputStates.SUBMITTING)
			onSubmit(email)
		}
		setEmail('')
	}

	const submitEnabled = submitState === InputStates.INITIAL

	return (
		<Column>
			{ submitState !== InputStates.SUBMITTED &&
			<>
				<Typography variant="labelLgThin">Follow my work</Typography>
				<StyledBox>
					<StyledTextField
						InputProps={{ disableUnderline: true }}
						fullWidth
						error={!validEmail}
						disabled={!submitEnabled}
						value={email}
						onChange={handleSetEmail}
						placeholder="Type your email"
						type="text"
						variant="standard" />
					<StyledButtonBase onClick={handleSubmit} disabled={!submitEnabled}>
						{ submitEnabled ?
							<Typography variant="button">Follow</Typography> :
							<StyledCircularProgress size={'20px'} />
						}
					</StyledButtonBase>
				</StyledBox>
				<TextButton size="small" onClick={handleDismiss}>No thanks</TextButton>
			</>
			}
			{ submitState === InputStates.SUBMITTED &&
			<>
				<InputBox>
					<Typography variant="textInput">We'll keep in touch!</Typography>
				</InputBox>
			</>}
		</Column>
	)
}

export default InputSubmit