export const blueYellow = {
	palette: {
		primary: {
		  light: '#6f74dd',
		  main: '#3949ab',
		  dark: '#00227b',
		  contrastText: '#000000'
		},
		secondary: {
		  light: '#ffffa6',
		  main: '#dce775',
		  dark: '#a8b545',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		frame: {
			col1: '#3949ab',
			col2: '#000000',
			mid: '#8B9890',
		},
		pinkOrange: {
		  col1: '#dce775',
		  col2: '#3949ab',
		  mid: '#FF7F54',
		},
	  },
}