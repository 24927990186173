import { Fragment, useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { CircularProgress, Typography, styled } from '@mui/material';
import { doPasswordReset } from '../../helpers/auth/Authentication';
import { AuthErrorCodes } from 'firebase/auth';
import FullModal from '../modals/FullModal';
import { FORM_PROPS } from '../../helpers/FormProps';
import FilledButton from '../buttons/FilledButton';
import { AuthContext } from '../../helpers/auth/AuthProvider';


const RESET_STATUS = {
	NOT_SENT: 'NOT_SENT',
	LOADING: 'LOADING',
	CODE_EXPIRED: 'CODE_EXPIRED',
}

const StyledTextField = styled(TextField)(({theme}) => ({
	input: {
		'&::placeholder' : {
			color: 'black',
			opacity: 1,
		},
	}
}))

const ResetPasswordModal = ({ oobCode = 'mock-oob-code', resetSuccess = () => {} }) => {

	const authContext = useContext(AuthContext)
	const { setShowLogin } = authContext

	const [resetStatus, setResetStatus] = useState(RESET_STATUS.NOT_SENT)

	const [newPassword, setNewPassword] = useState('')
	const [resetVisible, setResetVisible] = useState(true)

	const handleResend = () => {
		setResetVisible(false)
		setResetStatus(RESET_STATUS.NOT_SENT)
		setShowLogin(true)
	}

	const handleReset = async () => {
		setResetStatus(RESET_STATUS.LOADING)
		try {
			const { pwdReset, error } = await doPasswordReset(oobCode, newPassword)
			if (error === AuthErrorCodes.EXPIRED_OOB_CODE || error === AuthErrorCodes.INVALID_OOB_CODE) {
				setResetStatus(RESET_STATUS.CODE_EXPIRED)
				return
			}

			if(pwdReset){
				handleResend()
			}
		} catch (error) {
			console.log('error resetting pwd', error);
		}
	}

	const handleClose = () => {
		setResetVisible(false)
	}

	return(
		<FullModal modalSize="narrow" isOpen={resetVisible} showClose={true} setIsOpen={handleClose}>
				<Stack spacing={FORM_PROPS.ROW_SPACING}>
					{
						{
							'CODE_EXPIRED':
								<Fragment>
									<Typography variant="h2" textAlign={'center'}>Code expired</Typography>
									<FilledButton
										btnStyle="rounded"
										btnColor="black"
										stretch={false}
										onClick={handleResend}>
										Try again?
									</FilledButton>
								</Fragment>,
						}[resetStatus] ||
						<Fragment>
							<Typography variant='h2'>Reset password</Typography>
							<StyledTextField
								value={newPassword}
								onChange={(e) => { setNewPassword(e.target.value)}}
								fullWidth
								label="New password"
								type="password"
								variant="standard"
								autoComplete='new-password'
							/>
							<FilledButton
								btnStyle='rounded'
								btnColor='black'
								stretch={false}
								disabled={newPassword.length < 8}
								onClick={handleReset}
								>{resetStatus === RESET_STATUS.LOADING ? <CircularProgress size={'2rem'}/> : 'Reset' }</FilledButton>
						</Fragment>
					}
				</Stack>
			</FullModal>
	)
}

export default ResetPasswordModal