import { Outlet } from "react-router-dom";
import WtsFooter from "../../components/molecules/wtsfooter/WtsFooter";
import Navbar from "../../components/organisms/navbar/Navbar";
import PageMeta from "./PageMeta";
import InviteModal from "../../components/organisms/invitemodal/InviteModal";
import CreateAccountModal from "../../components/organisms/createaccountmodal/CreateAccountModal";
import LoginModal from "../../components/organisms/loginmodal/LoginModal";
import { Box } from "@mui/material";

interface WtsPageLayoutProps {}

const WtsPageLayout = ({}: WtsPageLayoutProps) => {

    return(
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			flex: '1 1 auto',
			justifyContent: 'space-between',
		}}>
			<PageMeta />
			<InviteModal />
			<CreateAccountModal />
			<LoginModal />
			<Navbar />
			<Box sx={{
				flex: 1,
				paddingBottom: '40px',
			}}>
				<Outlet />
			</Box>
			<WtsFooter />
		</Box>
    )
  }

export default WtsPageLayout