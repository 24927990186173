import { Stack } from "@mui/material"
import { CSSProperties, PropsWithChildren } from "react"
import { FORM_PROPS } from "../../../helpers/FormProps"

interface ColumnProps extends PropsWithChildren {
	alignItems?: CSSProperties['alignItems'];
	justifyContent?: CSSProperties['justifyContent'];
	rowGap?: CSSProperties['rowGap'];
	height?: CSSProperties['height'];
	minHeight?: CSSProperties['minHeight'];
	paddingTop?: CSSProperties['paddingTop'];
}

const Column = ({alignItems = 'center', justifyContent = 'flex-start', rowGap, paddingTop, height, minHeight, children}: ColumnProps) => {
	return (
		<Stack
			useFlexGap
			display={'flex'}
			justifyContent={justifyContent}
			flexDirection={'column'}
			height={height}
			paddingTop={paddingTop}
			minHeight={minHeight}
			alignItems={alignItems}
			spacing={FORM_PROPS.ROW_SPACING}
			rowGap={rowGap}
			sx={{width: '100%'}}>
			{children}
		</Stack>
	)
}

export default Column