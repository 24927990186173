export const blackWhite = {
	palette: {
		primary: {
		  light: '#ff9300',
		  main: '#000000',
		  dark: '#000000',
		  contrastText: '#ffffff'
		},
		secondary: {
		  light: '#ff6aa7',
		  main: '#ffffff',
		  dark: '#a8b545',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		frame: {
			col1: '#000000',
			col2: '#ffffff',
			mid: '#808080',
		},
		pinkOrange: {
		  col1: '#000000',
		  col2: '#ffffff',
		  mid: '#FF7F54',
		},
	  },
}