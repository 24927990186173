import { TableHead, Table, TableBody, Typography, useMediaQuery, useTheme, Theme } from "@mui/material"
import { useSelector } from "react-redux"
import { StyledTableCell, StyledTableContainer, StyledTableRow } from "./ListComponents";
import { useNavigate } from "react-router-dom";
import ConfirmRemove from "../fragments/ConfirmRemove";
import { useState } from "react";
import type { FC } from 'react';
import { Message, MessageStatus } from "../../store/types/messageTypes";
import { selectMessageTitle, selectSortedMessages } from "../../store/reducers/message/messageSelectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { sendMessage } from "../../store/reducers/message/messageActions";
import TextButton from "../buttons/TextButton";

const MessageLabel = new Map<MessageStatus, { label: string; actionLabel: string; }>([
	[MessageStatus.DRAFT, { label: 'Draft', actionLabel: 'Edit' }],
	[MessageStatus.SENT, { label: 'Sent', actionLabel: 'Remove' }],
])

interface MessageRowProps {
	message: Message;
	handleEdit: (args: { event: React.MouseEvent<HTMLTableCellElement, MouseEvent>; message: Message }) => void;
	handleRemove: (args: { event: React.MouseEvent<HTMLTableCellElement, MouseEvent>; message: Message }) => void;
  }

const MessageRow: FC<MessageRowProps> = ({message, handleEdit, handleRemove}) => {
	const messageTitle = useAppSelector((state) => selectMessageTitle(state, message.id) )
	const handleClick = (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => {
		if (message.status === MessageStatus.DRAFT) handleEdit({event: e, message })
		if (message.status === MessageStatus.SENT) handleRemove({event: e, message })
	}
	return (
		<StyledTableRow>
			<StyledTableCell><Typography variant="tableContent">{ messageTitle }</Typography></StyledTableCell>
			<StyledTableCell><Typography variant="tableContent">{ MessageLabel.get(message.status)?.label }</Typography></StyledTableCell>
			<StyledTableCell align="right">
				<TextButton typography="tableContent" onClick={handleClick}>{MessageLabel.get(message.status)?.actionLabel}</TextButton>
			</StyledTableCell>
		</StyledTableRow>
	)
}

interface EventHandlerProps {
	event: React.MouseEvent<HTMLTableCellElement, MouseEvent>;
	message: Message;
}

interface MessageListProps {
	messages: Message[]
}

const MessageList = ({ messages }: MessageListProps) => {

	const [currentMessage, setCurrentMessage] = useState({})
	const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false)

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const theme: Theme = useTheme();
	const onMobile = useMediaQuery(theme.breakpoints.down("sm"))

	const handleSendMessage = ({event, message}: EventHandlerProps) => {
		event.stopPropagation();
		dispatch( sendMessage(message.id) )
	}

	const handleEditMessage = ({event, message}: EventHandlerProps) => {
		event.stopPropagation();
		navigate(`edit/${message.id}`)
	}

	const handleRemoveMessage = ({event, message}: EventHandlerProps) => {
		event.stopPropagation()
		setCurrentMessage(message)
		setRemoveConfirmationOpen(true)
	}

	const handleCloseRemoveModal = () => {
		setCurrentMessage({})
		setRemoveConfirmationOpen(false)
	}

	if (messages.length === 0) return null

	return (
		<StyledTableContainer>
			<Table size="small">
				<TableHead>
					<StyledTableRow>
						<StyledTableCell>Title</StyledTableCell>
						<StyledTableCell>Status</StyledTableCell>
						<StyledTableCell></StyledTableCell>
					</StyledTableRow>
				</TableHead>
				<TableBody>
					{ messages.map((message, index) => <MessageRow key={`message-${index}`} handleRemove={handleRemoveMessage} handleEdit={handleEditMessage} message={message} /> )}
				</TableBody>
			</Table>
			<ConfirmRemove isOpen={removeConfirmationOpen} handleClose={handleCloseRemoveModal} item={currentMessage} itemType={'message'} />
		</StyledTableContainer>
	)
}

export default MessageList