import { useEffect, useState } from "react";

export const ScrollDirection = {
  UP: 'UP',
  DOWN: 'DOWN',
}

export const useScrollDirection = ({ threshold = 30, topDistance = 50 }) => {
//   const { threshold } = props;
  const [scrollDir, setScrollDir] = useState(ScrollDirection.UP);
  const [topPosition, setTopPosition] = useState(0);
  const scrollContainer = document.getElementById('root');
  useEffect(() => {
	
    let previousScrollYPosition = scrollContainer.scrollTop;

    const scrolledMoreThanThreshold = (currentScrollYPosition) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

    const isScrollingUp = (currentScrollYPosition) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
      !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

    const updateScrollDirection = () => {
      const currentScrollYPosition = scrollContainer.scrollTop;

      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition)
          ? ScrollDirection.DOWN
          : ScrollDirection.UP;
        setScrollDir(newScrollDirection);
		setTopPosition(currentScrollYPosition);
        previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
      }
    };
	

	const updateWindowTop = () => {
		const currentScrollYPosition = scrollContainer.scrollTop
	}

    const onScroll = () => window.requestAnimationFrame(updateScrollDirection);
    const onScroll2 = () => window.requestAnimationFrame(updateWindowTop);
	
    scrollContainer.addEventListener("scroll", onScroll);
    scrollContainer.addEventListener("scroll", onScroll2);

    return () => {
		scrollContainer.removeEventListener("scroll", onScroll);
		scrollContainer.removeEventListener("scroll", onScroll2);
	}

	}, [])

  	return {
		direction: scrollDir,
		topPosition: topPosition, 
	};
};

// https://www.fabrizioduroni.it/2022/01/02/react-detect-scroll-direction/