import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers";
import { ItemID } from "../../types/itemTypes";
import { selectItemsWithFile } from "../item/itemSelectors";

const highlightSlice = (state: RootState) => state.highlight

const selectItemId = (state: RootState, id: ItemID): ItemID => id;


const selectHighlightedItems = createSelector(
	[highlightSlice],
	(slice) => slice.items
)

export const selectIsItemHighlighted = createSelector(
	[selectHighlightedItems, selectItemId],
	(items, id) => items.includes(id)
)

export const selectHighlightedItemsWithFile = createSelector(
	[selectHighlightedItems, selectItemsWithFile],
	(highlighted, items) => items.filter((item) => highlighted.includes(item.id) )
)