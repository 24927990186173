import { styled, keyframes } from '@mui/material';
import Button from '@mui/material/Button';
import { PropsWithChildren } from 'react';

const nextAnimation = keyframes`
0%, 50%, 80%, 100% { transform: scale(1); }
20% { transform: scale(1.2); }
65% { transform: scale(1.1); }   
`

const RoundStyleButton = styled(Button,{
	shouldForwardProp: (prop) => prop !== 'btnColor' && prop !== 'animate'
})<RoundButtonProps>(({theme, btnColor, animate, size}) => ({
	...theme.typography.button,
	height: '80px',
	width: '80px',
	minWidth: '80px',
	minHeight: '80px',
	...(size === 'large' && {
		...theme.typography.buttonLg,
		height: '120px',
		width: '120px',
	}),
	borderRadius: '50%',
	backgroundColor: theme.palette.primary.contrastText,
	'&:hover': { 
		backgroundColor: theme.palette.primary.contrastText,
		animation: `${nextAnimation} 300ms linear`,
	 },
	 ...(btnColor === 'black' && {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark,
		'&:hover': { 
			backgroundColor: theme.palette.primary.dark,
			animation: `${nextAnimation} 300ms linear`,
	 	},
	 }),
	 ...(btnColor === 'pink' && {
		color: theme.palette.primary.dark,
		backgroundColor: theme.palette.frame.col2,
		'&:hover': { 
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			animation: `${nextAnimation} 300ms linear`,
	 	},
	 }),
}))

type BtnColors = 'white' | 'black' | 'pink'
type BtnSizes = 'large' | 'medium'

interface RoundButtonProps extends PropsWithChildren {
	btnColor?: BtnColors;
	size?: BtnSizes;
	animate?: boolean;
	onClick: () => void;
}

const RoundButton = ({ children, onClick, btnColor = "white", size = 'medium', animate, ...inputProps}: RoundButtonProps) => {
	return (
		<RoundStyleButton btnColor={btnColor} animate={animate} onClick={onClick} size={size} {...inputProps} variant='contained'>
			{ children }
		</RoundStyleButton>
	)
}

export default RoundButton