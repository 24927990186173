import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../reducers"


const userSlice = (state: RootState) => state.user

export const selectUserLoading = createSelector(
	[userSlice],
	(user) => user.status
)

export const selectUploadingLogo = createSelector(
	[userSlice],
	(user) => user.uploading
)

export const selectUserSlug = createSelector(
	[userSlice],
	(user) => user.slug
)

export const selectUserAddress = createSelector(
	[userSlice],
	(user) => user.address
)

export const selectUserPostalCode = createSelector(
	[userSlice],
	(user) => user.postalCode
)

export const selectUserCity = createSelector(
	[userSlice],
	(user) => user.city
)

export const selectUserEmail = createSelector(
	[userSlice],
	(user) => user.email
)



export const selectQuickLinks = createSelector(
	[userSlice],
	(user) => user.links
)

export const selectUserBio = createSelector(
	[userSlice],
	(user) => user.bio
)

export const selectUserProfession = createSelector(
	[userSlice],
	(user) => user.profession
)

export const selectCompanyName = createSelector(
	[userSlice],
	(user) => user.companyName
)

export const selectDefaultTheme = createSelector(
	[userSlice],
	(user) => user.defaultTheme
)

export const selectHasUserInfo = createSelector(
	[userSlice],
	(user) => {
		if (!user.email) return false
		if (!user.bio) return false
		if (!user.profession) return false
		if (!user.companyName) return false
		return true
	}
)