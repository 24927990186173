import { useContext, useState, MouseEvent } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from '../../../helpers/auth/AuthProvider';
import { styled } from '@mui/material';
import { userLogout } from '../../../store/reducers/user/userSlice';
import { useAppDispatch } from '../../../store/hooks';

const NAV_PATHS = [
	{ path: '/admin', label: "Try it out" },
	{ path: '/why', label: "Why?" },
]
  
const AUTH_NAV_PATHS = [
	{ path: '/admin', label: "Biog" },
	{ path: '/admin/items', label: "Items" },
	{ path: '/admin/contacts', label: "Contacts" },
	{ path: '/admin/emails', label: "Emails" },
	{ path: '/admin/settings', label: "Settings" },
]

interface StyledMenuItemProps {
	mobileVisible: boolean;
	currentRoute?: boolean;
}

const StyledMenuItem = styled(MenuItem, {
	shouldForwardProp: (prop) => prop !== 'mobileVisible' && prop !== 'currentRoute'
})<StyledMenuItemProps>(({ theme, mobileVisible, currentRoute }) => ({
	...(mobileVisible && {
		[theme.breakpoints.down('md')]: {
			display: 'none'
		},
		display: 'block',
	}),
	...(currentRoute && { fontWeight: 600 }),
	minHeight: 'initial',
}))

const StyledMenu = styled(Menu)(({theme}) => ({
	'.MuiMenu-paper': {
		backgroundColor: theme.palette.grey[300],
		minWidth: '250px',
	},
}))

const StyledMenuIcon = styled(MenuIcon)(({theme}) => ({
	fill: 'black',
	// [theme.breakpoints.down('md')]: {
	// 	display: 'block'
	// },
	// display: 'none'
}))


const BurgerMenu = () => {

	const { authenticated, setShowLogin } = useContext(AuthContext)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
    
	const location = useLocation();
	const dispatch = useAppDispatch()
	const navigate = useNavigate();
  	
	const handleLogin = () => {
		setAnchorEl(null)
		setShowLogin(true)
	}

	const handleSignout = () => {
		setAnchorEl(null)
		dispatch( userLogout() )
	}

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
      	setAnchorEl(event.currentTarget);
    }

    const closeMenu = () => {
      	setAnchorEl(null)
    }
	
    const handleMenuClick = (route: string) => {
		setAnchorEl(null)
      	navigate(route);
    }

    return (
      <>
        { !authenticated &&
			<StyledMenuItem key={`menu-login`} mobileVisible={true} onClick={handleLogin}>
				Login
			</StyledMenuItem>
		}
		<IconButton onClick={handleOpenMenu}>
        	<StyledMenuIcon />
        </IconButton>
        <StyledMenu anchorEl={anchorEl} onClose={closeMenu} open={open}>
			{ authenticated ?
				[
					AUTH_NAV_PATHS.map((item, index) =>
						<StyledMenuItem currentRoute={location.pathname === item.path} key={`menu-${index}`} mobileVisible={false} onClick={() => {handleMenuClick(item.path)}}>{item.label}</StyledMenuItem>
					),
					<StyledMenuItem key={`menu-logout`} mobileVisible={false} onClick={handleSignout}>
						Logout
					</StyledMenuItem>
				]
				:
				[
					NAV_PATHS.map((item, index) =>
						<StyledMenuItem key={`menu-${index}`} mobileVisible={false} onClick={() => handleMenuClick(item.path)}>{item.label}</StyledMenuItem>
					),
					<StyledMenuItem key={`menu-login`} mobileVisible={false} onClick={handleLogin}>
						Login
					</StyledMenuItem>
				]
			}
        </StyledMenu>
      </>
    )
  }


  export default BurgerMenu;