import { forwardRef } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ProgressBar from '../molecules/progressbar/ProgressBar';
import Frame from './Frame';


const FormWrapper = forwardRef((props, ref) => {
  const { children, progress = false, heading = 'Send professional and super-🔥 invoices!' } = props;
  return (
    <Container
		ref={ref}
		sx={{
			alignItems: 'center',
			flexDirection: 'column',
			display: 'flex',
		}}>
		{ heading ? <Typography
			data-html2canvas-ignore="true"
			variant="h3"
			sx={{
				paddingBottom: '20px',
				paddingTop: '20px',
			}}>
			{ heading }
		</Typography> : null}
		{ Number.isInteger(progress) ? <ProgressBar activeStep={progress} /> : null }
		<Frame>
			{ children }
		</Frame>
    </Container>
  );
})

export default FormWrapper;
