import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";

const SPINNER_SIZE = '80px'

const FullscreenSpinner = () => {
	return (
		<Container
			sx={{
				height: '75vh',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<CircularProgress size={ SPINNER_SIZE }/>
		</Container>
	)
}

export default FullscreenSpinner