import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

interface StyledModalProps {
}

const StyledModal = styled(Modal)<StyledModalProps>(({theme}) => ({
	color: '#fff',
	backgroundColor: 'rgba(0,0,0,0)',
	zIndex: theme.zIndex.drawer + 1,
	justifyContent: "center",
	alignItems: 'center',
	display: 'flex',
}))

interface StyledBoxProps {
	transparent: boolean;
 }

const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'transparent' && prop !== 'coverFull',
})<StyledBoxProps>(({theme, transparent}) => ({
	boxSizing: 'border-box',
	backgroundColor: transparent ? 'rgba(255,255,255,0.8)' : theme.palette.background.default,
	padding: '0 20px 20px 20px',
	display: 'flex',
	position: 'absolute',
	top: '180px',
	bottom: '0px',
	left: '0px',
	right: '0px',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
}))

const ContentBox = styled(Box)(({theme}) => ({
	display: 'flex',
	flex: '1 1',
	flexDirection: 'column',
	justifyContent: 'center',
	[theme.breakpoints.up('sm')]: {
		width: '400px',
		minHeight: '300px',
	},
	[theme.breakpoints.down('sm')] : {
		height: '100%',
		width: '100%',
	}
}))

interface PartialModalProps extends PropsWithChildren {
	isOpen: boolean;
	title?: string;
	transparent?: boolean;
	showBack?: boolean;
	onClose: () => void;
}

const PartialModal = ({
	isOpen = false,
	transparent = false,
	onClose = () => {},
	children,
}: PartialModalProps) => {

    const handleClose = () => {
		onClose()
    }

    return(
        <StyledModal open={isOpen} onClose={handleClose} disableAutoFocus={true} hideBackdrop={true} >
			<StyledBox transparent={transparent}>
				<ContentBox>
					{ children }
				</ContentBox>
			</StyledBox>
        </StyledModal>
    )
}

export default PartialModal