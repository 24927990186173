import { Box } from "@mui/material"
import CenterWrap from "../../components/layouts/centerwrap/CenterWrap"
import Column from "../../components/layouts/column/Column"
import ItemList from "../../components/organisms/itemlist/ItemList"
import { useAppSelector } from "../../store/hooks"
import { selectSortedItemsWithFile } from "../../store/reducers/item/itemSelectors"
import { useNavigate } from "react-router-dom"
import EmptyList from "../../components/molecules/emptylist/EmptyList"
import OutlineButton from "../../components/atoms/outlinebutton/OutlineButton"
import { Item } from "../../store/types/itemTypes"


const Items = () => {
	const navigate = useNavigate()

	const items = useAppSelector(selectSortedItemsWithFile)
	// const items: Item[] = []

	const handleAddPic = () => {
		navigate('add')
	}

	return(
		<>
			<OutlineButton label="Add file" onClick={handleAddPic} stretch />
			<ItemList items={items} />
			{ items.length === 0  && <EmptyList entityType="item" onEmptyClick={handleAddPic} /> }
		</>
	)
}

export default Items