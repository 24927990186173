import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { UploadedFile, UploadTask } from '../types/fileTypes';
import { uploadObjectURL } from '../utils/uploadUtil';
import { ApiStatus, SliceEntityMap } from '../types/storeTypes';

export interface FileUploadStatus {
    uploadId: string;
    uploadProgress: number;
    fileURL: string;
}

export interface FilenameUpdate {
    id: string;
    val: string;
}

interface ActionCreatorMap {
    [name: string]: (state: any, action: PayloadAction<any>) => void;
}

interface AddFileError {
	error: string
}

export const createAddFileThunk = (sliceName: string) => {
	return createAsyncThunk<UploadedFile, UploadTask, { rejectValue: AddFileError }>(
		`${sliceName}/fileAdded`,
		async (fileAdded: UploadTask, thunkAPI) => {
			try {
				const { user } = thunkAPI.getState() as any
				const options = {
					fileId: fileAdded.id,
					folder: sliceName,
					objectURL: fileAdded.blobURL,
					userId: user.id,
				}
				const { uploaded, fullPath, fileId, fileURL } = await uploadObjectURL(options)
				if (!uploaded) throw new Error('Failed to upload file')
				const uploadedFile: UploadedFile = {
					name: '',
					meta: fileAdded.meta,
					fileURL: fileURL,
					fullPath: fullPath,
					id: fileId,
				}
				return uploadedFile
			} catch (error: any) {
				return thunkAPI.rejectWithValue(error.message)
			}
		}
	)
}

export const createAddFileExtraReducers = (sliceName: string) => {

	const entityName = SliceEntityMap[sliceName]

	const pendingReducer = (state: any) => {
        state.loading = ApiStatus.PENDING;
    };
    
    const fulfilledReducer = (state: any, action: PayloadAction<UploadedFile>) => {
		const uploadedFile = action.payload
		const uploadIndex = state[entityName].uploads.findIndex((task: UploadTask) => task?.id === uploadedFile.id)
		if (uploadIndex !== -1) {
			state[entityName].uploads.splice(uploadIndex, 1)
		}
		state[entityName].files.push(uploadedFile)
        state.loading = ApiStatus.SUCCESS;
    };
    
    const rejectedReducer = (state: any) => {
        state.loading = ApiStatus.ERROR;
    };

	const addFileThunk = createAddFileThunk(sliceName)

	return {
		pendingReducer,
		fulfilledReducer,
		rejectedReducer,
		addFileThunk,
	}
}



export const fileActionsReducer = (sliceName: string): ActionCreatorMap => {
	const entityName = SliceEntityMap[sliceName]
	
	return {
		'addUpload': (state: any, action: PayloadAction<UploadTask>) => {
			state[entityName].uploads.push(action.payload)
		},
		'removeUpload': (state: any, action: PayloadAction<string>) => {
			state[entityName].uploads = state[entityName].uplods.filter((task: UploadTask) => task.id !== action.payload)
		},
		'setNameForFile': (state: any, action: PayloadAction<FilenameUpdate>) => {
			const { id, val } = action.payload;
			console.log(state);
			
			let fileIndex = state[entityName].files.findIndex((item: any) => item.id === id);
			if (fileIndex >= 0) {
				state[entityName].files[fileIndex].name = val;
			}
		},
		'setFileTypeForFile': (state: any, action: PayloadAction<{id: string, fileType: string}>) => {
			const { id, fileType } = action.payload
			let fileIndex = state[entityName].files.findIndex((item: any) => item.id === id);
			if (fileIndex >= 0) {
				state[entityName].files[fileIndex].meta.type = fileType;
			}
		},
		'removeFile': (state: any, action: PayloadAction<string>) => {
			state[entityName].files = state[entityName].files.filter((item: any) => item.id !== action.payload);
		}
	}
};