import React from "react"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom"

function StartButton() {

    const navigate = useNavigate();

    const handleStart = () => {
        navigate('/start')
    }
    return (
        <Button
            onClick={handleStart}
            sx={{
            borderColor: 'white',
            borderStyle: 'solid',
            borderWidth: '2px',
            borderRadius: '50px',
            height: '100px',
            color: 'white',
            }}
            >
            I'm ready, send an invoice?
        </Button>
    )
}

export default StartButton;