import SvgIconWrapper, { SvgIconWrapperProps } from "./SvgIconWrapper";

interface IdCardIconProps extends SvgIconWrapperProps {}

const IdCardIcon = ({ iconSize = 'large', iconColor, onClick }: IdCardIconProps) => {
	return (
		<SvgIconWrapper iconSize={iconSize} iconColor={iconColor} onClick={onClick}>
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M566.15-461.54h163.08v-40H566.15v40Zm0-110.77h163.08v-40H566.15v40ZM230.77-347.69h264.61v-6.62q0-35-35.15-54.19-35.15-19.19-97.15-19.19-62 0-97.16 19.19-35.15 19.19-35.15 54.19v6.62Zm132.31-144.62q25.3 0 42.65-17.34 17.35-17.35 17.35-42.66 0-25.31-17.35-42.65-17.35-17.35-42.65-17.35-25.31 0-42.66 17.35-17.34 17.34-17.34 42.65t17.34 42.66q17.35 17.34 42.66 17.34ZM184.62-200q-27.62 0-46.12-18.5Q120-237 120-264.62v-430.76q0-27.62 18.5-46.12Q157-760 184.62-760h590.76q27.62 0 46.12 18.5Q840-723 840-695.38v430.76q0 27.62-18.5 46.12Q803-200 775.38-200H184.62Zm0-40h590.76q9.24 0 16.93-7.69 7.69-7.69 7.69-16.93v-430.76q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H184.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v430.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM160-240v-480 480Z"/></svg>
		</SvgIconWrapper>
	)
}

export default IdCardIcon