export const brownPink = {
	palette: {
		primary: {
		  light: '#8e362d',
		  main: '#5b0404',
		  dark: '#360000',
		  contrastText: '#ffffff'
		},
		secondary: {
		  light: '#ffffff',
		  main: '#ead1dc',
		  dark: '#b8a0aa',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		frame: {
			col1: '#5b0404',
			col2: '#ead1dc',
			mid: '#A36B70',
		},
		pinkOrange: {
		  col1: '#dce775',
		  col2: '#3949ab',
		  mid: '#FF7F54',
		},
	  },
}