export const darkblueYellow = {
	palette: {
		primary: {
		  light: '#5470b7',
		  main: '#1c4587',
		  dark: '#001f59',
		  contrastText: '#ffffff'
		},
		secondary: {
		  light: '#ffff97',
		  main: '#ffd966',
		  dark: '#c9a835',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		frame: {
			col1: '#1c4587',
			col2: '#ffd966',
			mid: '#8E8F77',
		},
		pinkOrange: {
		  col1: '#dce775',
		  col2: '#3949ab',
		  mid: '#FF7F54',
		},
	  },
}