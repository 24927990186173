import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { itemState } from "./itemState";
import { getEntitiesForUser, getEntityByID, removeEntityById, saveEntityForUser, saveOrCreateEntity } from "../../utils/firebaseUtils";
import { ApiStatus } from "../../types/storeTypes";
import { Item, ItemID, defaultItem, isItemFilter } from "../../types/itemTypes";
import { createAddFileExtraReducers, createAddFileThunk, fileActionsReducer } from "../../actions/fileActions";
import type { RootState } from "../reducers";

export const createOrUpdateItem  = createAsyncThunk('items/create', async (args, thunkApi) => {
	try {
		const { user, items: { item } } = thunkApi.getState() as RootState
		const result = await saveOrCreateEntity('items', item, user.id)
		if (!result.saved) throw new Error('Failed to save item')
		const savedItem = result.entity as Item
		await saveEntityForUser('items', savedItem.id, user.id)
		return {
			saved: true,
			item: savedItem
		}
	} catch (error: any) {
		return {
			saved: false,
			error: error.message,
		}
	}
})

type getItemsForUserArgs = {
	userId: string;
}

export const getItemsForUser = createAsyncThunk<Item[], getItemsForUserArgs>(
	'items/getitemsUser',
	async (args, thunkApi): Promise<Item[]> => {
		try {
			const { userId } = args
			const { result } = await getEntitiesForUser('items', userId)
			const items: Item[] = result.filter(isItemFilter)
			return items
		} catch (error: any) {
			throw new Error(error.message)		
		}
	}
)

export const getItemsForCurrentUser = createAsyncThunk<Item[], void, { rejectValue: string }>(
	'items/getitemsCurrentUser',
	async (args, thunkApi): Promise<Item[]> => {
		try {
			const { user } = thunkApi.getState() as RootState
			const { result } = await getEntitiesForUser('items', user.id)
			const items: Item[] = result.filter(isItemFilter)
			return items
		} catch (error: any) {
			throw new Error(error.message)		
		}
	}
)

type GetItemArgs = string;

export const getItem = createAsyncThunk<Item, GetItemArgs>('items/get', async (args, thunkApi): Promise<Item> => {
	try {
		// Check if item already exists?
		// const { items } = thunkApi.getState() as RootState
		const itemId = args
		const item = await getEntityByID('items', itemId) as Item
		return item
	} catch (error: any) {
		throw error
	}
})

type ItemId = string;
type RemoveItemId = string;

export const removeItem = createAsyncThunk<ItemId, RemoveItemId>('items/delete', async (args): Promise<ItemId> => {
	try {
		const itemId = args
		await removeEntityById('items', itemId)
		return itemId
	} catch (error: any) {
		throw error
	}
})

const fileActions = fileActionsReducer('items')
const addFileThunk = createAddFileThunk('items')


type StringUpdateWithId = {
	value: string;
	id: ItemID;
}

type NumberUpdateWithId = {
	value: number;
	id: ItemID;
}

export const itemSlice = createSlice({
	name: 'items',
	initialState: { ...itemState() },
	reducers: {
		...fileActionsReducer('items'),
		setItemDescription: (state, action: PayloadAction<string>) => {
			state.item.description = action.payload;
		},
		setItemAmountExclVAT: (state, action: PayloadAction<number>) => {
			state.item.amountExclVAT = action.payload;
		},
		setItemVAT: (state, action: PayloadAction<number>) => {
			state.item.currentVAT = action.payload;
		},
		setItemDiscount: (state, action: PayloadAction<number>) => {
			state.item.discount = action.payload;
		},
		setItemAmount: (state, action: PayloadAction<number>) => {
			state.item.itemAmount = action.payload;
		},
		setItemNetAmountExclVAT: (state, action: PayloadAction<number>) => {
			state.item.itemNetAmountExclVAT = action.payload;
		},
		setIndexItemDescription: (state, action: PayloadAction<StringUpdateWithId>) => {
			const { value, id } = action.payload
			const itemIndex = state.items.findIndex((item) => item.id === id)
			if (itemIndex !== -1) {
				state.items[itemIndex].description = value
			}
			if (state.item.id === id) {
				state.item.description = value
			}
		},
		setIndexItemAmountExclVAT: (state, action: PayloadAction<NumberUpdateWithId>) => {
			const { value, id } = action.payload
			let itemIndex = state.items.findIndex((item) => item.id === id)
			if (itemIndex !== -1) {
				state.items[itemIndex].amountExclVAT = value;
			}
		},
		setIndexItemVAT: (state, action: PayloadAction<NumberUpdateWithId>) => {
			const { value, id } = action.payload
			let itemIndex = state.items.findIndex((item) => item.id === id)
			if (itemIndex !== -1) {
				state.items[itemIndex].currentVAT = value;
			}
		},
		setIndexItemDiscount: (state, action: PayloadAction<NumberUpdateWithId>) => {
			const { value, id } = action.payload
			let itemIndex = state.items.findIndex((item) => item.id === id)
			if (itemIndex !== -1) {
				state.items[itemIndex].discount = value
			}
		},
		setIndexItemAmount: (state, action: PayloadAction<NumberUpdateWithId>) => {
			const { value, id } = action.payload
			let itemIndex = state.items.findIndex((item) => item.id === id)
			if (itemIndex !== -1) {
				state.items[itemIndex].itemAmount = value;
			}
		},
		setIndexItemNetAmountExclVATs: (state, action: PayloadAction<NumberUpdateWithId>) => {
			const { value, id } = action.payload
			let itemIndex = state.items.findIndex((item) => item.id === id)
			if (itemIndex !== -1) {
				state.items[itemIndex].itemNetAmountExclVAT = value;
			}
		},
		...fileActions,
	},
	extraReducers: builder => {
		const { pendingReducer, fulfilledReducer, rejectedReducer, addFileThunk } = createAddFileExtraReducers('items')
		builder.addCase(addFileThunk.pending, pendingReducer)
		builder.addCase(addFileThunk.fulfilled, fulfilledReducer)
		builder.addCase(addFileThunk.rejected, rejectedReducer)

		builder
			.addCase(createOrUpdateItem.pending, (state) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(createOrUpdateItem.fulfilled, (state, action) => {
				const { saved, item } = action.payload
				if (saved && item) {
					const index = state.items.findIndex((existingItems) => existingItems.id === item.id)
					if (index !== -1) {
						state.items = state.items.map(existingItem =>
							existingItem.id === item.id ? item : existingItem
						);
					} else {
						state.items.push(item)
					}
				}
				const newItem = defaultItem({})
				state.item = newItem
				state.status = ApiStatus.SUCCESS
			})
			.addCase(createOrUpdateItem.rejected, (state) => {
				state.status = ApiStatus.SUCCESS
			})
			.addCase(getItemsForUser.pending, (state) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(getItemsForUser.rejected, (state) => {
				state.status = ApiStatus.SUCCESS
			})
			.addCase(getItemsForUser.fulfilled, (state, action) => {
				state.items = action.payload
				state.status = ApiStatus.SUCCESS
			})
			.addCase(getItemsForCurrentUser.pending, (state) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(getItemsForCurrentUser.rejected, (state) => {
				state.status = ApiStatus.SUCCESS
			})
			.addCase(getItemsForCurrentUser.fulfilled, (state, action) => {
				state.items = action.payload
				state.status = ApiStatus.SUCCESS
			})
			.addCase(getItem.pending, (state) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(getItem.fulfilled, (state, action) => {
				const item = action.payload
				state.item = item
				if (state.items.find((existingItems) => existingItems.id === item.id)) return
				state.items.push(item)
			})
			.addCase(removeItem.pending, (state) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(removeItem.fulfilled, (state, action) => {
				const itemId = action.payload
				state.items = state.items.filter(existingItem => existingItem.id !== itemId);
				state.status = ApiStatus.SUCCESS
			})
		}
})

export const {
	setItemDescription,
	setItemAmountExclVAT,
	setItemNetAmountExclVAT,
	setItemVAT,
	setIndexItemDescription,
} = itemSlice.actions

export { addFileThunk }

export default itemSlice.reducer