import { Box } from "@mui/material"
import ProfileForm from "../../components/forms/ProfileForm"
import CenterWrap from "../../components/layouts/centerwrap/CenterWrap"
import Column from "../../components/layouts/column/Column"
import FloatingSaveButton from "../../components/organisms/floatingsavebuttons/FloatingSaveButtons"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { createOrUpdateUser } from "../../store/reducers/user/userSlice"
import ThemeSelector from "../../components/organisms/themeselector/ThemeSelector"
import { selectUserLoading } from "../../store/reducers/user/userSelectors"



const Profile = () => {

	const dispatch = useAppDispatch()
	const isLoading = useAppSelector(selectUserLoading)

	const handleSaveProfile = () => {
		dispatch( createOrUpdateUser() )
	}

	return (
		<>
			<ProfileForm />
			<FloatingSaveButton onSave={handleSaveProfile} isLoading={isLoading} />
			{/* <ThemeSelector /> */}
		</>
	)
}


export default Profile