import { ApiStatus } from "../../types/storeTypes"
import { Message, defaultMessage } from "../../types/messageTypes";
import { UploadTask, UploadedFile } from "../../types/fileTypes";


export interface InitialMessageState {
	messages: Message[];
	message: Message;
	uploads: UploadTask[];
	files: UploadedFile[];
	status: ApiStatus;
	stateValid: boolean;
	validation: Record<string, any>;
}

const INITIAL_STATE: InitialMessageState = {
	messages: [],
	message: { ...defaultMessage({}) },
	files: [],
	uploads: [],
	status: ApiStatus.SUCCESS,
	stateValid: true, // Don't save
	validation: {}, // Don't save
}

export const messageState = () => INITIAL_STATE