export const IS_SET = (val) => val && val.length > 0 ? true : false
export const IS_STRING = (val) => val.length > 0
export const IS_ARRAY = (val) => val.length > 0
export const IS_NUMBER = (val) => val > 0
export const IS_SWISH = (val) => new RegExp(/^[0-9]{10}$/).test(val)
export const IS_EMAIL = (val) => new RegExp(/^\S+@\S+\.\S+$/).test(val)
export const IS_ORG = (val) => new RegExp(/^[0-9]{10}$/).test(val)
export const IS_VAT = (val) => new RegExp(/^SE[0-9]{10}01$/).test(val)

export const PAYMENT_VALIDATION = {
	'Swish': IS_SWISH,
	'BankTransferAccount': IS_NUMBER,
	'BankTransferClearing': IS_NUMBER,
	'BankTransferName': IS_STRING,
	'Bankgiro': IS_NUMBER,
}

export const VALID_PARAMS = {
	'IS_SET' : () => 'set',
	'IS_STRING' : () => 'string',
	'IS_ARRAY' : () => ['array'],
	'IS_NUMBER' : () => 12,
	'IS_SWISH' : () => '0722323341',
	'IS_EMAIL' : () => 'test@email.com',
	'IS_ORG' : () => '5566778899',
	'IS_VAT' : () => 'SE556677889901',
	'paymenOptionValidation': () => ({
		'Swish': { value: '0722323341', enabled: true },
		'BankTransferAccount': { value: '0722323341', enabled: true },
		'BankTransferClearing': { value: '0722323341', enabled: true },
		'BankTransferName': { value: 'Name', enabled: true },
		'Bankgiro': { value: '12345', enabled: true },
	})
}

export const paymenOptionValidation = (val) => {
	for (const key in val) {
		const item = val[key]
		if (item.enabled) {
			const ruleToTest = PAYMENT_VALIDATION[key]
			return ruleToTest(item.value)
		}
		return true
  }
}

export const clearValidationForParam = (state, action) => {
	const { payload } = action
	let { validation, stateValid } = state
	delete validation[payload]
	if (Object.keys(validation).length === 0) { stateValid = true}
	state.validation = validation
	state.stateValid = stateValid
}

export const validateState = (state = {}, validationRules = {}) => {
	try {
		let nonValidFields = { };
		let stateValid = true;
		for (const field in validationRules) {
			if (Object.hasOwnProperty.call(validationRules, field)) {
				const validate = validationRules[field];
				const toValidate = state[field];
				if (!toValidate) {
					nonValidFields[field] = { valid: false }
					stateValid = false
					continue
				}
				const valid = validate.func(toValidate)
				if (!valid) {
					nonValidFields[field] = { valid: false }
					stateValid = false
				}
			}
		}
		return {
			stateValid,
			validation: nonValidFields,
		}
	} catch (error) {
		return {
			stateValid: false,
			validation: {},
		}
	}
}