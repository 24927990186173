import { ActionReducerMapBuilder, createAsyncThunk, current } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/storeTypes";
import { Message, MessageStatus, defaultMessage, isMessageFilter } from "../../types/messageTypes";
import { getEntitiesForUser, getEntityByID, removeEntityById, saveEntityForUser, saveOrCreateEntity } from "../../utils/firebaseUtils";
import { RootState } from "../reducers";
import { sendEmail, sendEmailPreivew } from "../emails/emailSlice";
import { InitialMessageState } from "./messageState";
import { updateItemListField } from "./messageSlice";
import { createOrUpdateItem } from "../item/itemSlice";

type createOrUpdateMessageArgs = {
	messageId?: MessageId;
}

type createOrUpdateMessageResp = {
	saved: boolean;
	message?: Message;
	error?: string;
}

export const createOrUpdateMessage = createAsyncThunk<createOrUpdateMessageResp, createOrUpdateMessageArgs>(
	'messages/create',
	async (args, thunkApi) => {
		try {
			const { user, messages: { message, messages } } = thunkApi.getState() as RootState;
			let messageToSave: Message;
			const messageId = args.messageId ? args.messageId : false
			if (!messageId) {
				messageToSave = message
			} else {
				const messageIndex = messages.findIndex((msg) => msg.id === args.messageId)
				if (messageIndex === -1) throw new Error('Message not found')
				messageToSave = messages[messageIndex]
			}
			
			const result = await saveOrCreateEntity('messages', messageToSave, user.id);
			if (!result.saved)
				throw new Error('Failed to save message');
			const savedMessage = result.entity as Message;
			await saveEntityForUser('message', savedMessage.id, user.id);
			return {
				saved: true,
				message: savedMessage
			};
		} catch (error: any) {
			return {
				saved: false,
				error: error.message,
			};
		}
	}
);

export const addCreateOrUpdateMessageCases = (builder: ActionReducerMapBuilder<InitialMessageState>) => {
	builder.addCase(createOrUpdateMessage.pending, (state) => {
		state.status = ApiStatus.PENDING
	});
	builder.addCase(createOrUpdateMessage.fulfilled, (state, action) => {
		const { saved, message } = action.payload
		if (saved && message) {
			const index = state.messages.findIndex((existingMessage: Message) => existingMessage.id === message.id)
			if (index !== -1) {
				state.messages[index] = message
			} else {
				state.messages.push(message)
			}
			state.message = defaultMessage({})
		}
		state.status = ApiStatus.SUCCESS
	})
	builder.addCase(createOrUpdateMessage.rejected, (state) => {
		state.status = ApiStatus.SUCCESS
	})
}


export const getMessagesForUser = createAsyncThunk<Message[], void, { rejectValue: string; }>(
	'messages/getmessages',
	async (args, thunkApi): Promise<Message[]> => {
		try {
			const { user } = thunkApi.getState() as RootState;
			const { result } = await getEntitiesForUser('messages', user.id);
			const messages: Message[] = result.filter(isMessageFilter);
			return messages;
		} catch (error: any) {
			throw new Error(error.message);
		}
	}
);

export const addGetMessagesForUserCases = (builder: ActionReducerMapBuilder<InitialMessageState>) => {
	builder.addCase(getMessagesForUser.pending, (state) => {
		state.status = ApiStatus.PENDING
	})
	builder.addCase(getMessagesForUser.rejected, (state) => {
		state.status = ApiStatus.SUCCESS
	})
	builder.addCase(getMessagesForUser.fulfilled, (state, action) => {
		state.messages = action.payload
		state.status = ApiStatus.SUCCESS
	})
}



type GetMessageArgs = string;

export const getMessage = createAsyncThunk<Message, GetMessageArgs>(
	'messages/get',
	async (args, thunkApi): Promise<Message> => {
		try {
			// Check if item already exists?
			// const { messages } = thunkApi.getState() as RootState
			const messageId = args;
			const message = await getEntityByID('messages', messageId) as Message;
			return message;
		} catch (error: any) {
			throw error;
		}
	}
);

export const addGetMessageCases = (builder: ActionReducerMapBuilder<InitialMessageState>) => {
	builder.addCase(getMessage.pending, (state) => {
		state.status = ApiStatus.PENDING
	})
	builder.addCase(getMessage.fulfilled, (state, action) => {
		const message = action.payload
		state.message = message
		state.status = ApiStatus.SUCCESS
		if (state.messages.find((existingMessage) => existingMessage.id === message.id)) return
		state.messages.push(message)
	})
}



type MessageId = string;
type RemoveMessageId = string;

export const removeMessage = createAsyncThunk<MessageId, RemoveMessageId>(
	'messages/delete',
	async (args): Promise<MessageId> => {
		try {
			const messageId = args;
			await removeEntityById('messages', messageId);
			return messageId;
		} catch (error: any) {
			throw error;
		}
	}
);

export const addRemoveMessageCases = (builder: ActionReducerMapBuilder<InitialMessageState>) => {
	builder.addCase(removeMessage.pending, (state) => {
		state.status = ApiStatus.PENDING
	})
	builder.addCase(removeMessage.fulfilled, (state, action) => {
		const messageId = action.payload
		state.messages = state.messages.filter(existineMessage => existineMessage.id !== messageId);
		state.status = ApiStatus.SUCCESS
	})
}



type SendMessageResponse = {
	messageId: MessageId;
	status: MessageStatus;
};

export const sendMessage = createAsyncThunk<SendMessageResponse, MessageId>(
	'messages/send',
	async (args, thunkApi) => {
		const messageId = args;
		const { messages: { messages } } = thunkApi.getState() as RootState;
		const index = messages.findIndex((existingMessage: Message) => existingMessage.id === messageId);
		if (index === -1) throw new Error('No email found');
		const message = messages[index];
		await thunkApi.dispatch( sendEmail({ message }) )
		return {
			messageId: messageId,
			status: MessageStatus.SENT
		};
	}
);



export const addSendMessageCases = (builder: ActionReducerMapBuilder<InitialMessageState>) => {
	builder.addCase(sendMessage.pending, (state) => {
		state.status = ApiStatus.PENDING
	})
	builder.addCase(sendMessage.fulfilled, (state, action) => {
		const { messageId, status } = action.payload
		console.log('fulfilled', messageId, status);
		
		const index = state.messages.findIndex((existingMessage: Message) => existingMessage.id === messageId);
		console.log('index', index, index !== -1);
		
		if (index !== -1) {
			state.messages[index].status = status
		}
		state.status = ApiStatus.SUCCESS
	})
	builder.addCase(sendMessage.rejected, (state) => {
		state.status = ApiStatus.ERROR
	})
}

type SendPreviewMessageResponse = {
	messageId: MessageId;
	status: MessageStatus;
};

export const sendMessagePreview = createAsyncThunk<SendPreviewMessageResponse, MessageId>(
	'messages/preview',
	async (args, thunkApi) => {
		const messageId = args;
		const { messages: { messages }, user } = thunkApi.getState() as RootState;
		const index = messages.findIndex((existingMessage: Message) => existingMessage.id === messageId);
		if (index === -1) throw new Error('No email found');
		const message = messages[index];
		await thunkApi.dispatch( sendEmailPreivew({ message }) )
		return {
			messageId: messageId,
			status: MessageStatus.SENT
		};
	}
);



export const addSendMessagePreviewCases = (builder: ActionReducerMapBuilder<InitialMessageState>) => {
	builder.addCase(sendMessagePreview.pending, (state) => {
		state.status = ApiStatus.PENDING
	})
	builder.addCase(sendMessagePreview.fulfilled, (state, action) => {
		const { messageId, status } = action.payload
		console.log('fulfilled', messageId, status);
		
		const index = state.messages.findIndex((existingMessage: Message) => existingMessage.id === messageId);
		console.log('index', index, index !== -1);
		
		if (index !== -1) {
			state.messages[index].status = status
		}
		state.status = ApiStatus.SUCCESS
	})
	builder.addCase(sendMessagePreview.rejected, (state) => {
		state.status = ApiStatus.ERROR
	})
}


type AddItemToItemListArgs = {
	fieldKey: string;
}
type AddItemToItemListResponse = {
	saved: boolean;
}


export const addItemToItemListMessage = createAsyncThunk<AddItemToItemListResponse, AddItemToItemListArgs>(
	'messages/fields/itemlist/addItem',
	async (args, thunkAPI) => {
		try {
			const { fieldKey } = args
			const { items } = thunkAPI.getState() as RootState
			const currentItemId = items.item.id
			console.log('currentItemId from async', currentItemId);
			
			thunkAPI.dispatch( updateItemListField({ fieldKey, itemId: currentItemId}))
			await thunkAPI.dispatch( createOrUpdateItem() )

			return {
				saved: true,
			}
		} catch (error: any) {
			console.log(error);
			
			throw error;
		}
	}
)

export const addItemToItemListMessageCases = (builder: ActionReducerMapBuilder<InitialMessageState>) => {
	builder.addCase(addItemToItemListMessage.pending, (state) => {
		state.status = ApiStatus.PENDING
	});
	builder.addCase(addItemToItemListMessage.fulfilled, (state) => {
		state.status = ApiStatus.SUCCESS
	})
	builder.addCase(addItemToItemListMessage.rejected, (state) => {
		state.status = ApiStatus.SUCCESS
	})
}