import { Fragment, PropsWithChildren, useContext, useEffect } from 'react'
import FullscreenSpinner from '../../components/molecules/fullscreenspinner/FullscreenSpinner'
import { AuthContext } from './AuthProvider'
import { useNavigate } from 'react-router-dom'

interface ProtectedRouteProps extends PropsWithChildren {

}

const ProtectedRoute = ({children}: ProtectedRouteProps) => {

	const navigate = useNavigate();
	const { loaded, authenticated, validCode, showLogin, setShowInvite, setShowLogin } = useContext(AuthContext)

	useEffect(() => {
		if (!loaded) return
		if (!authenticated && !validCode) {
			setShowInvite(true)
			return
		}
		if (!authenticated && validCode) {
			setShowLogin(true)
			return navigate('/')
		}
	}, [loaded, authenticated, showLogin])

	if (!loaded) {
		return <FullscreenSpinner />
	}

	return (
		<Fragment>
			{ children }
		</Fragment>
	)
}

export default ProtectedRoute