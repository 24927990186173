import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { INITIAL_EMAIL_STATE } from "./emailState";
import { ApiStatus } from "../../types/storeTypes";
import { Message } from "../../types/messageTypes";
import { RootState } from "../reducers";
import { makeEmail } from "./emailActions";
import { saveOrCreateEntity } from "../../utils/firebaseUtils";
import { Email } from "../../types/emailTypes";
import { makeShareItemsMessage } from "./emailData";

type SendEmailArgs = {
	message: Message;
}

export const sendEmail = createAsyncThunk<Email, SendEmailArgs>(
	'email/send',
	async (args, thunkApi) => {
		const { user } = thunkApi.getState() as RootState
		const { message } = args
		let emailMessage;
		if (message.template === 'ShareItemsMessage') {
			const { items } = thunkApi.getState() as RootState
			emailMessage = makeShareItemsMessage({ message, items: items.items, user, preview: false })
		}
		// const emailMessage = makeEmail({ data: message, user: user, thunk: thunkApi })
		const result = await saveOrCreateEntity('emails', emailMessage, user.id)
		if (!result.saved) throw new Error('Failed to save email')
		const savedEmail = result.entity as Email
		return savedEmail
	}
)

type SendEmailPreviewArgs = {
	message: Message;
}

export const sendEmailPreivew = createAsyncThunk<Email, SendEmailPreviewArgs>(
	'email/preview',
	async (args, thunkApi) => {
		const { user } = thunkApi.getState() as RootState
		const { message } = args
		let emailMessage;
		if (message.template === 'ShareItemsMessage') {
			const { items } = thunkApi.getState() as RootState
			emailMessage = makeShareItemsMessage({ message, items: items.items, user, preview: true })
		}
		const result = await saveOrCreateEntity('emails', emailMessage, user.id)
		console.log(result);
		
		if (!result.saved) throw new Error('Failed to save email')
		const savedEmail = result.entity as Email
		return savedEmail
	}
)


export const emailSlice = createSlice({
	name: 'email',
	initialState: { ...INITIAL_EMAIL_STATE },
	reducers: {

	},
	extraReducers(builder) {
		builder
		.addCase(sendEmail.pending, (state) => {
			state.status = ApiStatus.PENDING
		})
		.addCase(sendEmail.fulfilled, (state, action) => {
			state.status = ApiStatus.SUCCESS
			state.emails.push(action.payload)
		})
		.addCase(sendEmail.rejected, (state) => {
			state.status = ApiStatus.ERROR
		})
		.addCase(sendEmailPreivew.pending, (state) => {
			state.status = ApiStatus.PENDING
		})
		.addCase(sendEmailPreivew.fulfilled, (state, action) => {
			state.status = ApiStatus.SUCCESS
		})
		.addCase(sendEmailPreivew.rejected, (state) => {
			state.status = ApiStatus.ERROR
		})
	},
})

export const {

} = emailSlice.actions

export default emailSlice.reducer