import { ChangeEvent } from "react";
import { useAppDispatch } from "../../store/hooks";
import { addFileToMessage, removeUploadListField, removeUploadedFile, updateCtaField, updateField, updateItemListField, updateUploadField, updateUploadListField } from "../../store/reducers/message/messageSlice";
import { FieldType, CtaFieldType } from "../../store/types/messageTypes";
import TextInput from "./fields/TextInput";
import TextBox from "./fields/TextBox";
import MessageUpload from "../upload/MessageUpload";
import CtaInput from "./fields/CtaInput";
import { UploadTask } from "../../store/types/fileTypes";
import MessageUploadList from "../upload/MessageUploadList";
import ItemList from "../messagefields/ItemList";

type Params = FieldType & { canEdit: boolean; messageId: string; }

type HandleCtaChangeType = {
	e: ChangeEvent<HTMLInputElement>;
	nestedKey: keyof CtaFieldType['fieldValue'];
}

type HandleUploadChangeType = UploadTask

const MessageField = (props: Params) => {
	const dispatch = useAppDispatch();
	const { fieldKey, fieldValue, inputType, canEdit, messageId } = props

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch( updateField({fieldKey, value: e.currentTarget.value, messageId}) )
	}

	const handleCtaChange = ({e, nestedKey}: HandleCtaChangeType) => {
		dispatch( updateCtaField({fieldKey, fieldValueToUpdate: nestedKey, value: e.currentTarget.value, messageId }))
	}

	const handleUploadChange = (uploadedFile: HandleUploadChangeType) => {
		dispatch( addFileToMessage(uploadedFile) )
		dispatch( updateUploadField({ fieldKey, value: uploadedFile}) )
	}

	const handleRemoveUpload = (fileId: string) => {
		dispatch( removeUploadedFile({ fieldKey, fileId }))
	}

	const handleUploadListChange = (uploadedFile: HandleUploadChangeType) => {
		dispatch( addFileToMessage(uploadedFile) )
		dispatch( updateUploadListField({ fieldKey, value: uploadedFile}) )
	}

	const handleRemoveUploadList = (fileId: string) => {
		dispatch( removeUploadListField({ fieldKey, fileId }))
	}

	switch (inputType) {
		case 'title': {
			const label = props.fieldTitle
			return <TextInput canEdit={canEdit} onChange={handleChange} value={fieldValue} placeholder={label} displayType="message" typography="h1"/>
		}
		case 'subtitle': {
			const label = props.fieldTitle
			return <TextInput canEdit={canEdit} onChange={handleChange} value={fieldValue} placeholder={label} displayType="message" typography="h4"/>
		}
		case 'textbox': {
			const label = props.fieldTitle
			return <TextBox canEdit={canEdit} onChange={handleChange} value={fieldValue} placeholder={label} displayType="message" typography="p"/>
		}
		case 'cta':
			return <CtaInput onChange={handleCtaChange} value={fieldValue} />
		case 'upload':
			return <MessageUpload onFileUploaded={handleUploadChange} onFileRemove={handleRemoveUpload} uploadedFile={fieldValue}/>
		case 'uploadList':
			return <MessageUploadList canEdit={canEdit} onFileUploaded={handleUploadListChange} onFileRemove={handleRemoveUploadList} uploadedFiles={fieldValue} />
		case 'itemList':
			return <ItemList canEdit={canEdit} items={fieldValue} fieldKey={fieldKey} />
		default:
			console.log('DEFAULT CASE', inputType);
			return <TextInput canEdit={canEdit} onChange={handleChange} value={fieldValue} />
	}
}

export default MessageField