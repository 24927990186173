import { ButtonBase, styled } from "@mui/material"


interface StyleProps {
	rounded: boolean;
	stretch: boolean;
	size: ButtonSizes;
	color: ButtonColors;
}


const StyledButtonBase = styled(ButtonBase, {
	shouldForwardProp: (prop) => prop !== 'rounded' && prop !== 'stretch' && prop !== 'size' && prop !== 'color'
})<StyleProps>(({theme, rounded, stretch, size, color}) => ({
	padding: '0.5em 2em',
	...(size === 'large' && {
		...theme.typography.buttonLg
	}),
	...(size === 'medium' && {
		...theme.typography.button
	}),
	...(size === 'small' && {
		...theme.typography.buttonSm
	}),
	...(stretch && { width: '100%', maxWidth: '400px' }),
	...(rounded && {
		borderRadius: '500px',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: theme.palette.grey[500],
	}),
	...(color === 'black' && { 
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.background.default,
			color: theme.palette.primary.dark,
		}
	}),
	...(color === 'outline' && {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.primary.dark,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
		}
	}),
	['&.Mui-disabled']: {
		backgroundColor: theme.palette.primary.dark
	},
}))

type ButtonSizes = 'small' | 'medium' | 'large';
type ButtonColors = 'outline' | 'black';


interface OutlineButtonProps {
	color?: ButtonColors;
	label: string;
	stretch?: boolean;
	size?: ButtonSizes;
	onClick: () => void;
}

const OutlineButton = ({ color = 'outline', label, stretch = false, size = 'medium', onClick }: OutlineButtonProps) => {

	return (
		<StyledButtonBase
			onClick={onClick}
			color={color}
			rounded={true}
			stretch={stretch}
			size={size}
			>
			{label}
		</StyledButtonBase>
	)
}

export default OutlineButton