export const orangeYellow = {
	palette: {
		primary: {
		  light: '#ffca47',
		  main: '#ff9900',
		  dark: '#c66a00',
		  contrastText: '#000000'
		},
		secondary: {
		  light: '#ffffca',
		  main: '#ffe599',
		  dark: '#cab36a',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		// https://meyerweb.com/eric/tools/color-blend/#D42222:000000:1:hex
		frame: {
			col1: '#ff9900',
			col2: '#ffe599',
			mid: '#FFBF4D',
		},
		pinkOrange: {
		  col1: '#dce775',
		  col2: '#3949ab',
		  mid: '#FF7F54',
		},
	  },
}