import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import LightningBolt from '../atoms/lightningbolt/LightningBolt';
import { Fragment, PropsWithChildren } from 'react';
import TextButton from '../buttons/TextButton';

type ModalSizes = 'normal' | 'narrow' | 'cover';
type ModalStyles = 'square' | 'rounded';

interface StyledBoxProps {
	modalSize: ModalSizes;
	modalStyle: ModalStyles;
}

const StyledBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'modalSize' && prop !== 'modalStyle',
})<StyledBoxProps>(({theme, modalSize, modalStyle}) => ({
	backgroundColor: theme.palette.grey[300],
	padding: '20px',
	paddingTop: '60px',
	paddingBottom: '60px',
	display: 'flex',
	position: 'relative',
	justifyContent: 'center',
	flexDirection: 'column',
	width: '400px',
	...(modalSize === 'narrow' && { width: '400px' }),
	...(modalStyle === 'square' ? { borderRadius: 0 } : { borderRadius: '60px' }),
	...(modalSize === 'cover' && {
		[theme.breakpoints.down('sm')] : {
			height: '100%',
			width: '100%',
		}
	}),
}))

interface StyledIconButtonProps extends PropsWithChildren {
	modalStyle: ModalStyles;
}

const StyledIconButton = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== 'modalStyle',
})<StyledIconButtonProps>(({theme, modalStyle}) => ({
	position: 'absolute',
	...(modalStyle === 'rounded' && {
		top: '-25px',
		right: '-25px',
		backgroundColor: theme.palette.grey[300],
		borderRadius: '50%',
		['&:hover']:{
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
		},
	}),
	...(modalStyle === 'square' && {
		top: '0px',
		right: '0px',
		['&:hover']:{
			backgroundColor: theme.palette.grey[300],
			color: theme.palette.primary.dark,
		}
	}),
	[theme.breakpoints.down("sm")]:{
		display: 'none',
	},
}))

interface RoundCloseButtonProps {
	handleClose: HandleCloseFunc;
	modalStyle: ModalStyles;
}

const RoundCloseButton = ({ handleClose, modalStyle }: RoundCloseButtonProps) => {
	return (
		<StyledIconButton onClick={() => handleClose()} modalStyle={modalStyle}>
			<CloseIcon />
		</StyledIconButton>
	)
}

const BackButton = ({ handleClose}: RoundCloseButtonProps) => {
	return (
		<TextButton onClick={handleClose}>Back</TextButton>
	)
}

type HandleCloseFunc = () => void;

interface FullModalProps extends PropsWithChildren {
	modalSize?: ModalSizes;
	modalStyle?: ModalStyles;
	showBolts?: boolean;
	showClose?: boolean;
	showBack?: boolean;
	isOpen: boolean;
	setIsOpen?: (open: boolean) => void;
	onClose: () => void;
}

const FullModal = ({
	modalSize = 'normal',
	modalStyle = 'square',
	showBolts = false,
	showClose = true,
	showBack = false,
	isOpen = false,
	setIsOpen = (arg) => {},
	onClose = () => {},
	children,
}: FullModalProps) => {

    const handleClose = () => {
		setIsOpen(false)
		onClose()
    }

    return(
        <Modal
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, justifyContent: "center", alignItems: 'center', display: 'flex', }}
            open={isOpen}
			onClose={handleClose}
        >
			<Fragment>
			{ showBolts ? [<LightningBolt key={'bolt-left'} orientation='left'/>,<LightningBolt key={'bolt-right'} orientation="right"/>] : null }
				<StyledBox modalSize={modalSize} modalStyle={modalStyle}>
					{ showClose && <RoundCloseButton handleClose={handleClose} modalStyle={modalStyle} /> }
					{ showBack && <BackButton handleClose={handleClose} modalStyle={modalStyle} /> }
					{ children }
				</StyledBox>
			</Fragment>
        </Modal>
    )
}

export default FullModal