import { Container, Typography } from "@mui/material"


const LiteStart = () => {

	return (
		<Container>
			<Typography>WIP</Typography>
		</Container>
	)
}

export default LiteStart