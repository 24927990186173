import { useMatches, useNavigate } from "react-router-dom"
import Link from '@mui/material/Link';
import { Typography, styled } from "@mui/material";

const StyledLink = styled(Link)(({theme}) => ({
	cursor: 'pointer',
}))

interface MenuBackProps {

}

type MatchType = {
	handle: {
		showBack?: boolean;
	}
}

const MenuBack = ({}: MenuBackProps) => {

	const matches = useMatches()
	const { handle } = matches[matches.length - 1] as MatchType
	const showBack = handle && handle.showBack || false

	const navigate = useNavigate()

	if (!showBack) return <></>

	const handleBack = () => {
		navigate(-1)
	}

	return (
		<StyledLink onClick={handleBack}>
			<Typography variant="textLink">Back</Typography>
		</StyledLink>
	)
}

export default MenuBack