import ItemForm from "../../components/forms/ItemForm"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { selectItemById, selectItemLoading } from "../../store/reducers/item/itemSelectors"
import { createOrUpdateItem } from "../../store/reducers/item/itemSlice"
import { useNavigate, useParams } from "react-router-dom"
import { ItemID } from "../../store/types/itemTypes"
import FullscreenSpinner from "../../components/molecules/fullscreenspinner/FullscreenSpinner"
import FloatingSaveButton from "../../components/organisms/floatingsavebuttons/FloatingSaveButtons"

const EditItem = () => {

	const { itemId } = useParams()
	const dispatch = useAppDispatch();
	const navigate = useNavigate()

	const item = useAppSelector((state) => selectItemById(state, itemId as ItemID) )
	const loading = useAppSelector(selectItemLoading)
	
	const handleSaveItem = async () => {
		await dispatch( createOrUpdateItem() )
		navigate(-1)
	}

	if (!item) return (<FullscreenSpinner />)
	return (
		<>
			<ItemForm item={item} />
			<FloatingSaveButton onSave={handleSaveItem} />
		</>
	)
}

export default EditItem