import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FluidBackgroundBox from '../../components/deprecated/FluidBackgroundBox';
import ResetPasswordModal from '../../components/fragments/ResetPasswordModal';
import { AuthContext } from '../../helpers/auth/AuthProvider';


const ResetPassword = () => {
	
	const authContext = useContext(AuthContext)
	const { authenticated } = authContext

	const navigate = useNavigate();

	let [searchParams] = useSearchParams();
	const oobCode = searchParams.get('oobCode')
	const authMode = searchParams.get('mode')
	// const redirectTo = searchParams.get('redirect')
	
	useEffect(() => {
		// if (authMode === 'resetPassword' && authenticated){
		// 	navigate('/')
		// }
	}, [authenticated, authMode])

	return (
		<FluidBackgroundBox sx={{ height: '100vh'}}>
			{
				{
					'resetPassword': <ResetPasswordModal oobCode={oobCode} />
				}[authMode]
			}
		</FluidBackgroundBox>
	);
}

export default ResetPassword;