import { keyframes } from "@mui/system";

const BounceAnim = keyframes`
	0%, 20%, 50%, 85%, 100% {transform: scale(1);} 
	40% {transform: scale(1.25);}
	65% {transform: scale(1.15);}
`

export const BounceAnimation = () => ({
    webkitAnimation: `${BounceAnimation} 1.5s linear infinite`,
    mozAnimation: `${BounceAnimation} 1.5s linear infinite`,
    oAnimation: `${BounceAnimation} 1.5s linear infinite`,
    animation: `${BounceAnimation} 1.5s linear infinite`,
  })