import { createListenerMiddleware } from "@reduxjs/toolkit";
import { createOrUpdateItem } from "../reducers/item/itemSlice";
import { saveHighlightedItems } from "../reducers/highlight/highlightSlice";

const itemMiddleWare = createListenerMiddleware()

itemMiddleWare.startListening({
	actionCreator: createOrUpdateItem.fulfilled,
	effect: async (_, listenerApi) => {
		listenerApi.dispatch( saveHighlightedItems() )
	}
})

export default itemMiddleWare