import { Typography } from "@mui/material"
import CenterWrap from "../../components/layouts/centerwrap/CenterWrap"
import Column from "../../components/layouts/column/Column"
import TextInput from "../../components/inputs/text/TextInput"
import FilledButton from "../../components/buttons/FilledButton"
import BoxPadding from "../../components/layouts/boxpadding/BoxPadding"
import Row from "../../components/layouts/row/Row"


const CreateProfile = () => {

	return (
		<CenterWrap>
			<BoxPadding>
				<Column>
					<Row>
						<Typography variant="h3">Create profile</Typography>
						<Typography variant="h3">Log in</Typography>
					</Row>
					<TextInput placeholder="Your name" value="" onChange={() => {}}/>
					<TextInput placeholder="Your email" value="" onChange={() => {}}/>
					<TextInput placeholder="Your profession" value="" onChange={() => {}}/>
					<FilledButton btnStyle="rounded" btnColor="black">Create</FilledButton>
				</Column>
			</BoxPadding>
		</CenterWrap>
	)
}

export default CreateProfile