import { onAuthStateChanged } from "firebase/auth";
import { PropsWithChildren, createContext, useEffect, useState } from "react"
import { auth } from "../../Firebase";
import { userLoggedIn, userLogout } from "../../store/reducers/user/userSlice";
import { useAppDispatch } from "../../store/hooks";

interface AuthContextType {
	loaded: boolean;
	authenticated: boolean;
	validCode: boolean;
	setValidCode: (valid: boolean) => void;
	showCreateAccount: boolean;
	setShowCreateAccount: (show: boolean) => void;
	showInvite: boolean;
	setShowInvite: (show: boolean) => void;
	showLogin: boolean;
	setShowLogin: (show: boolean) => void;
}

export const AuthContext = createContext<AuthContextType>({
	loaded: false,
	authenticated: false,
	validCode: false,
	setValidCode: () => {},
	showInvite: false,
	setShowInvite: () => {},
	showCreateAccount: false,
	setShowCreateAccount: () => {},
	showLogin: false,
	setShowLogin: () => {},
})

interface AuthProviderProps extends PropsWithChildren {}

const AuthProvider = ({ children }: AuthProviderProps) => {

	const dispatch = useAppDispatch();

	const hasValidated = localStorage.getItem('validCode') ? (localStorage.getItem('validCode') === 'true') : false

	const [validCode, setValidCode] = useState(hasValidated)
	const [showCreateAccount, setShowCreateAccount] = useState(false)
	const [showInvite, setShowInvite] = useState(false);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false)
	const [showLoginModal, setShowLoginState] = useState(false)

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setIsLoaded(true)
			setIsAuthenticated(user?.uid ? true : false)
			if (user) {
				dispatch( userLoggedIn(user.uid))
			}
		})
		return () => unsubscribe()
	}, [isLoaded])

	const _setValidCode = (valid: boolean) => {
		localStorage.setItem('validCode', valid as unknown as string)
		setValidCode(valid)
	}

	const contextValue = {
		loaded: isLoaded,
		authenticated: isAuthenticated,
		validCode: validCode,
		setValidCode: (valid: boolean) => _setValidCode(valid),
		showCreateAccount: showCreateAccount,
		setShowCreateAccount: (show: boolean) => setShowCreateAccount(show),
		showInvite: showInvite,
		setShowInvite: (show: boolean) => setShowInvite(show),
		showLogin: showLoginModal,
		setShowLogin: (show: boolean) => setShowLoginState(show),
	}

	return(
		<AuthContext.Provider value={contextValue}>
			{ children }
		</AuthContext.Provider>
	)
}

export default AuthProvider