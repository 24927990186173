import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../reducers"


const publicUserSlice = (state: RootState) => state.publicUser

export const selectPublicUserLoading = createSelector(
	[publicUserSlice],
	(user) => user.status
)

export const selectPublicUserId = createSelector(
	[publicUserSlice],
	(user) => user.id
)

export const selectPublicUserSlug = createSelector(
	[publicUserSlice],
	(user) => user.slug
)

export const selectPublicUserAddress = createSelector(
	[publicUserSlice],
	(user) => user.address
)

export const selectPublicUserPostalCode = createSelector(
	[publicUserSlice],
	(user) => user.postalCode
)

export const selectPublicUserCity = createSelector(
	[publicUserSlice],
	(user) => user.city
)

export const selectPublicUserEmail = createSelector(
	[publicUserSlice],
	(user) => user.email
)

export const selectPublicUserQuickLinks = createSelector(
	[publicUserSlice],
	(user) => user.links
)

export const selectPublicUserBio = createSelector(
	[publicUserSlice],
	(user) => user.bio
)

export const selectPublicUserProfession = createSelector(
	[publicUserSlice],
	(user) => user.profession
)

export const selectPublicUserCompanyName = createSelector(
	[publicUserSlice],
	(user) => user.companyName
)

export const selectPublicUserDefaultTheme = createSelector(
	[publicUserSlice],
	(user) => user.defaultTheme
)