import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Highlight, highlightState } from "./highlightState";
import { ItemID } from "../../types/itemTypes";
import { getEntityByID, updateEntity } from "../../utils/firebaseUtils";
import { RootState } from "../reducers";
import { ApiStatus } from "../../types/storeTypes";

export const saveHighlightedItems = createAsyncThunk('highlight/saveItems',
	async (_, thunkAPI) => {
		try {
			const { highlight: { items }, user } = thunkAPI.getState() as RootState
			const result = await updateEntity('highlights', { items }, user.id)
			if (result.saved) return
			throw new Error('Error saving highlights')	
		} catch (error) {
			return		
		}
	}
)

type getHighlightedItemsForUserArgs = {
	userId: string;
}

export const getHighlightedItemsForUser = createAsyncThunk<Highlight, getHighlightedItemsForUserArgs>(
	'highlight/getForUser',
	async (args, _) => {
		try {
			const { userId } = args
			const result = await getEntityByID('highlights', userId) as Highlight
			return result
		} catch (error) {
			console.log(error);
			throw error
		}
	}
)

export const getHighlightedItems = createAsyncThunk('highlight/get',
	async (_, thunkAPI) => {
		try {
			const { user } = thunkAPI.getState() as RootState
			const result = await getEntityByID('highlights', user.id) as Highlight
			return result
		} catch (error) {
			console.log(error);
			return
		}
	}
)

export const highlightSlice = createSlice({
	name: 'highlight',
	initialState: { ...highlightState() },
	reducers: {
		addHighlightedItem: (state, action: PayloadAction<ItemID>) => {
			state.items = state.items.concat(action.payload)
		},
		removeHighlightedItem: (state, action: PayloadAction<ItemID>) => {
			state.items = state.items.filter((itemId) => itemId !== action.payload)
		},
		toggleHighlightedItem: (state, action: PayloadAction<ItemID>) => {
			const index = state.items.findIndex((itemId) => itemId === action.payload)
			let newItems;
			if (index > -1) {
				newItems = state.items.filter((itemId) => itemId !== action.payload)
			} else {
				newItems = state.items.concat(action.payload)
			}
			state.items = newItems
		}
	},
	extraReducers: builder => {
		builder.addCase(saveHighlightedItems.pending, (state) => {
			state.status = ApiStatus.PENDING
		})
		builder.addCase(saveHighlightedItems.fulfilled, (state, _) => {
			state.status = ApiStatus.SUCCESS
		})
		builder.addCase(getHighlightedItems.pending, (state) => {
			state.status = ApiStatus.PENDING
		})
		builder.addCase(getHighlightedItems.fulfilled, (state, action) => {
			state.status = ApiStatus.SUCCESS
			state.items = action.payload?.items || []
		})
		builder.addCase(getHighlightedItemsForUser.pending, (state) => {
			state.status = ApiStatus.PENDING
		})
		builder.addCase(getHighlightedItemsForUser.fulfilled, (state, action) => {
			state.status = ApiStatus.SUCCESS
			state.items = action.payload?.items || []
		})
	}
})

export const {
	addHighlightedItem,
	removeHighlightedItem,
	toggleHighlightedItem,
} = highlightSlice.actions

export default highlightSlice.reducer