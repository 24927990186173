import { createListenerMiddleware } from "@reduxjs/toolkit";
import { customerRemoved } from "../reducers/customers/customersSlice";
import { createOrUpdateUser, removeCustomerForUser } from "../reducers/user/userSlice";

const customersMiddleware = createListenerMiddleware()

customersMiddleware.startListening({
	type: 'customer/remove/fulfilled',
	effect: async (action, listenerApi) => {
		listenerApi.dispatch( customerRemoved(action.payload) )
		listenerApi.dispatch( removeCustomerForUser(action.payload) )
		listenerApi.dispatch( createOrUpdateUser() )
	}
})

export default customersMiddleware;