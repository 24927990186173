import * as React from 'react';
import { useDropzone } from 'react-dropzone'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { saveAvatar } from '../../store/actions/logoActions';
import { useDispatch, useSelector } from 'react-redux';
import { removeAvatar } from '../../store/reducers/user/userSlice';
import { styled } from '@mui/material';
import YourLogo from './YourLogo';
import DeleteIcon from '../atoms/icons/DeleteIcon';

const StyledBox = styled(Box)(({theme}) => ({
	position: 'absolute',
	height: '2em',
	width: '2em',
	top: '-0.25em',
	right: '-0.25em',
	padding: '0em',
	borderRadius: '50%',
	zIndex: 2,
	backgroundColor: theme.palette.background.default
}))

const RemoveLogo = ({onRemove}) => {
	return (
		<StyledBox onClick={onRemove}>
			<DeleteIcon iconSize="medium" iconColor='mediumGrey' />
		</StyledBox>
	)
}

const darkStyle = {
    avatar: {
        bgcolor: 'white',
        borderRadius: '50%',
        color: 'black',
    },
    button: {
        borderRadius: '50%',
    }
}

function LogoUpload({ size = 'large', position = "center" }){

	const dispatch = useDispatch()
	const avatar = useSelector(state => state.user.avatar)

	const [ uploadedLogo, setUploadedLogo ] = React.useState(false);

	const removeLogo = () => {
		setUploadedLogo(false)
		dispatch( removeAvatar() )
	}

	React.useEffect(() => {
		if (avatar.URL) setUploadedLogo(avatar.URL)
	},[avatar])

	const onDrop = React.useCallback(acceptedFiles => {
		console.log('ondrop');
		const uploadedFile = acceptedFiles[0];
		const TemporaryURL = URL.createObjectURL(uploadedFile);
		setUploadedLogo(TemporaryURL)

		const fileMeta = {
			type: uploadedFile.type,
		}

		const reader = new FileReader();
		reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => console.log('file reading has failed')
		reader.onload = () => {
		dispatch( saveAvatar({
			arrayBuffer: reader.result,
			meta: fileMeta,
		}) )
		}
		reader.readAsArrayBuffer(uploadedFile)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			'image/*': ['.jpeg', '.png'],
		},
		maxFiles: 1,
	})

	const avatarImage = uploadedLogo ? { backgroundImage: `url(${uploadedLogo})` } : {}

	return (
		<>
		{uploadedLogo ?
			<Box sx={{position: 'relative', width: '100%', height: '100%'}}>
				<Button
				sx={{
					...darkStyle.avatar,
					...avatarImage,
					width: '100%',
					height: '100%',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: '50% 50%',
					backgroundSize: 'cover',
					position:'absolute',
					}}
				>
				</Button>
				<RemoveLogo onRemove={removeLogo} />
			</Box>
		: <Button {...getRootProps()}
			sx={{
				...darkStyle.button,
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
			}}>
				<input {...getInputProps()} />
				<YourLogo />
			</Button> }
		</>
  	)
}

export default LogoUpload;