import { combineReducers } from "@reduxjs/toolkit"
import userReducer from './user/userSlice'
import invoicesReducer from './invoices/invoicesSlice'
import customerReducer from './customer/customerSlice'
import customersReducer from './customers/customersSlice'
import invoiceReducer from './invoice/invoiceSlice'
import miscReducer from './misc/miscSlice'
import publicUserReducer from "./publicUser/publicUserSlice"
import messageReducer from "./message/messageSlice"
import itemReducer from "./item/itemSlice"
import contactReducer from './contact/contactSlice'
import highlightReducer from './highlight/highlightSlice'

// export const testRootReducer = combineReducers({
// 	user: userReducer,
// 	customer: customerReducer,
// 	customers: customersReducer,
// 	invoice: invoiceReducer,
// 	invoices: invoicesReducer,
// 	misc: miscReducer,
// 	publicUser: publicUserReducer,
// 	messages: messageReducer,
// 	items: itemReducer,
// 	contact: contactReducer,
// 	highlight: highlightReducer,
// })

export const rootReducer = combineReducers({
	user: userReducer,
	customer: customerReducer,
	customers: customersReducer,
	invoice: invoiceReducer,
	invoices: invoicesReducer,
	misc: miscReducer,
	publicUser: publicUserReducer,
	messages: messageReducer,
	items: itemReducer,
	contact: contactReducer,
	highlight: highlightReducer,
})


export type RootState = ReturnType<typeof rootReducer>