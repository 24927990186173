import { useParams } from "react-router"
import CenterWrap from "../../components/layouts/centerwrap/CenterWrap";
import Column from "../../components/layouts/column/Column";
import { Grid, Typography } from "@mui/material";
import QuickLinks from "../../components/molecules/quicklinks/QuickLinks";
import { ItemID } from "../../store/types/itemTypes";
import { useAppSelector } from "../../store/hooks";
import { selectItemsWithFile } from "../../store/reducers/item/itemSelectors";
import ItemList from "../../components/organisms/itemlist/ItemList";
import { selectCompanyName, selectQuickLinks } from "../../store/reducers/user/userSelectors";


const ProfileTop = () => {

	const companyName = useAppSelector(selectCompanyName)
	const quickLinks = useAppSelector(selectQuickLinks)

	return (
		<Grid container direction="row" alignItems={'center'}>
			<Grid item xs={12} sm={6}><Typography variant="h3" align="left">{companyName}</Typography></Grid>
			<Grid item xs={12} sm={6}><QuickLinks quickLinks={quickLinks} alignment="right" /></Grid>
		</Grid>
	)
}

const ProfileItems = () => {
	const params = useParams()
	const itemId = params.itemId as ItemID
	const items = useAppSelector(state => selectItemsWithFile(state))
	return (
		<>
			<ProfileTop />
			<ItemList items={items} />
		</>
	)
}

export default ProfileItems