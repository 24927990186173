import Typography from "@mui/material/Typography";
import Expander from "../../expander/Expander";


interface BiographyProps {
	bioText: string;
}

const Biography = ({ bioText }: BiographyProps) => {

	return (
		<Expander>
			<Typography variant="textInput" textAlign={'left'} sx={{ whiteSpace: 'pre-line' }}>{bioText}</Typography>
		</Expander>
	)
}

export default Biography