import { SxProps, styled } from "@mui/material";
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const StyledBox = styled(Box)(({theme}) => ({
	width: '100%',
	position: 'sticky',
	top: '64px',
	[theme.breakpoints.down("sm")]: {
		top: '56px'
	},
	zIndex: '2',
}))

const ProfileBox = styled(Box)(({theme}) => ({
	paddingTop: '8px',
	backgroundColor: theme.palette.background.default,
}))

const SpacerBox = styled(Box)(({theme}) => ({
	position: 'absolute',
	left: '0',
	right: '0',
	bottom: '-8px',
	height: '8px',
	background: 'linear-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 30%, rgba(255,255,255,0) 100%)',
}))

interface ProfileHeaderProps {
	profileName: string;
	title: string;
	sx?: SxProps;
}


const ProfileHeader = ({ profileName, title, sx = {} }: ProfileHeaderProps) => {

	return (
		<StyledBox>
			<ProfileBox display={'flex'} flexDirection={'row'} alignItems={'baseline'} columnGap={2}>
				<Typography variant="h1">{ profileName }</Typography>
				<Typography variant="h6">{`/ ${title}`}</Typography>
			</ProfileBox>
			<SpacerBox />
		</StyledBox>
	)
}


export default ProfileHeader