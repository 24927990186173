import Box from '@mui/material/Box';
import { CSSProperties, PropsWithChildren } from 'react';

interface CenterWrapProps extends PropsWithChildren {
	paddingTop?: CSSProperties['paddingTop'];
}

const CenterWrap = ({ paddingTop = '2em', children}:CenterWrapProps) => {
    return (
		<Box sx={{
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			paddingTop: paddingTop,
		}}>		
			<Box sx={{
				boxSizing: 'border-box',
				width: {
					xs: '100%',
					sm: '100%',
					md: '800px',
				},
				paddingRight: {
					xs: '1em',
					sm: '1em',
				},
				paddingLeft: {
					xs: '1em',
					sm: '1em',
				},
				height: '100%',
				display:'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
				flexDirection: 'column',
			}}>
				{ children }
			</Box>
	  	</Box>
    )
  }

  export default CenterWrap