export const redBlack = {
	palette: {
		primary: {
			light: '#ff5d4c',
			main: '#d42222',
			dark: '#9a0000',
			contrastText: '#ffffff'
		},
		secondary: {
			light: '#2c2c2c',
			main: '#000000',
			dark: '#000000',
			contrastText: '#ffffff',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		// https://meyerweb.com/eric/tools/color-blend/#D42222:000000:1:hex
		frame: {
			col1: '#d42222',
			col2: '#000000',
			mid: '#6A1111',
		},
		pinkOrange: {
			col1: '#dce775',
			col2: '#3949ab',
			mid: '#FF7F54',
		},
	  },
}