import MenuItem from '@mui/material/MenuItem';
import { ALL_COUNTRIES } from './Countries';

const SWEDEN = ALL_COUNTRIES.find(el => el.code === 'SE')
const NORWAY = ALL_COUNTRIES.find(el => el.code === 'NO')
const DENMARK = ALL_COUNTRIES.find(el => el.code === 'DK')
const FINLAND = ALL_COUNTRIES.find(el => el.code === 'FI')

export const FORM_PROPS = {
  ROW_SPACING: 2,
  COLUMN_SPACING: 2,
  SELECT_WIDTH: {
    width: '50%'
  }
}
export const ALL_COUNTRIES_ITEMS = ALL_COUNTRIES.map((item, index) => 
  <MenuItem key={index} value={item.code}>{item.name}</MenuItem>
)

export const COUNTRY_NAME = (code) => {
	return ALL_COUNTRIES.find(el => el.code === code).name
}

export const COUNTRIES_ITEMS = [
  <MenuItem key="Country-0" value={SWEDEN.code}>{SWEDEN.name}</MenuItem>,
  <MenuItem key="Country-1" value={NORWAY.code}>{NORWAY.name}</MenuItem>,
  <MenuItem key="Country-2" value={DENMARK.code}>{DENMARK.name}</MenuItem>,
  <MenuItem key="Country-3" value={FINLAND.code}>{FINLAND.name}</MenuItem>,
]

export const COUNTRIES_FOR_SELECT = [ ...COUNTRIES_ITEMS ] //, <Divider key="country-divider" />, ...ALL_COUNTRIES_ITEMS]

export const PAYMENT_TERMS = [
  <MenuItem key="Terms-0" value="0">0 days</MenuItem>,
  <MenuItem key="Terms-1" value="7">7 days</MenuItem>,
  <MenuItem key="Terms-2" value="10">10 days</MenuItem>,
  <MenuItem key="Terms-3" value="14">14 days</MenuItem>,
  <MenuItem key="Terms-4" value="30">30 days</MenuItem>,
]

export const VAT_OPTIONS = [
	<MenuItem key="VAT-0" value={0}>0%</MenuItem>,
	<MenuItem key="VAT-6" value={6}>6%</MenuItem>,
	<MenuItem key="VAT-12" value={12}>12%</MenuItem>,
	<MenuItem key="VAT-25" value={25}>25%</MenuItem>,
]