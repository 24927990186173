import { useAppSelector, useAppDispatch } from "../../store/hooks"
import FloatingSaveButton from "../../components/organisms/floatingsavebuttons/FloatingSaveButtons"
import MessageForm from "../../components/forms/MessageForm"
import { selectMessageById } from "../../store/reducers/message/messageSelectors"
import { createOrUpdateMessage, sendMessage, sendMessagePreview } from "../../store/reducers/message/messageActions"
import { useParams } from "react-router-dom"
import { useState } from "react"
import ContactSelect from "../../components/organisms/contactselect/ContactSelect"

type MessageStates = 'content' | 'contacts' | 'preview' | 'send';

const nextLabel = new Map<MessageStates, string>([
	['content', 'Next'],
	['contacts', 'Next'],
	['preview', 'Send preview'],
	['send', 'Send']
])

const EditEmail = () => {

	const { emailId } = useParams()
	const dispatch = useAppDispatch();

	const [messageState, setMessageState] = useState<MessageStates>('preview')
	// const [messageState, setMessageState] = useState<MessageStates>('content')

	const currentMessage = useAppSelector((state) => selectMessageById(state, emailId as string))
	console.log(currentMessage);
	
	const handleNextClick = () => {
		if (messageState === 'content') return setMessageState('contacts')
			if (messageState === 'contacts') {
				dispatch( createOrUpdateMessage({ messageId: emailId }) )
				setMessageState('preview')
			}
			if (messageState === 'preview') {
				dispatch( sendMessagePreview(emailId as string) )	
			}
			if (messageState === 'send') {
				dispatch( sendMessage(emailId as string) )	
			}
	}

	const handlePrevClick = () => {
		if (messageState === 'content') return
		if (messageState === 'contacts') return setMessageState('content')
		if (messageState === 'preview') return setMessageState('contacts')
		if (messageState === 'send') return setMessageState('preview')
	}

	if (!currentMessage) return <></>

	return (
		<>
			{{
				'content' : <MessageForm canEdit={true} message={currentMessage} />,
				'contacts' : <ContactSelect messageId={currentMessage.id} />,
				'preview' : <MessageForm canEdit={false} message={currentMessage} />,
				'send': <></>,
			}[messageState]}
			{ messageState !== 'content' && <FloatingSaveButton onSave={handlePrevClick} label="Back" position="left" /> }
			<FloatingSaveButton onSave={handleNextClick} label={nextLabel.get(messageState)} />
		</>
	)
}

export default EditEmail