import { ComponentProps, FC } from "react";
import { Grid, Chip, Box, Typography } from "@mui/material"
import { styled } from '@mui/material';
import { FORM_PROPS } from "../../../helpers/FormProps";
import { useState } from "react";
import { setRecipientEmail, toggleContactAsRecipient } from "../../../store/reducers/message/messageSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectContacts } from "../../../store/reducers/contact/contactSelectors";
import Column from "../../layouts/column/Column";
import { Contact } from "../../../store/reducers/contact/contactState";
import { selectMessageContacts } from "../../../store/reducers/message/messageSelectors";

interface StyledChipProps extends ComponentProps<typeof Chip> {
	selected?: boolean;
}

const StyledChip: FC<StyledChipProps> = styled(Chip)<StyledChipProps>(({theme, selected = false}) => ({
	...theme.typography.buttonSm,
	'&:hover': {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.contrastText,
	},
	['.MuiChip-label']: {
		padding: '20px',
	},
	...(selected && {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	}),
}))

interface ContactSelectProps {
	messageId: string;
	// addEmailFunc: (email: string) => void;
	// removeEmailFunc: (email: string) => void;
}

// const ContactSelect = ({ messageId, addEmailFunc, removeEmailFunc }: ContactSelectProps) => {
const ContactSelect = ({ messageId }: ContactSelectProps) => {

	const dispatch = useAppDispatch()

	
	const selectedEmails: string[] = []

	const [showCustomerList, setShowCustomerList] = useState(true)
	const contacts = useAppSelector(selectContacts)
	const selectedContacts = useAppSelector((state) => selectMessageContacts(state, messageId))

	const toggleContact = (contact: Contact) => {
		console.log('toggle', messageId, contact);
		
		dispatch( toggleContactAsRecipient({ messageId: messageId, contactId: contact.id }))
	}

	const addEmail = (email: string) => {
		// addEmailFunc(email)
	}

	const removeEmail = (email: string) => {
		// removeEmailFunc(email)
	}

	return(
		<Column>
			<Typography variant='h4' alignSelf={'flex-start'}>Select recipients</Typography>
			<Grid
				container
				direction="row"
				rowGap={FORM_PROPS.ROW_SPACING}
				columnGap={FORM_PROPS.COLUMN_SPACING}>
				{ contacts.map((contact, index) =>
					<Grid item key={`contact-${index}`}>
						<StyledChip
							selected={selectedContacts.includes(contact.id)}
							variant="outlined"
							label={contact.email}
							onClick={() => { toggleContact(contact) }}
							/>
					</Grid>	
				)}
			</Grid>
			{/* { showCustomerList ?
			<Grid
				justifyContent="flex-start"
				direction="row"
				container
				sx={{ padding: '8px' }}
				spacing={FORM_PROPS.COLUMN_SPACING}>
				<Grid item key={`customer-create`}>
					<StyledChip
						purpose='new'
						variant="outlined"
						label={'Create new'}
						onClick={enterRecipient}
					/>
				</Grid>
			</Grid> :
			<EmailSelect emails={selectedEmails} addEmail={addEmail} removeEmail={removeEmail} /> } */}
		</Column>
	)
}

export default ContactSelect