import currency from "currency.js"
import { CURRENCIES, Currency } from "../../../helpers/MoneyHelper"
import { DEFAULT_VAT, VATRateType } from "../../types/invoiceTypes"
import { Item, defaultItem } from "../../types/itemTypes"
import { InitialInvoiceState } from "./invoiceState"


const applyDiscount = (item: Item, currency: Currency): Item => {
	const newItem: Item = {
		id: item.id,
		uploads: item.uploads,
		type: item.type,
		files: item.files,
		title: item.title,
		description: item.description,
		currentVAT: item.currentVAT,
		discount: item.discount,
		itemNetAmountExclVAT: CURRENCIES[currency](item.itemNetAmountExclVAT).value,
		amountExclVAT: calculateDiscount(CURRENCIES[currency](item.itemNetAmountExclVAT), item.discount),
		itemVAT: CURRENCIES[currency](item.amountExclVAT).multiply(item.currentVAT / 100).value,
		itemAmount: CURRENCIES[currency](item.amountExclVAT).add(item.itemVAT).value,
		created: item.created,
	}
	return newItem
}

const calculateDiscount = (amount: currency, discount: number = 0): number => {
	if (discount === 0) return amount.value
	return amount.multiply(1 - discount / 100 ).value
}

const compareItems = (a: Item, b: Item) => {
    return JSON.stringify(a) === JSON.stringify(b)
}

const diffItems = (oldItem: Item, newItem: Item, currency: Currency): { netDiff: number; VATDiff: number; amountDiff: number; } => {
	const oldItemNetAmountExclVAT = CURRENCIES[currency](oldItem.itemNetAmountExclVAT)
	const oldItemAmountExclVAT = calculateDiscount( oldItemNetAmountExclVAT, oldItem.discount)
	const oldItemVAT = CURRENCIES[currency](oldItemAmountExclVAT).multiply(oldItem.currentVAT / 100)
	const oldItemAmount = CURRENCIES[currency](oldItemAmountExclVAT).add(oldItemVAT)

	const newItemNetAmountExclVAT = CURRENCIES[currency](newItem.itemNetAmountExclVAT)
	const newItemAmountExclVAT = CURRENCIES[currency](calculateDiscount( newItemNetAmountExclVAT, newItem.discount))
	const newItemVAT = newItemAmountExclVAT.multiply(newItem.currentVAT / 100)
	const newItemAmount = newItemAmountExclVAT.add(newItemVAT)

	const netDiff = newItemAmountExclVAT.subtract(oldItemAmountExclVAT).value
	const VATDiff = newItemVAT.subtract(oldItemVAT).value
	const amountDiff = newItemAmount.subtract(oldItemAmount).value

	return { netDiff, VATDiff, amountDiff }
}

export const recalculateTotal = (state: InitialInvoiceState, elementId: string, action = 'add') => {

    // console.log(elementId);
	const toCurrency = (val: number): currency => CURRENCIES[currency](val)

    const { currency, currentItem } = state
    let { totalAmount, netAmount, totalVAT } = state
	let allItems = [ ...state.items]
	const newItem = applyDiscount(currentItem, currency)

	totalAmount = toCurrency(totalAmount).value
	netAmount = toCurrency(netAmount).value

	// Check if item exists
    const exists = allItems.findIndex(el => el.id === elementId)

    if (exists >= 0 && action === 'add') {
		// Item exists, should edit
        console.log('should edit');
		const oldItem = applyDiscount(allItems[exists], currency)

		// If no change - return
		if (compareItems(oldItem, newItem)) {
			return {
				netAmount,
				totalAmount,
				totalVAT,
				allItems,
			}
		}

		const { netDiff, VATDiff, amountDiff } = diffItems(oldItem, newItem, currency)

		// Update totals
		netAmount = toCurrency(netAmount).add(netDiff).value
		totalAmount = toCurrency(totalAmount).add(amountDiff).value
		totalVAT['total'] = toCurrency(totalVAT['total']).add(VATDiff).value
		const vatRateForItem = newItem.currentVAT as VATRateType
		totalVAT[vatRateForItem] = toCurrency(totalVAT[vatRateForItem]).add(VATDiff).value
		
		// Update items
		allItems[exists] = newItem

    } else if (exists >= 0 && action === 'remove') {
		// Should remove item
        console.log('should remove');

		const oldItem = defaultItem({})
		const { netDiff, VATDiff, amountDiff } = diffItems(newItem, oldItem, currency)

		// Update totals
		netAmount = toCurrency(netAmount).add(netDiff).value
		totalAmount = toCurrency(totalAmount).add(amountDiff).value
		totalVAT['total'] = toCurrency(totalVAT['total']).add(VATDiff).value
		const vatRateForItem = newItem.currentVAT as VATRateType
		totalVAT[vatRateForItem] = toCurrency(totalVAT[vatRateForItem]).add(VATDiff).value

		// Update items
        allItems.splice(exists, 1)

		// If no items, re-init
		if (allItems.length === 0) {
			netAmount = CURRENCIES[currency](0).value
			totalAmount = CURRENCIES[currency](0).value
			totalVAT = { ...DEFAULT_VAT }
			allItems = []
		}
    } else {
		// New item, add
        console.log('will add', newItem);

		const oldItem = applyDiscount( defaultItem({}), currency )
		const { netDiff, VATDiff, amountDiff } = diffItems(oldItem, newItem, currency)

		// Update totals
		netAmount = toCurrency(netAmount).add(netDiff).value
		totalAmount = toCurrency(totalAmount).add(amountDiff).value
		totalVAT['total'] = toCurrency(totalVAT['total']).add(VATDiff).value
		const vatRateForItem = newItem.currentVAT as VATRateType
		totalVAT[vatRateForItem] = toCurrency(totalVAT[vatRateForItem]).add(VATDiff).value

		// Update items
        allItems.push(newItem)
    }

	return {
        netAmount,
        totalAmount,
        totalVAT,
        allItems,
    }
  
}