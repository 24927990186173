import { Box, Stack, Typography, useTheme } from "@mui/material"
import CenterWrap from "../../layouts/centerwrap/CenterWrap"
import TextButton from "../../buttons/TextButton";
import { FrameAnimation } from "../../animations/FrameAnimation";
import Row from "../../layouts/row/Row";
import { useNavigate } from "react-router-dom";
import PejmeLogo from "../../atoms/pejmelogo/PejmeLogo";
import { useContext } from "react";
import { AuthContext } from "../../../helpers/auth/AuthProvider";

const WtsFooter = () => {
	const theme = useTheme();
	const navigate = useNavigate()
	const { setShowCreateAccount } = useContext(AuthContext)

	const { palette: { frame } } = theme
	const animation = FrameAnimation(`${frame.col1}, ${frame.col2}`);
	
	const handleCreateProfile = () => {
		setShowCreateAccount(true)
	}

	const handleOtherProfiles = () => {
		navigate('/')
	}

	const handleContact = () => {
		window.open('mailto:hey@pejme.se')
	}

	return (
		<Box sx={{
			...animation,
			paddingTop: '20px',
			paddingBottom: '40px',
		}}>
			<CenterWrap paddingTop={''}>
				<Stack spacing={4} width={'100%'}>
					<Row>
						<TextButton onClick={handleCreateProfile}><Typography color={'white'} sx={{textDecoration: 'underline'}}>Create a profile</Typography></TextButton>
						<TextButton onClick={handleOtherProfiles}><Typography color={'white'} sx={{textDecoration: 'underline'}}>Other profiles</Typography></TextButton>
						<TextButton onClick={handleContact}><Typography color={'white'} sx={{textDecoration: 'underline'}}>Contact us</Typography></TextButton>
					</Row>
					<PejmeLogo />
					<Typography textAlign={'center'} color={'white'}>© 2023 Pejme - Made in Sweden</Typography>
				</Stack>
			</CenterWrap>
		</Box>
	)
}

export default WtsFooter