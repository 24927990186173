import { keyframes } from "@mui/system";
import { styled } from '@mui/material';
import { PropsWithChildren } from "react";

const rotaAnimation = keyframes`
to { transform: rotate(360deg); }
`

// const opaAnimation = keyframes`
// 12.0% { opacity: 0.80; }
// 19.5% { opacity: 0.88; }
// 37.2% { opacity: 0.64; }
// 40.5% { opacity: 0.52; }
// 52.7% { opacity: 0.69; }
// 60.2% { opacity: 0.60; }
// 66.6% { opacity: 0.52; }
// 70.0% { opacity: 0.63; }
// 79.9% { opacity: 0.60; }
// 84.2% { opacity: 0.75; }
// 91.0% { opacity: 0.87; }
// `
const opaAnimation = keyframes`
    100% { opacity: 1 }
`

const ListItem = ({ className}: { className: string; }) => {
	return ( <li className={className} /> )
}

const itemSize = 6
const itemWH = itemSize / 6
const itemWidth = itemWH / 5

const StyledListItem = styled(ListItem)(({theme}) => ({
    width: `${itemWidth}em`,
    height: `${itemWidth}em`,
    position: 'absolute',
    borderRadius: '50%',
    listStyle: 'none',
    '&.topLeft': {
        background: theme.palette.primary.light,
        top: '0',
        left: '50%',
        marginLeft: `-${itemWidth / 2}em`,
        transformOrigin: '50% 250%',
        animation: `${rotaAnimation} 1.13s linear infinite, ${opaAnimation} 3.67s ease-in-out infinite alternate`
    },
    '&.topRight': {
        background: theme.palette.primary.light,
        top: '50%',
        right: '0',
        marginTop: `-${itemWidth / 2}em`,
        transformOrigin: '-150% 50%',
        animation: `${rotaAnimation} 1.86s linear infinite, ${opaAnimation} 4.29s ease-in-out infinite alternate`
    },
    '&.bottomLeft': {
        background: theme.palette.primary.light,
        bottom: '0',
        left: '50%',
        marginLeft: `-${itemWidth / 2}em`,
        transformOrigin: '50% -150%',
        animation: `${rotaAnimation} 1.45s linear infinite, ${opaAnimation} 5.12s ease-in-out infinite alternate`
    },
    '&.bottomRight': {
        background: theme.palette.primary.light,
        top: '50%',
        left: '0',
        marginTop: `-${itemWidth / 2}em`,
        transformOrigin: '250% 50%',
        animation: `${rotaAnimation} 1.72s linear infinite, ${opaAnimation} 5.25s ease-in-out infinite alternate`
    }
}))

interface ListContainerProps extends PropsWithChildren {}

const ListContainer = styled((props: ListContainerProps) => {
    const { children, ...otherProps } = props
    return (
        <ul {...otherProps}>{children}</ul>
    )
})(({}) => ({
	fontSize: `${itemSize}em`,
	width: `${itemWH}em`,
	height: `${itemWH}em`,
	margin: '20px auto',
	position: 'relative',
	listStyle: 'none',
	borderRadius: '50%',
	border: '.01em solid rgba(150,150,150,0.1)',
}))

const SpinningDots = () => {

    return (
    <ListContainer>
        <StyledListItem className="topLeft"/>
        <StyledListItem className="topRight"/>
        <StyledListItem className="bottomLeft"/>
        <StyledListItem className="bottomRight"/>
    </ListContainer>
    )
}

export default SpinningDots