import TextField, { TextFieldProps } from "@mui/material/TextField";
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Theme, styled } from "@mui/material";
import { CSSProperties, FC, useState, ChangeEvent } from "react";
import FilledButton from "../../buttons/FilledButton";
import { CtaFieldType } from "../../../store/types/messageTypes";

type SharedProps = {
	typography?: keyof Theme["typography"];
	textAlign?: 'center' | 'left' | 'right';
}

type StyledTextInputProps = TextFieldProps & SharedProps & { }

const StyledTextField = styled(TextField, {
	shouldForwardProp: prop => prop !== 'typography' && prop !== 'textAlign'
})<StyledTextInputProps>(({theme, typography, textAlign}) => ({
	...(typography && { '& input': { ...theme.typography[typography] as CSSProperties, color: 'yellow' }}),
	...(textAlign && { '& input': { textAlign } }),
}))

type CtaCopyInputProps = {
	value: string;
	onChange: (e: any) => void;
	placeholder: string;
}

const CtaCopyInput: FC<CtaCopyInputProps> = ({ value, onChange, placeholder }) => {
	return (
		<StyledTextField
			type="text"
			value={value}
			onChange={onChange}
			typography="button"
			placeholder={placeholder}
			variant="standard"
			textAlign="center"
			InputProps={{disableUnderline:true}}
		/>
	)
}

type OnChangeType = {
	e: ChangeEvent<HTMLInputElement>;
	nestedKey: keyof CtaFieldType['fieldValue'];
}

interface CtaInputProps extends SharedProps {
	value: CtaFieldType['fieldValue'];
	onChange: ({e, nestedKey}: OnChangeType) => void;
	disabled?: boolean;
}

const CtaInput: FC<CtaInputProps> = ({ value, onChange, disabled = false, typography = 'h2' }) => {

	const { link, copy } = value;

	const [showCopy, setShowCopy] = useState(false)
	
	const handleShow = () => setShowCopy(!showCopy)

	return (
		<FilledButton btnColor="email" btnStyle="rounded" stretch={false} onClick={() => { }}>
			{ showCopy ?
				<CtaCopyInput value={copy} onChange={(e) => onChange({e, nestedKey: 'copy'})} placeholder="Button text"/> :
				<CtaCopyInput value={link}  onChange={(e) => onChange({e, nestedKey: 'link'})} placeholder="Button link"/>
			}
			{ link ? <LinkOffIcon onClick={handleShow} /> : <AddLinkIcon onClick={handleShow} /> }
		</FilledButton>
	)
}

export default CtaInput