import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Theme, styled } from "@mui/material";
import { FC, CSSProperties } from "react";

type SharedProps = {
	displayType?: 'regular' | 'message';
	typography?: keyof Theme["typography"];
}

type StyledTextFieldProps = TextFieldProps & SharedProps & {}

const StyledTextField = styled(TextField, {
	shouldForwardProp: prop => prop !== 'typography' && prop !== 'displayType'
})<StyledTextFieldProps>(({theme, typography}) => ({
	...(typography && { ...theme.typography[typography] as CSSProperties})
}))

interface TextBoxProps extends SharedProps {
	value: string;
	label?: string;
	onChange: (e: any) => void;
	disabled?: boolean;
	placeholder?: string;
	canEdit?: boolean;
}

const TextBox: FC<TextBoxProps> = ({ value, label, onChange, canEdit = true, disabled = false, placeholder, displayType = "regular", typography = "p" }) => {
	
	return (
		<StyledTextField
			fullWidth
			type="text"
			placeholder={placeholder}
			label={label}
			multiline={true}
			minRows={1}
			disabled={disabled}
			value={value}
			onChange={onChange}
			variant="standard"
			displayType={displayType}
			InputProps={{
				disableUnderline: displayType === 'message',
				readOnly: canEdit ? false : true,
			}}
			typography={typography}
			/>
	)
}

export default TextBox