import { LoaderFunctionArgs, createBrowserRouter } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";
import PagesRouter from "./PagesRouter";
import DashboardRouter from "./DashboardRouter";
import AuthRouter from "./AuthRouter";
import ProfileRouter from "./ProfileRouter";
import AdminRouter from "./AdminRouter";
import WtsPageLayout from "../pages/elements/WtsPageLayout";
import { PejmeRouter } from "./PejmeRouter";
import LiteRouter from "./LiteRouter";

export interface LoaderParams extends LoaderFunctionArgs {
	params: {
		itemId: string;
		messageId: string;
		customerId: string;
		slug: string;
		invoiceId: string;
	}
}

export const router = createBrowserRouter([
  {
    element: <WtsPageLayout />,
	errorElement: <ErrorPage />,
	children: [
		...PagesRouter, // Editorial pages
		...AuthRouter, // Auth-routes
		...ProfileRouter, // WTS Profiles
		...LiteRouter, // WTS Lite
		...AdminRouter, // WTS Admin
		// ...PejmeRouter,
		// ...DashboardRouter,
		
	]
  },
])