import { ChangeEvent, useState } from "react";
import { Contact, defaultContact } from "../../../store/reducers/contact/contactState";
import { Stack, Typography } from "@mui/material";
import { FORM_PROPS } from "../../../helpers/FormProps";
import FilledButton from "../../buttons/FilledButton";
import TextInput from "../../inputs/text/TextInput";

interface AddContactProps {
	onSubmit: (contact: Contact) => void;
}

const AddContact = ({ onSubmit }: AddContactProps) => {

	const [ctName, setCtName] = useState<string>('');
	const [ctEmail, setCtEmail] = useState<string>('');

	const ctValid = ctEmail.length > 0

	const handleName = (e: ChangeEvent<HTMLInputElement>) => {
		setCtName(e.currentTarget.value)
	}
	const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
		setCtEmail(e.currentTarget.value)
	}

	const handleSubmit = () => {
		const ct = defaultContact({
			name: ctName,
			email: ctEmail,
		})
		onSubmit(ct)
	}

	return (
		<Stack spacing={FORM_PROPS.ROW_SPACING}>
			<TextInput placeholder="Email" label="Email" value={ctEmail} onChange={handleEmail} />
			<TextInput placeholder="Name" label="Name" value={ctName} onChange={handleName} />
			<FilledButton btnStyle="rounded" size="medium" btnColor="black" alignSelf="flex-end" stretch={false} onClick={handleSubmit} disabled={!ctValid}>
				Save
			</FilledButton>
		</Stack>
	)
}

export default AddContact
