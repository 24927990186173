import { CircularProgress, Typography, styled, useTheme } from "@mui/material"
import FilledButton from "../../buttons/FilledButton"
import { ApiStatus } from "../../../store/types/storeTypes";

const StyledCircularProgress = styled(CircularProgress)(({theme}) => ({
	color: theme.palette.primary.contrastText
}))

interface SaveButtonProps {
	label?: string;
	onSave: () => void;
	disabled?: boolean;
	saveStatus: ApiStatus;
}

const SaveButton = ({label = 'Save', onSave, disabled, saveStatus}: SaveButtonProps) => {

	const theme = useTheme();

	const canSave = saveStatus === ApiStatus.SUCCESS
	
	return (
		<FilledButton btnStyle="rounded" btnColor="black" onClick={onSave} disabled={disabled}>
			{ canSave ?
				<Typography variant="buttonSm" color={theme.palette.primary.contrastText}>{label}</Typography> :
				<StyledCircularProgress size={'20px'} />
			}
		</FilledButton>
	)
}

export default SaveButton

// disabled={!canSave || disabled}>