import { useNavigate } from "react-router-dom"
import EmptyList from "../../components/molecules/emptylist/EmptyList"
import OutlineButton from "../../components/atoms/outlinebutton/OutlineButton"
import MessageList from "../../components/lists/MessageList"
import { useAppSelector } from "../../store/hooks"
import { selectSortedMessages } from "../../store/reducers/message/messageSelectors"



const Emails = () => {
	const navigate = useNavigate()

	const messages = useAppSelector(selectSortedMessages)
	const hasMessages = messages.length > 0

	const handleAddMessage = () => {
		navigate('add')
	}

	return(
		<>
			{ hasMessages && <OutlineButton label="Add email" onClick={handleAddMessage} stretch /> }
			<MessageList messages={messages} />
			{ !hasMessages && <EmptyList entityType="email" onEmptyClick={handleAddMessage} /> }
		</>
	)
}

export default Emails