export const pinkOrange = {
	palette: {
		primary: {
		  light: '#ffc446',
		  main: '#ff9300',
		  dark: '#c56400',
		  contrastText: '#000000'
		},
		secondary: {
		  light: '#ff9dd8',
		  main: '#ff6aa7',
		  dark: '#c83578',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		// https://meyerweb.com/eric/tools/color-blend/#D42222:000000:1:hex
		frame: {
			col1: '#ff9300',
			col2: '#ff6aa7',
			mid: '#FF7F54',
		},
		pinkOrange: {
		  col1: '#ff9300',
		  col2: '#ff6aa7',
		  mid: '#FF7F54',
		},
	  },
}