import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getCurrentItemFiles } from "../../store/reducers/item/itemSelectors";
import { selectCurrentItem, selectItemsById } from "../../store/reducers/item/itemSelectors";
import { addFileThunk } from "../../store/reducers/item/itemSlice";
import { addItemToItemListMessage } from "../../store/reducers/message/messageActions";
import { removeItemListField } from "../../store/reducers/message/messageSlice";
import { RootState } from "../../store/reducers/reducers";
import { UploadTask } from "../../store/types/fileTypes";
import { Item, ItemID } from "../../store/types/itemTypes";
import SimpleItemForm from "../forms/SimpleItemForm";
import UploadArea from "../upload/UploadArea";
import UploadButton from "../upload/UploadButton";


interface ItemListProps {
	items: ItemID[];
	fieldKey: string;
	canEdit: boolean;
}

const ItemList = ({items, fieldKey, canEdit = true}: ItemListProps) => {
	
	const dispatch = useAppDispatch();
	const populatedItems: Item[] = useAppSelector((state: RootState) => selectItemsById(state, items))
	const currentItemFiles = useAppSelector(getCurrentItemFiles)
	
	const onFileUploaded = async (uploadedFile: UploadTask) => {
		await dispatch( addFileThunk({ ...uploadedFile }) )
		await dispatch( addItemToItemListMessage({fieldKey}) )
	}

	const handleRemove = (id: ItemID) => {
		dispatch(removeItemListField({fieldKey, itemId: id}))
	}

	return (
		<>
			{ populatedItems.map((item, index) =>
				<SimpleItemForm canEdit={canEdit} key={`item-${index}`} item={item} onItemRemove={handleRemove}/>
			) }
			{ canEdit ? <UploadButton onFileUploaded={onFileUploaded} uploadedFile={currentItemFiles[0]} /> : null }
			{/* <SimpleItemForm key={`item-new`} item={currentItem} onItemCreated={onItemCreated} /> */}
		</>
	)
}

export default ItemList