import { logEvent } from "firebase/analytics";
import { analyticsRef } from "../Firebase";


export function logCustomEvent(params){
    const { eventName, ...props } = params
    return logEvent(analyticsRef, eventName, props)
}

export function logProgress(params){
    const { eventName = 'progress_form', ...props } = params
    return logEvent(analyticsRef, eventName, props)
}

export function logPageview(params){
    const { eventName, ...props } = params
    return logEvent(analyticsRef, 'page_view', props)
}

export function logLinkClick(params){
    const { linkName, linkPlacement, ...props } = params
    return logEvent(analyticsRef, 'internal_link_click', params)
}

export function logButtonClick(params){
    const { buttonName, buttonPlacement, ...props } = params
    return logEvent(analyticsRef, 'button_click', params)
}