import LiteStart from "../pages/lite/LiteStart";



const LiteRouter = [
	{
		path: '/lite',
		element: <LiteStart />,
	}
]

export default LiteRouter