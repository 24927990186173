import { PropsWithChildren, useState } from 'react'
import Box from '@mui/material/Box'
import TextButton from '../buttons/TextButton';

interface ExpanderProps extends PropsWithChildren {
	expandedOnLoad?: boolean;
}

const BOX_HEIGHT = '80px'

const Expander = ({ expandedOnLoad = false, children }: ExpanderProps) => {
	
	const [ expanded, setExpanded ] = useState(expandedOnLoad)

	const handleSetExpanded = () => {
		setExpanded(!expanded)
	}

	return (
		<Box sx={{
			position: 'relative',
			width: '100%',
			margin: '0',
		}}>
			{ !expanded && <Box sx={{
				height: BOX_HEIGHT,
				position: 'absolute',
				width: '100%',
				background: 'linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0.8) 75%, rgba(255,255,255,1) 100%)',
				top: 0,
				left: 0,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'flex-end',
				}} onClick={handleSetExpanded}>
				{/* <TextButton onClick={handleSetExpanded}>Show more</TextButton> */}
			</Box> }
			{ expanded ?
			<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}} onClick={handleSetExpanded}>
				{children}
				{/* <TextButton onClick={handleSetExpanded}>Close</TextButton> */}
			</Box> :
			<Box sx={{ height: BOX_HEIGHT, overflow: 'hidden' }}>{ children }</Box> }
		</Box>
	)
}

export default Expander