import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
	doc,
	setDoc,
} from "firebase/firestore";
import { AuthError, signInAnonymously, signOut } from 'firebase/auth';
import { db, auth } from '../../../Firebase';
import { createUserPassword, currentUser, signInUserPassword } from '../../../helpers/auth/Authentication';
import { saveAvatar } from '../../actions/logoActions';
import { clearValidationForParam, validateState } from '../../validation/validationTypes';
import { ApiStatus, DEFAULT_AVATAR } from '../../types/storeTypes';
import { AvailablePaymentOptions, PAYMENT_OPTIONS, PaymentOption } from '../../types/invoiceTypes';
import { VALIDATE_USER_RULES } from '../../validation/validationRules';
import { INITIAL_USER_STATE, QuickLink, QuickLinkID, User, generateUser, getUserToSave } from './userState';
import SlugHelper from '../../../helpers/SlugHelper';
import { getEntityByID } from '../../utils/firebaseUtils';
import type { RootState } from '../reducers';

export const createOrUpdateUser = createAsyncThunk(
	'user/create',
	async (args, thunkAPI) => {
		try {
			const { user } = thunkAPI.getState() as RootState
			let { id, ...userToSave } = getUserToSave(user)
			console.log(userToSave, id);
			
			await setDoc(doc(db, 'users', id), userToSave)
			const savedUser: User = {
				id: id,
				...userToSave
			}
			return savedUser
		} catch (error) {
			console.log('ERROR SAVING USER', error);
		}
	}
)

type SignInEmailPwdArgs = {
	email: string,
	password: string,
}

type SignInEmailPwdResponse = {
	signedIn: boolean;
	user: User | null;
	error: null | AuthError;
}

export const signInUserEmailPassword = createAsyncThunk(
	'user/signInEmailPassword',
	async (args: SignInEmailPwdArgs): Promise<SignInEmailPwdResponse> => {
		const { email, password } = args
		let returnedUser = null;
		const { signedIn, user, error } = await signInUserPassword(email, password)
		if (user) {
			returnedUser = generateUser({ id: user?.uid, email: email })
		}
		return {
			signedIn,
			user: returnedUser,
			error,
		}
	}
)

export const createUserEmailPassword = createAsyncThunk(
	'user/createEmailPassword',
	async (args: SignInEmailPwdArgs, thunkAPI): Promise<SignInEmailPwdResponse> => {
		const { email, password } = args
		let returnedUser = null
		const { signedIn, user, error } = await createUserPassword(email, password)
		if (user) {
			returnedUser = generateUser({id: user?.uid, email: email })
			console.log(returnedUser);
			
		}
		return {
			signedIn,
			user: returnedUser,
			error,
		}
	}
)

export const signInUserAnon = createAsyncThunk('user/signInAnon', async (args, thunkAPI) => {
	try {
		console.log('SING IN ANON');
		const currentUserId = currentUser()
		if (currentUserId) {
			console.info('Already signed in user:', currentUserId);
			return currentUserId
		}
		const { user } = thunkAPI.getState() as RootState
		const userToSave = getUserToSave(user);
		const { user: signedInUser } = await signInAnonymously(auth)
		console.info('Signed in anon with user:', signedInUser.uid);
		await setDoc(doc(db, 'users', signedInUser.uid), userToSave)
		return signedInUser.uid
	} catch (error) {
		console.log('ERROR SIGNING IN');
        console.log(error);
	}
})

export const userLoggedIn = createAsyncThunk('user/loggedIn', async (userId: string, thunkAPI) => {
	try {
		const user = await getEntityByID('users', userId)
		return user
	} catch (error) {
		console.log(error)
		return {}
	}
})

export const userLogout = createAsyncThunk('user/logout', async (_, thunkAPI) => {
	try {
		await signOut(auth)
		return
	} catch (error) {
		console.log(error);
		throw error
	}
})


export const userSlice = createSlice({
  name: 'user',
  initialState: { ...INITIAL_USER_STATE },
  reducers: {
	overrideUserState: (state, action) => {
		return Object.assign({}, state, action.payload)
	},
	setDefaultPaymentOptions: (state, action) => {
		state.defaultPaymentOptions = action.payload
	},
	setDefaultPaymentOption: (state, action) => {
		const paymentLabel = action.payload.id as AvailablePaymentOptions
		const paymentOpt: PaymentOption = {
		  enabled: action.payload.value ? true : false,
		  value: action.payload.value,
		  label: PAYMENT_OPTIONS[paymentLabel].label,
		} 
		state.defaultPaymentOptions[paymentLabel] = paymentOpt
	  },
	setDefaultTheme: (state, action) => {
		state.defaultTheme = action.payload
	},
	setUserSlug: (state,action) => {
		state.slug = SlugHelper(action.payload)
	},
    setUserCompanyName: (state, action) => {
      state.companyName = action.payload
    },
    setUserAddress: (state, action) => {
      state.address = action.payload
    },
    setUserPostalCode: (state, action) => {
      state.postalCode = action.payload
    },
    setUserCountry: (state, action) => {
      state.country = action.payload
    },
    setUserCity: (state, action) => {
      state.city = action.payload
    },
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
    setUserVATnumber: (state, action) => {
      state.VATnumber = action.payload
    },
    setUserORGnumber: (state, action) => {
      state.ORGnumber = action.payload
    },
	setUserProfession: (state, action: PayloadAction<string>) => {
		state.profession = action.payload
	},
	setUserBio: (state, action: PayloadAction<string>) => {
		state.bio = action.payload
	},
	addQuickLink: (state, action: PayloadAction<QuickLink>) => {
		state.links = state.links.concat(action.payload)
	},
	removeQuickLink: (state, action: PayloadAction<QuickLinkID>) => {
		state.links = state.links.filter((ql) => ql.id !== action.payload)
	},
    removeAvatar: (state) => {
      state.avatar = DEFAULT_AVATAR
    },
	addCustomerForUser: (state, action) => {
		let { customers } = state
		customers.indexOf(action.payload) === -1 ? customers.push(action.payload) : console.log('customer already added to user')
		state.customers = customers
	},
	removeCustomerForUser: (state, action) => {
		state.customers = state.customers.filter((item) => item !== action.payload.removedId )
	},
    setCustomersForUser: (state, action) => {
      let customers = state.customers
      state.customers = customers.concat([action.payload])
    },
    setInvoicesForUser: (state, action) => {
      let invoices = state.invoices
      state.invoices = invoices.concat(action.payload)
    },
	validateUser: (state) => {
		const { stateValid, validation } = validateState(state, VALIDATE_USER_RULES)
		state.stateValid = stateValid
		state.validation = validation
	},
	setPremadeAvatar: (state, action) => {
		const avatarURL = `${window.location.origin}${action.payload}`
		state.avatar = {
			thumbnailPath: '',
			fullPath: '',
			URL: avatarURL,
		}
	},
	clearValidation: clearValidationForParam,
  },
  extraReducers: builder => {
    builder
      .addCase(createOrUpdateUser.pending, (state, action) => {
        state.status = ApiStatus.PENDING
      })
      .addCase(createOrUpdateUser.fulfilled, (state, action) => {
        state.status = ApiStatus.SUCCESS
      })
	  .addCase(signInUserEmailPassword.pending, (state) => {
		state.status = ApiStatus.PENDING
	  })
	  .addCase(signInUserEmailPassword.fulfilled, (state, action) => {
		state.status = ApiStatus.SUCCESS
		if (action.payload.user){
			const user: User = action.payload.user
			state.id = user.id
			if (user.email) state.email = user.email
		}
	  })
	  .addCase(signInUserEmailPassword.rejected, (state, action) => {
		state.status = ApiStatus.ERROR
	  })
	  .addCase(createUserEmailPassword.pending, (state) => {
		state.status = ApiStatus.PENDING
	  })
	  .addCase(createUserEmailPassword.fulfilled, (state, action) => {
		state.status = ApiStatus.SUCCESS
		if (action.payload.user){
			const user: User = action.payload.user
			state.id = user.id
			state.slug = user.slug
			if (user.email) state.email = user.email
		}
	  })
	  .addCase(createUserEmailPassword.rejected, (state, action) => {
		state.status = ApiStatus.ERROR
	  })
	  .addCase(userLoggedIn.pending, (state, action) => {
		state.status = ApiStatus.PENDING
	  })
	  .addCase(userLoggedIn.fulfilled, (state, action) => {
		return Object.assign({}, state, { status: ApiStatus.SUCCESS }, action.payload)
	  })
	  .addCase(userLoggedIn.rejected, (state, action) => {
		state.status = ApiStatus.ERROR
	  })
	  .addCase(userLogout.fulfilled, (state, action) => {
		state = { ...INITIAL_USER_STATE }
	  })
      .addCase(saveAvatar.pending, (state, action) => {
        state.uploading = true
      })
      .addCase(saveAvatar.fulfilled, (state, action) => {
		// TODO: This can be improved
		const { thumbnailPath, fullPath, URL } = action.payload
        state.uploading = false
		state.avatar = {
			thumbnailPath: thumbnailPath ? thumbnailPath : state.avatar.thumbnailPath,
			fullPath: fullPath ? fullPath : state.avatar.fullPath,
			URL: URL ? URL : state.avatar.URL,
		}
      })
	  .addCase(saveAvatar.rejected, (state, action) => {
		state.uploading = false
	  })
	  .addCase(signInUserAnon.pending, (state, action) => {

	  })
	  .addCase(signInUserAnon.rejected, (state, action) => {
		
	  })
	  .addCase(signInUserAnon.fulfilled, (state, action) => {
		if (action.payload) state.id = action.payload
	  })
  }
})

export const {
	overrideUserState,
	setDefaultPaymentOptions,
	setDefaultPaymentOption,
	setDefaultTheme,
	setUserCompanyName,
	setUserAddress,
	setUserPostalCode,
	setUserCity,
	setUserCountry,
	setUserEmail,
	setUserVATnumber,
	setUserORGnumber,
	setUserProfession,
	setUserBio,
	setUserSlug,
	addQuickLink,
	removeQuickLink,
	removeAvatar,
	setCustomersForUser,
	removeCustomerForUser,
	setInvoicesForUser,
	validateUser,
	clearValidation,
	setPremadeAvatar,
} = userSlice.actions

export default userSlice.reducer
