import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { keyframes } from "@mui/system";
import { StepIcon, stepIconClasses, styled } from '@mui/material';
import { FORM_STEPS } from '../../forms/FormSteps';

const progressAnimationFrames = keyframes`
    0%, 50%, 80%, 100% { transform: scale(1); }
    20% { transform: scale(1.2); }
    65% { transform: scale(1.1); }    
`

const progressAnimation ={
  animation: `${progressAnimationFrames} 300ms linear`,
  animationDelay: '200ms',
}

const bgPosition = keyframes`
  0% { background-position: right },
  100% { background-position: left },
`
const StyledStepIcon = styled(StepIcon)(({active, theme}) => ({
	[`${stepIconClasses.completed}`]: {
		color: theme.palette.primary.dark,
	},
	text: { fill: active ? theme.palette.primary.contrastText : theme.palette.primary.dark },
}))

const StyledConnector = styled(StepConnector)(({theme}) => ({
      [`&.${stepConnectorClasses.active}`]: {
          background: `linear-gradient(to right, ${theme.palette.primary.main} 50%, white 50%) right`,
          height: '1px',
          backgroundSize: '200% 100%',
          animation: `${bgPosition} 200ms ease-in`,
          animationFillMode: 'forwards',
        [`& .${stepConnectorClasses.line}`]: {
          border: 'none',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: `${theme.palette.primary.main}`,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
    },
}))

const StyledStepLabel = styled(StepLabel)<{ active?: boolean; }>(({theme}) => ({
	[`&.${stepLabelClasses.active} > svg > text`]: {
		stroke: 'white'
	}
}))

interface ProgressBarProps {
	activeStep: number;
}

const ProgressBar = ({ activeStep }: ProgressBarProps) => {
  
  return (
    <Box sx={{ width: {
		xs: '100%',
		s: '100%',
		md: '600px',
	  }, paddingBottom: '20px',
	  paddingTop: '20px' }}>
      <Stepper activeStep={activeStep} connector={<StyledConnector />}>
        {FORM_STEPS.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step
			  	key={label}
				{...stepProps}
				sx={ (activeStep === index) ? progressAnimation : {} }>
                <StyledStepLabel StepIconComponent={StyledStepIcon} active={activeStep === index ? true : undefined} {...labelProps}>{ activeStep === index ? '' : ''}</StyledStepLabel>
              </Step>
            );
          })}
      </Stepper>
    </Box>
  );
}

export default ProgressBar;
