import Grid from '@mui/material/Grid'
import ChipAtom from '../../atoms/chip/ChipAtom'
import { FORM_PROPS } from '../../../helpers/FormProps'

type QuickLink = {
	label: string;
	link: string;
}

interface QuickLinksProps {
	alignment?: 'center' | 'right' | 'left';
	quickLinks: QuickLink[];
}

const QuickLinks = ({ alignment = 'center', quickLinks }: QuickLinksProps) => {

	const justifyContent = alignment === 'center' ? 'center' : alignment === 'left' ? 'flex-start' : 'flex-end'

	const handleQuickLinkClick = (ql: QuickLink) => {
		window.open(ql.link)
	}
	return (
		<Grid
			sx={{marginTop: '0px'}}
			container
			direction="row"
			justifyContent={justifyContent}
			rowGap={FORM_PROPS.ROW_SPACING}
			columnGap={FORM_PROPS.COLUMN_SPACING}
			>
				{ quickLinks.map((quickLink, index) => 
					<Grid item key={`quicklink-${index}`}>
						<ChipAtom label={quickLink.label} onClick={() => { handleQuickLinkClick(quickLink)}}/>
					</Grid>
				) }			
		</Grid>
	)
}

export default QuickLinks