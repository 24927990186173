export const baseColors = {
	palette: {
		primary: {
		  light: '#b7b7b7',
		  main: '#5B5B5B',
		  dark: '#1c1c1c',
		  contrastText: '#ffffff'
		},
		secondary: {
		  light: '#ff9dd8',
		  main: '#ff6aa7',
		  dark: '#c83578',
		  contrastText: '#000000',
		},
		tertiary: {
			light: '#ffc446',
			main: '#ff9300',
			dark: '#c56400',
			contrastText: '#000000'
		},
		pinkOrange: {
		  col1: '#ff9300',
		  col2: '#ff6aa7',
		  mid: '#FF7F54',
		},
		black: {
			main: '#000000'
		},
		misc: {
			logo: '#F1FD54',
			error: "#d32f2f",
		},
	  },
}