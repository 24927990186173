import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Theme, styled } from "@mui/material";
import { CSSProperties, FC } from "react";

type SharedProps = {
	displayType?: 'regular' | 'message';
	typography?: keyof Theme["typography"];
}

type StyledTextInputProps = TextFieldProps & SharedProps & { }

const StyledTextField = styled(TextField, {
	shouldForwardProp: prop => prop !== 'typography' && prop !== 'displayType'
})<StyledTextInputProps>(({theme, displayType, typography}) => {
	let textStyle = {}
	if (displayType === 'message' && typography) {
		const fieldTypography = theme.typography[typography] as CSSProperties
		textStyle = {
			'& input': {
				...fieldTypography,
				textAlign: 'left',
			},
			'& .MuiInput-underline': {
				borderColor: 'red'
			}

		}
	}
	return {
		...textStyle
	}
})


interface TextInputProps extends SharedProps {
	value: string;
	label?: string;
	onChange: (e: any) => void;
	disabled?: boolean;
	placeholder?: string;
	canEdit?: boolean;
}

const TextInput: FC<TextInputProps> = ({ value, label, onChange, canEdit = true, disabled = false, placeholder, displayType = 'regular', typography = 'h2' }) => {

	return (
		<StyledTextField
			fullWidth
			type="text"
			label={label}
			placeholder={placeholder}
			disabled={disabled}
			value={value}
			onChange={onChange}
			variant="standard"
			displayType={displayType}
			InputProps={{
				disableUnderline: displayType === 'message',
				readOnly: canEdit ? false : true,
			}}
			typography={typography}
			/>
	)
}

export default TextInput