import { Typography, Stack, CircularProgress } from '@mui/material'
import { FORM_PROPS } from '../../helpers/FormProps';
import FullModal from '../modals/FullModal';
import FilledButton from '../buttons/FilledButton';
import TextButton from '../buttons/TextButton';
import { Fragment, useEffect, useState } from 'react';
import { removeInvoice } from '../../store/reducers/invoice/invoiceActions';
import { useDispatch } from 'react-redux';
import { removeCustomer } from '../../store/reducers/customer/customerSlice';
import { removeItem } from '../../store/reducers/item/itemSlice';
import { removeMessage } from "../../store/reducers/message/messageActions";

const REMOVE_STATUS = {
	INITIAL: 'INITIAL',
	LOADING: 'LOADING',
	SUCCESS: 'SUCCESS',
}

const ITEM_TYPES = {
	'invoice': {
		'successHeader': 'Invoice removed!',
		'entityIdentifier': 'invoiceNumber',
		'confirmMessage': (invoiceNumber) => `This will remove invoice #${invoiceNumber}`,
		'removeAction': removeInvoice,
	},
	'customer': {
		'successHeader': 'Customer removed!',
		'entityIdentifier': 'companyName',
		'confirmMessage': (companyName) => `This will remove customer: ${companyName}`,
		'removeAction': removeCustomer,
	},
	'item': {
		'successHeader': 'Item removed!',
		'entityIdentifier': 'description',
		'confirmMessage': (description) => `This will remove item: ${description}`,
		'removeAction': removeItem,
	},
	'message': {
		'successHeader': 'Message removed!',
		'entityIdentifier': 'messageTitle',
		'confirmMessage': (messageTitle) => `This will remove message: ${messageTitle}`,
		'removeAction': removeMessage,
	}
}

const ConfirmRemove = ({ isOpen, item = {}, itemType = 'invoice', handleClose = () => {}}) => {

	const [ removeStatus, setRemoveStatus ] = useState(REMOVE_STATUS.INITIAL)

	const dispatch = useDispatch()

	const itemActions = ITEM_TYPES[itemType];

	useEffect(() => {
		if (removeStatus === REMOVE_STATUS.SUCCESS) {
			const timeout = setTimeout(() => closeModal(), 750)
			return () => {
				clearTimeout(timeout)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [removeStatus])

	const closeModal = () => {
		setRemoveStatus(REMOVE_STATUS.INITIAL)
		handleClose()
	}

	const handleConfirm = async () => {
		setRemoveStatus(REMOVE_STATUS.LOADING)
		try {
			const actionToDispatch = itemActions.removeAction
			const removed = await dispatch( actionToDispatch(item.id) )
			console.log('removed response', removed);
			setRemoveStatus(REMOVE_STATUS.SUCCESS)
		} catch (error) {
			console.log(error);
		}
	}

	return(
		<FullModal isOpen={isOpen} onClose={closeModal}>
			<Stack spacing={FORM_PROPS.ROW_SPACING}>
				{
					{
						'SUCCESS' :
						<Fragment>
							<Typography variant='h2'>{itemActions.successHeader}</Typography>
						</Fragment>
					}[removeStatus] ||
					<Fragment>
						<Typography variant='h2'>{itemActions.confirmMessage( item[itemActions.entityIdentifier] )}</Typography>
						<FilledButton
							btnStyle="rounded"
							btnColor='black'
							stretch={false}
							onClick={handleConfirm}
							disabled={ removeStatus === REMOVE_STATUS.LOADING }
							>
							{ removeStatus === REMOVE_STATUS.LOADING ? <CircularProgress size={'2rem'} /> : 'Remove' }
						</FilledButton>
						<TextButton disabled={ removeStatus === REMOVE_STATUS.LOADING } size="medium" onClick={closeModal}>
							Back
						</TextButton>
					</Fragment>
				}
			</Stack>
		</FullModal>
    )
}

export default ConfirmRemove