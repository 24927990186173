import { styled } from "@mui/material";


type ImageCompProps = {
	src: string;
}

const StyledImg = styled('img')<ImageCompProps>(({theme}) => ({
	maxWidth: '100%',
}))

interface ImageProps {
	src: string;
}

const Image = ({ src }: ImageProps) => {

	return (
		<StyledImg
			src={src}
		/>
	)
}

export default Image
// https://blog.logrocket.com/progressive-image-loading-react-tutorial/