import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const GA4Config = {
    config: {
        send_page_view: false,
		debug_mode: process.env.NODE_ENV === 'development' ? true : false,
    },
}

// https://blog.gmagnenat.co/user-authentication-and-persistence-firebase-9-react-redux-toolkit

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore(app);
const storage = getStorage();
const avatarRef = ref(storage, 'avatars');
const thumbRef = ref(storage, 'thumbnails');
const storageRef = ref(storage);
const analyticsRef = initializeAnalytics(app, GA4Config);

export {
    auth,
    db,
    avatarRef,
    thumbRef,
    storageRef,
	storage,
    analyticsRef,
}