import { Box, Grid, Typography, styled } from "@mui/material"
import { FORM_PROPS } from "../../../helpers/FormProps"
import ItemCard from "../../molecules/itemcard/ItemCard"
import { useNavigate, useLocation } from "react-router"
import { ItemID, Item } from "../../../store/types/itemTypes"

interface ItemListProps {
	items: Item[];
	title?: string;
}

const StyledBox = styled(Box)(({theme}) => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	alignItems: 'flex-start',
	rowGap: '1em',
	paddingTop: '2em',
}))

const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
const ITEM_REGEX = /items/

const ItemList = ({items, title = 'Portfolio'}: ItemListProps) => {

	const navigate = useNavigate()
	const { pathname } = useLocation();

	const goToItem = (itemId: ItemID) => {
		const paths: string[] = pathname.split('/')
		const itemsIndex: number = paths.findIndex(path => path === 'items')
		if (itemsIndex === -1) {
			const newPath = paths.concat(['items', itemId]).join('/')
			navigate(newPath)
		} else if(itemsIndex === paths.length - 1) {
			const newPath = paths.concat([itemId]).join('/')
			navigate(newPath)
		} else {
			const newPath = paths.slice(0, -1).concat(itemId).join('/')
			navigate(newPath)
		}
	}

	return (
		<StyledBox>
			{ items.length > 0 ? <Typography variant="h4" textAlign="left">{title} ({items.length})</Typography> : <></> }
			<Grid
				container
				direction="row"
				justifyContent="flex-start"
				rowSpacing={FORM_PROPS.ROW_SPACING}
				columnSpacing={FORM_PROPS.COLUMN_SPACING}>
				{ items.map((item, index) => 
					<Grid key={`collection-item-${index}`} item xs={6} sm={6} md={6} xl={6} zeroMinWidth>
						<ItemCard item={item} onClick={goToItem} constrainHeight={false} />
					</Grid>	
				)}
			</Grid>
		</StyledBox>
	)
}

export default ItemList