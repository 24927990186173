import { Outlet } from "react-router-dom"
import CenterWrap from "../../components/layouts/centerwrap/CenterWrap"
import { Box } from "@mui/material"
import Column from "../../components/layouts/column/Column"

interface PageContentProps {}

const PageContent = ({}: PageContentProps) => {
	return (
		<CenterWrap>
			<Box sx={{paddingLeft: '0.5em', paddingRight: '0.5em', width: '100%', boxSizing: 'border-box'}}>
				<Column minHeight={'82vh'}>
					<Outlet />
				</Column>
			</Box>
		</CenterWrap>		
	)
}

export default PageContent