import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";

interface TextInputProps {
	autoComplete?: 'off';
	value: string;
	helperText?: string;
	placeholder?: string;
	disabled?: boolean;
	valid?: boolean;
	label?: string;
	canEdit?: boolean;
	multiRow?: boolean;
	minRows?: number;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledTextField = styled(TextField)(({theme}) => ({
	...theme.typography.textInput
}))


const TextInput = ({
	autoComplete = 'off',
    value,
    label = '',
    helperText = '',
    valid = true,
    disabled = false,
	placeholder = '',
	canEdit = true,
	multiRow = false,
	minRows = 3,
	onChange,
}: TextInputProps) => {

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		// if (e.key === 'Enter') handleEnterPress()
	}

    return (
        <StyledTextField
            fullWidth
			multiline={multiRow}
			minRows={multiRow ? minRows : 1}
            autoComplete={autoComplete}
            error={!valid}
			onKeyPress={handleKeyPress}
			onChange={onChange}
            disabled={disabled}
            value={value}
            helperText={helperText}
			placeholder={placeholder}
			InputProps={{
				readOnly: canEdit ? false : true,
			}}
            type="text"
            variant="standard"
            label={label}
        />
    )
}

export default TextInput