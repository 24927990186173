import GetStartedForm from "../../components/forms/GetStartedForm"



const CreateAccount = () => {

	return (
		<>
			<GetStartedForm />
		</>
	)
}


export default CreateAccount