import { Stack } from "@mui/material"
import UploadArea from "../upload/UploadArea"
import TextBox from "../inputs/fields/TextBox"
import { FORM_PROPS } from "../../helpers/FormProps"
import { Item, ItemID } from "../../store/types/itemTypes"
import { addFileThunk, setIndexItemDescription } from "../../store/reducers/item/itemSlice"
import { UploadTask } from "../../store/types/fileTypes"
import { useAppDispatch } from "../../store/hooks"
import { getIndexItemDescription } from "../../store/reducers/item/itemSelectors"
import usePlainReduxUpdateIndex from "../inputs/hooks/plainReduxUpdateIndex"
import RemoveButton from "../buttons/RemoveButton"

interface SimpleItemFormProps {
	item: Item;
	onItemCreated?: (id: ItemID) => void;
	onItemRemove?: (id: ItemID) => void;
	canEdit: boolean;
}

const SimpleItemForm = ({ item, onItemRemove, canEdit }: SimpleItemFormProps) => {

	const dispatch = useAppDispatch()
	
	const onFileUploaded = (uploadedFile: UploadTask) => {
		dispatch( addFileThunk({ ...uploadedFile }) )
	}

	const handleRemove = () => {
		if(onItemRemove) onItemRemove(item.id)
	}

	const itemDescription = usePlainReduxUpdateIndex({
		type: 'text',
		entityId: item.id,
		entityType: 'item',
		action: setIndexItemDescription,
		selector: getIndexItemDescription,
	})

	const { files } = item
	const uploadedFile = files.length > 0 ? files[0] : undefined

	return (
		<Stack spacing={FORM_PROPS.ROW_SPACING} sx={{position: 'relative'}}>
			<UploadArea onFileUploaded={onFileUploaded} uploadedFile={uploadedFile} canUpload={canEdit}>
				{ canEdit ? <RemoveButton placement="inside" handleRemove={handleRemove}/> : null }
			</UploadArea>
			{ uploadedFile && <TextBox canEdit={canEdit} placeholder="Item description" {...itemDescription} displayType="message" typography="p" /> }
		</Stack>
	)
}

export default SimpleItemForm