import ItemForm from "../../components/forms/ItemForm"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { selectCurrentItem, selectItemLoading } from "../../store/reducers/item/itemSelectors"
import { createOrUpdateItem } from "../../store/reducers/item/itemSlice"
import FloatingSaveButton from "../../components/organisms/floatingsavebuttons/FloatingSaveButtons"
import { useNavigate } from "react-router-dom"

const AddItem = () => {

	const dispatch = useAppDispatch();
	const navigate = useNavigate()

	const item = useAppSelector(selectCurrentItem)
	const loading = useAppSelector(selectItemLoading)

	const handleSaveItem = async () => {
		await dispatch( createOrUpdateItem() )
		navigate(-1)
	}

	return (
		<>
			<ItemForm item={item} />
			<FloatingSaveButton onSave={handleSaveItem} />
		</>
	)
}

export default AddItem