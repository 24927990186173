import { Box, Typography, styled, useTheme } from "@mui/material"
import UploadComponent from "./UploadComponent"
import { UploadTask, UploadedFile, isUploadTaskFilter, isUploadedFileFilter } from "../../store/types/fileTypes";
import Image from "../atoms/image/Image";
import AddIcon from "../atoms/icons/AddIcon";

type UploadedFileType = UploadTask | UploadedFile | undefined;

const StyledBox = styled(Box)(({}) => ({
	display: 'flex',
	flex: 1,
	alignItems: 'center',
	justifyContent: 'flex-start',
}))

interface UploadButtonProps {
	onFileUploaded: (uploadTask: UploadTask) => void;
	uploadedFile: UploadedFileType;
}

const uploadedFileSrc = (toCheck: UploadedFileType) => {
	if (!toCheck) return false
	if (isUploadTaskFilter(toCheck)) return toCheck.blobURL
	if (isUploadedFileFilter(toCheck)) return toCheck.fileURL
	return false
}

const UploadButton = ({ onFileUploaded, uploadedFile }: UploadButtonProps) => {
	const theme = useTheme();

	const imgSrc = uploadedFileSrc(uploadedFile)
	
	return (
		<UploadComponent onUploaded={onFileUploaded}>
			{ imgSrc ? <Image src={imgSrc}/> :
			<StyledBox>
				<AddIcon />
				<Typography variant="h3Thin" color={theme.palette.primary.light}>Add files</Typography>
			</StyledBox> }
		</UploadComponent>
	)
}

export default UploadButton