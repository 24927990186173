import { arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../../Firebase";
import { Item } from "../types/itemTypes";
import { Message } from "../types/messageTypes";
import { Invoice } from "../types/invoiceTypes";
import { Email } from "../types/emailTypes";
import { Contact } from "../reducers/contact/contactState";
import { Highlight } from "../reducers/highlight/highlightState";

type Entity = Item | Message | Email | Invoice | Contact | Highlight

type SaveOrCreateEntityResult =
  { saved: true; entity: Entity }
  | { saved: false; error: string | undefined };

export const saveOrCreateEntity = async (entityType: string, entityData: any, userId: string): Promise<SaveOrCreateEntityResult> => {
	try {
		if (!entityData || !entityType || !userId) throw new Error('Input param missing')
		
		const entityToSave = {
			user: userId,
			...entityData,
		}
		const docRef = doc(db, entityType, entityToSave.id)
		console.log(docRef);
		
		await setDoc(docRef, entityToSave)
		return {
			saved: true,
			entity: entityToSave
		}
	} catch (error: any) {
		console.log(error);
		
		return {
			saved: false,
			error: error?.message
		}
	}
}

type UpdateEntityResult = 
{ saved: true; } |
{ saved: false; error: string | undefined };

export const updateEntity = async (entityType: string, updateData: any, userId: string): Promise<UpdateEntityResult> => {
	try {
		if (!updateData || !entityType || !userId) throw new Error('Input param missing')

		const docRef = doc(db, entityType, userId)
		await setDoc(docRef, updateData, { merge: true })
		return {
			saved: true,
		}

	} catch (error: any) {
		console.log(error);
		return {
			saved: false,
			error: error?.message
		}
	}
}

export const saveEntityForUser = async (entityType: string, entityId: string, userId: string) => {
	try {
		if (!userId || !entityId || !entityType) throw new Error('Input param missing')
		await setDoc(doc(db, 'entitiesForUser', userId), { [entityType]: arrayUnion(entityId)}, { merge: true })
		return {
			saved: true,
		}
	} catch (error: any) {
		return {
			saved: false,
			error: error?.message
		}
	}
}



export const getEntityByID = async (entityType: string, entityId: string): Promise<Entity> => {
	try {
		if (!entityType || !entityId) throw new Error(`No input params`)
		const docRef = doc(db, entityType, entityId)
		const docSnap = await getDoc(docRef)
		if (!docSnap.exists()) throw new Error(`No ${entityType} found`)
		const document = {
			id: docSnap.id,
			...docSnap.data()
		}
		if (entityType === 'items') return document as Item
		if (entityType === 'emails') return document as Email
		if (entityType === 'invoices') return document as Invoice
		if (entityType === 'highlights') return document as Highlight
		return document as Message
	} catch (error) {
		throw error
	}
}

export const removeEntityById = async (entityType: string, entityId: string) => {
	try {
		if (!entityType || !entityId) throw new Error(`No input params`)
		const docRef = doc(db, entityType, entityId)
		await deleteDoc(docRef)
		return {
			removed: true,
			removedId: entityId,
		}
	} catch (error: any) {
		return {
			removed: false,
			error: error?.message
		}
	}
}


interface EntitiesResult<T> {
    result: T[];
    error?: string;
}

type EntityResult = EntitiesResult<Entity>;

export const getEntitiesForUser = async (entityType: string, userId: string): Promise<EntityResult> => {
	try {
		if (!userId || !entityType) throw new Error('Input param missing')
		const queryRef = collection(db, entityType)
		const q = query(queryRef, where('user', '==', userId))
		const snapshot = await getDocs(q)
        const result: Entity[] = []
        snapshot.forEach(doc => {
            if (entityType === 'items') {
                result.push({ id: doc.id, ...doc.data() } as Item);
            } else if (entityType === 'messages') {
                result.push({ id: doc.id, ...doc.data() } as Message);
            } else if (entityType === 'emails') {
                result.push({ id: doc.id, ...doc.data() } as Email);
            } else if (entityType === 'invoices') {
                result.push({ id: doc.id, ...doc.data() } as Invoice);
            } else if (entityType === 'contacts') {
				result.push({ id: doc.id, ...doc.data() } as Contact)
			}
        });
		if (entityType === 'items') {
			const res = result as Item[]
			return { result: res }
		} else if (entityType === 'messages') {
			const res = result as Message[]
			return { result: res }
		} else if (entityType === 'emails') {
			const res = result as Email[]
			return { result: res }
		} else if (entityType === 'invoices') {
			const res = result as Invoice[]
			return { result: res }
		} else if (entityType === 'contacts') {
			const res = result as Contact[]
			return { result: res }
		}
		return {
			result,
		}
	} catch (error: any) {
		return {
			result: [],
			error: error?.message,
		}
	}
}