import { createOrUpdateCustomer } from './reducers/customer/customerSlice';
import { createOrUpdateUser, setCustomersForUser, setInvoicesForUser } from './reducers/user/userSlice';
import { setCustomerForInvoice, setUserForInvoice } from './reducers/invoice/invoiceSlice';
import { createOrUpdateInvoice } from './reducers/invoice/invoiceActions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadBlob } from './utils/uploadUtil';
import { showValidationOverlay } from './reducers/misc/miscSlice';
import { generatePdfBlob, generatePdfFile } from '../helpers/pdf/PdfWorker';

export const generateInvoice = createAsyncThunk('invoice/generate', async (args, thunkAPI) => {
	const { validate = true, ref = false } = args;
	
	const {
		user: { stateValid: userStateValid, id: userId },
		customer: { stateValid: customerStateValid },
		invoice: { stateValid: invoiceStateValid, id: invoiceId}
	} = thunkAPI.getState()

	try {
		if (validate) {
			if (!userStateValid || !customerStateValid || !invoiceStateValid) {
				thunkAPI.dispatch( showValidationOverlay() )
				return thunkAPI.rejectWithValue({ validToSend: false })
			}
		}
		if (!ref) return thunkAPI.rejectWithValue({ validToSend: false})
		if (process.env.NODE_ENV === 'development') {
			console.log('generating pdf for development');
			await generatePdfFile(ref)
		}
		const pdfBlob = await generatePdfBlob(ref)
		const uploadedFileURL = await uploadBlob(`${userId}/${invoiceId}.pdf`, 'invoices', pdfBlob)
		console.log(uploadedFileURL);
		console.log('UPDATE INVOICE WITH BLOB!');
		// await thunkAPI.dispatch( updateInvoiceData({ id: invoiceId, data: { invoiceURL: uploadedFileURL }}))
		return { validToSend: true }
	} catch (error) {
		console.log(error);
		return { validToSend: false }
	}
})

export const saveInvoice = createAsyncThunk('invoice/save', async (args, thunkAPI) => {

    try {
        let [savedUser, savedCustomer, savedInvoice] = await Promise.all([
            thunkAPI.dispatch( createOrUpdateUser() ),
            thunkAPI.dispatch( createOrUpdateCustomer() ),
            thunkAPI.dispatch( createOrUpdateInvoice() ),
        ])
 
        thunkAPI.dispatch( setCustomersForUser( savedCustomer.payload.id ))
        thunkAPI.dispatch( setInvoicesForUser( savedInvoice.payload.id ) )

        thunkAPI.dispatch( setCustomerForInvoice( savedCustomer.payload.id ) )
        thunkAPI.dispatch( setUserForInvoice( savedUser.payload.id ) )

        await Promise.all([
            thunkAPI.dispatch( createOrUpdateUser() ),
            thunkAPI.dispatch( createOrUpdateInvoice() ),
        ])
        return savedInvoice.payload.id
    } catch (error) {
        console.log(error);
    } finally {
        console.log('all saved!');
    }
})