import { Paper, Typography, styled, useTheme } from "@mui/material"
import UploadComponent from "./UploadComponent"
import { PropsWithChildren } from "react"
import { UploadTask, UploadedFile, isUploadTaskFilter, isUploadedFileFilter } from "../../store/types/fileTypes";
import Image from "../atoms/image/Image";

type UploadedFileType = UploadTask | UploadedFile | undefined;

const StyledPaper = styled(Paper)(({theme}) => ({
	width: '100%',
	maxWidth: '400px',
	aspectRatio: 1.5,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}))

interface UploadAreaProps extends PropsWithChildren {
	onFileUploaded: (uploadTask: UploadTask) => void;
	uploadedFile: UploadedFileType;
	canUpload?: boolean;
}

const uploadedFileSrc = (toCheck: UploadedFileType) => {
	if (!toCheck) return false
	if (isUploadTaskFilter(toCheck)) return toCheck.blobURL
	if (isUploadedFileFilter(toCheck)) return toCheck.fileURL
	return false
}

const UploadArea = ({ onFileUploaded, uploadedFile, children, canUpload = true }: UploadAreaProps) => {
	const theme = useTheme();
	const imgSrc = uploadedFileSrc(uploadedFile)
	
	return (
		<UploadComponent onUploaded={onFileUploaded} canUpload={canUpload}>
			{ imgSrc ? <Image src={imgSrc}/> :
			<StyledPaper elevation={0} square>
				{ children ? children : null}
				{ !uploadedFile && <Typography variant="textInput">Click to add files</Typography> }
			</StyledPaper> }
		</UploadComponent>
	)
}

export default UploadArea