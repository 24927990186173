import html2pdf from "html3pdf";

const PDF_CONFIG = {
	margin:       [10, 10, 10, 10],
	filename:     'invoice.pdf',
	html2canvas: {
		scale: 5,
		allowTaint: false,
		useCORS: true,
	},
	jsPDF: {
		orientation: 'portrait',
		format: 'a4',
		unit: 'mm',
	}
  };

export const generatePdfBlob = async (elementRef) => {

	const pdfFile = await html2pdf()
		.set(PDF_CONFIG)
		.from(elementRef.current)
		.toPdf()
		.get('pdf')
		.then((doc) => {
			const numberOfPages = doc.internal.getNumberOfPages()
			console.log(`Number of pages in blob = ${numberOfPages}.`);
			if (numberOfPages > 1) {
				console.log(`Number of pages = ${numberOfPages}, removing last page.`);
				doc.deletePage(numberOfPages)
			}
			return doc
		})
		.output('blob')

	return pdfFile
}

export const generatePdfFile = async (elementRef) => {

	console.info('Generating PDF file with', PDF_CONFIG);

	const pdfFile = await html2pdf()
		.set(PDF_CONFIG)
		.from(elementRef.current)
		.toPdf()
		.get('pdf')
		.then((doc) => {
			const numberOfPages = doc.internal.getNumberOfPages()
			console.log(`Number of pages = ${numberOfPages}.`);
			if (numberOfPages > 1) {
				console.log(`Number of pages in file = ${numberOfPages}, removing last page.`);
				doc.deletePage(numberOfPages)
			}
			return doc
		})
		.save()

	return pdfFile
}

export const generatePdfFileFromDOM = async (DOMelement) => {

	console.info('Generating PDF file with', PDF_CONFIG);

	const pdfFile = await html2pdf()
		.set(PDF_CONFIG)
		.from(DOMelement.container)
		.toPdf()
		.get('pdf')
		.then((doc) => {
			const numberOfPages = doc.internal.getNumberOfPages()
			console.log(`Number of pages = ${numberOfPages}.`);
			if (numberOfPages > 1) {
				console.log(`Number of pages in file = ${numberOfPages}, removing last page.`);
				doc.deletePage(numberOfPages)
			}
			return doc
		})
		.save()

	return pdfFile
}