import { AppBar, Box, Toolbar, styled } from "@mui/material"
import Navmid from "../../molecules/navmid/Navmid"
import BurgerMenu from "../../molecules/burgermenu/BurgerMenu"
import MenuBack from "../../molecules/menuback/MenuBack"
import ProfileButton from "../../molecules/profilebutton/ProfileButton"
import { useMatches } from "react-router-dom"

const StyledToolbar = styled(Toolbar)(({theme}) => ({
	flexDirection: 'row',
	justifyContent: 'space-between',
	a: { textDecoration: 'none' },
}))

const StyledBox = styled(Box)(({theme}) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	
}))

type MatchType = {
	handle: {
		showBack?: boolean;
		hideNav?: boolean;
	}
}

const Navbar = () => {

	const matches = useMatches()
	const { handle } = matches[matches.length - 1] as MatchType
	const hideNav = handle && handle?.hideNav || false

	return (
		<AppBar color="inherit" elevation={0} position="sticky">
			<StyledToolbar>
				<MenuBack />
				<Navmid />
				{ !hideNav &&
					<StyledBox>
						<ProfileButton />
						<BurgerMenu />
					</StyledBox>
				}
			</StyledToolbar>
		</AppBar>
	)
}

export default Navbar