import QuickLinks from "../../components/molecules/quicklinks/QuickLinks";
import ItemList from "../../components/organisms/itemlist/ItemList";
import ProfileHeader from "../../components/molecules/profileheader/ProfileHeader";
import Biography from "../../components/molecules/biography/Biography";
import Highlights from "../../components/molecules/highlights/Highlights";
import { selectItemsWithFile } from "../../store/reducers/item/itemSelectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import InputSubmit from "../../components/molecules/inputsubmit/InputSubmit";
import { selectPublicUserCompanyName, selectPublicUserQuickLinks, selectPublicUserBio, selectPublicUserProfession, selectPublicUserId } from "../../store/reducers/publicUser/publicUserSelectors";
import { selectContactsLoading } from "../../store/reducers/contact/contactSelectors";
import { defaultContact } from "../../store/reducers/contact/contactState";
import { addContactForUser } from "../../store/reducers/contact/contactSlice";
import { selectHighlightedItemsWithFile } from "../../store/reducers/highlight/highlightSelectors";
import CoverModal from "../../components/modals/CoverModal";
import { Box, styled } from "@mui/material";
import { useContext, useState } from "react";
import PartialModal from "../../components/modals/PartialModal";
import { ModalContext } from "../../helpers/ModalProvider";

const StyledBox = styled(Box)(({}) => ({
	minHeight: '30vh',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
}))

const Profile = () => {

	const { dismissFollow, shouldShowFollow } = useContext(ModalContext)
	
	const dispatch = useAppDispatch()

	const userId = useAppSelector(selectPublicUserId)
	const userCompanyName = useAppSelector(selectPublicUserCompanyName)
	const userProfession = useAppSelector(selectPublicUserProfession)
	const userQuickLinks = useAppSelector(selectPublicUserQuickLinks)
	const userBio = useAppSelector(selectPublicUserBio)
	const userHighlights = useAppSelector(selectHighlightedItemsWithFile)
	const items = useAppSelector(selectItemsWithFile)

	const contactsLoading = useAppSelector(selectContactsLoading)

	const followOpen = shouldShowFollow(userId)

	const closeFollowModal = () => {
		dismissFollow(userId)
	}

	const handleSubmitContact = (contactEmail: string) => {
		const newContact = defaultContact({email: contactEmail})
		const args = {
			userId: userId,
			contact: newContact,
		}
		dispatch( addContactForUser(args) )
		closeFollowModal()
	}

	return (
		<>
			<ProfileHeader profileName={userCompanyName} title={userProfession} />
			<QuickLinks alignment="left" quickLinks={userQuickLinks} />
			<Biography bioText={userBio} />
			<Highlights items={userHighlights} />
			<ItemList items={items} title="Portfolio" />
			<PartialModal
				showBack={false}
				transparent={true}
				isOpen={followOpen}
				onClose={closeFollowModal}>
				<StyledBox>
					<InputSubmit submitStatus={contactsLoading} onSubmit={handleSubmitContact} dismiss={closeFollowModal} />
				</StyledBox>
			</PartialModal>
		</>
	)
}

export default Profile