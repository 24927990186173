import { useParams } from "react-router"
import CenterWrap from "../../components/layouts/centerwrap/CenterWrap";
import Column from "../../components/layouts/column/Column";
import { Box, Grid, Stack, Typography } from "@mui/material";
import QuickLinks from "../../components/molecules/quicklinks/QuickLinks";
import { FORM_PROPS } from "../../helpers/FormProps";
import ImageCard from "../../components/image/ImageCard";
import FilledButton from "../../components/buttons/FilledButton";
import { ItemID } from "../../store/types/itemTypes";
import { useAppSelector } from "../../store/hooks";
import { selectItemById, selectItemsExcludeItem } from "../../store/reducers/item/itemSelectors";
import ItemList from "../../components/organisms/itemlist/ItemList";
import ScrollToTop from "../../helpers/ScrollToTop";
import ProfileHeader from "../../components/molecules/profileheader/ProfileHeader";
import { selectCompanyName, selectQuickLinks, selectUserProfession } from "../../store/reducers/user/userSelectors";

interface SingleItemProps {
	itemId: ItemID;
}

const SingleItem = ({ itemId }: SingleItemProps) => {


	const currentItem = useAppSelector((state) => selectItemById(state, itemId))
	
	const file = currentItem?.files[0]

	return (
		<Grid
			container
			direction="row"
			justifyContent={'center'}
			rowSpacing={FORM_PROPS.COLUMN_SPACING}
			>
			<Grid item xs={12} sm={12}>
				<ImageCard bgImage={file?.fileURL} size="responsive" />
			</Grid>
			<Grid container item xs={12} sm={12} direction={'column'}>
				<Stack direction={'column'} rowGap={2}>
					<Typography whiteSpace={'pre-line'} sx={{overflowWrap: 'break-word', wordBreak: 'break-all'}} variant="body2">{currentItem?.description}</Typography>
				</Stack>
			</Grid>
		</Grid>
	)
}

const ProfileItem = () => {
	const params = useParams()
	const itemId = params.itemId as ItemID
	const otherItems = useAppSelector((state) => selectItemsExcludeItem(state, itemId))
	const userCompanyName = useAppSelector(selectCompanyName)
	const userProfession = useAppSelector(selectUserProfession)
	const userQuickLinks = useAppSelector(selectQuickLinks)

	return (
		<>
			<ScrollToTop />
			<ProfileHeader profileName={userCompanyName} title={userProfession} />
			<QuickLinks alignment="left" quickLinks={userQuickLinks} />
			<SingleItem itemId={itemId} />
			<ItemList items={otherItems} />
		</>
	)
}

export default ProfileItem