import SvgIconWrapper, { SvgIconWrapperProps } from "./SvgIconWrapper";

interface ChevronRightIconProps extends SvgIconWrapperProps {}

const ChevronRightIcon = ({ iconSize = 'large', iconColor }: ChevronRightIconProps) => {
	return (
		<SvgIconWrapper iconSize={iconSize} iconColor={iconColor}>
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m531.69-480-184-184L376-692.31 588.31-480 376-267.69 347.69-296l184-184Z"/></svg>
		</SvgIconWrapper>
	)
}

export default ChevronRightIcon