declare module '@mui/material/styles' {
	interface TypographyVariants {
		tableContent: React.CSSProperties;
		buttonLg: React.CSSProperties;
		buttonSm: React.CSSProperties;
		logo: React.CSSProperties;
		tightText: React.CSSProperties;
		textLink: React.CSSProperties;
		textInput: React.CSSProperties;
		textInputLabel: React.CSSProperties;
		miniText: React.CSSProperties;
		h3Thin: React.CSSProperties;
		labelSm: React.CSSProperties;
		labelMd: React.CSSProperties;
		labelLg: React.CSSProperties;
		labelSmThin: React.CSSProperties;
		labelMdThin: React.CSSProperties;
		labelLgThin: React.CSSProperties;
		p: React.CSSProperties;
	}
  
	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		tableContent?: React.CSSProperties;
		buttonLg?: React.CSSProperties;
		buttonSm?: React.CSSProperties;
		logo?: React.CSSProperties;
		tightText?: React.CSSProperties;
		textLink?: React.CSSProperties;
		textInput?: React.CSSProperties;
		textInputLabel?: React.CSSProperties;
		miniText?: React.CSSProperties;
		h3Thin?: React.CSSProperties;
		labelSm?: React.CSSProperties;
		labelMd?: React.CSSProperties;
		labelLg?: React.CSSProperties;
		labelSmThin?: React.CSSProperties;
		labelMdThin?: React.CSSProperties;
		labelLgThin?: React.CSSProperties;
		p?: React.CSSProperties;
	}
}
  
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		tableContent: true;
		buttonLg: true;
		buttonSm: true;
		logo: true;
		tightText: true;
		textLink: true;
		textInput: true;
		textInputLabel: true;
		miniText: true;
		h3Thin: true;
		labelSm: true;
		labelMd: true;
		labelLg: true;
		labelSmThin: true;
		labelMdThin: true;
		labelLgThin: true;
		p: true;
	}
}

export const typographyTheme = {
	typography: {
		fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		h1: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '2rem',
		  textAlign: 'center',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h2: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.8rem',
		  textAlign: 'center',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h3: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.8rem',
		  textAlign: 'center',
		  letterSpacing: '0.15rem',
		  lineHeight: '1.35',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h3Thin: {
			color: 'black',
			fontWeight: '400',
			fontSize: '1.8rem',
			textAlign: 'center',
			letterSpacing: '0.15rem',
			lineHeight: '1.35',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  },
		h4: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.5rem',
		  letterSpacing: '0.1rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h5: {
		  color: 'black',
		  fontWeight: '600',
		  fontSize: '1.4rem',
		  minHeight: '1.4rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		h6: {
		  color: 'black',
		  fontWeight: '200',
		  fontSize: '1rem',
		  lineHeight: '1.6rem',
		  minHeight: '1.6rem',
		  letterSpacing: '0.05rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		logo: {
			color: 'black',
			fontSize: '1.5rem',
			textAlign: 'center',
			fontFamily: 'DrunkWideBold',
		},
		p: {
		  color: 'black',
		  fontSize: '1.5rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		body2: {
			color: 'black',
			fontWeight: '200',
			fontSize: '0.875rem',
			minHeight: '0.875rem',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		textLink: {
			color: 'black',
			textDecoration: 'underline',
			fontSize: '1rem',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		textInput: {
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			fontWeight: '400',
			fontSize: '1rem',
			letterSpacing: '0.08em',
			lineHeight: '1.4375rem',
		},
		textInputLabel: {
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			fontWeight: '400',
			fontSize: '1rem',
			letterSpacing: '0.00938em',
			lineHeight: '1.4375rem',
			color: 'rgba(0,0,0,0.6)'
		},
		tightText: {
		  color: 'black',
		  fontSize: '0.8rem',
		  lineHeight: '1.2rem',
		  minHeight: '0.8rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  letterSpacing: '0.01071em',
		},
		miniText: {
		  color: 'black',
		  fontSize: '0.6rem',
		  lineHeight: '1rem',
		  minHeight: '0.6rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  letterSpacing: '0.01071em',
		  textAlign: 'center',
		},
		tableHead: {
		  color: 'black',
		  fontSize: '0.8rem',
		  fontWeight: '500',
		  lineHeight: '1.2rem',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		tableContent: {
		  color: 'black',
		  fontSize: '0.8rem',
		  lineHeight: '1.2rem',
		  fontWeight: '400',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		tableTotal: {
		  color: 'black',
		  fontSize: '0.8rem',
		  lineHeight: '1.2rem',
		  fontWeight: '400',
		  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		inputText: {
			color: 'black',
			fontSize: '1rem',
			lineHeight: '1.5rem',
			fontWeight: '400',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		  },
		labelSm: {
			color: 'black',
			fontSize: '0.8rem',
			lineHeight: '1rem',
			fontWeight: '600',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		labelMd: {
			color: 'black',
			fontSize: '1rem',
			lineHeight: '1.2rem',
			fontWeight: '600',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		labelLg: {
			color: 'black',
			fontSize: '1.2rem',
			lineHeight: '2rem',
			fontWeight: '600',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		labelSmThin: {
			color: 'black',
			fontSize: '0.8rem',
			lineHeight: '1rem',
			fontWeight: '200',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		labelMdThin: {
			color: 'black',
			fontSize: '1rem',
			lineHeight: '1.2rem',
			fontWeight: '200',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		labelLgThin: {
			color: 'black',
			fontSize: '1.2rem',
			lineHeight: '2rem',
			fontWeight: '200',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
		},
		buttonSm: {
			color: 'black',
			fontSize: '0.875rem',
			lineHeight: '1.2rem',
			fontWeight: '400',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			textTransform: 'none',
		},
		button: {
			color: 'black',
			fontSize: '1rem',
			lineHeight: '1.2rem',
			fontWeight: '200',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			textTransform: 'none',
		},
		buttonLg: {
			color: 'black',
			fontSize: '1.2rem',
			lineHeight: '2rem',
			fontWeight: '400',
			fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
			textTransform: 'none',
		},
	  }
}