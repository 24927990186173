import React,{ FC } from 'react'
import { IconButton, IconButtonProps, styled } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

interface StyledIconButtonProps extends IconButtonProps {
	placement: 'inside' | 'outside';
	size: 'small' | 'medium';
}

const StyledIconButton = styled(IconButton, {
	shouldForwardProp: prop => prop !== 'placement' && prop !== 'size'
})<StyledIconButtonProps>(({theme, placement, size}) => ({
	position: 'absolute',
	top: '-15px',
	right: '-15px',
	width: '30px',
	height: '30px',
	...(size === 'small' && {
		top: '-10px',
		right: '-10px',
		width: '20px',
		height: '20px',
		'> svg': {
			width: '0.8em',
			height: '0.8em',
		}
	}),
	...(placement === 'inside' && {
		top: '15px',
		right: '15px',
	}),
	backgroundColor: theme.palette.grey[300],
	borderRadius: '50%',
	['&:hover']:{
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	}
}))

interface RemoveButtonProps {
	placement?: 'inside' | 'outside';
	size?: 'small' | 'medium';
	handleRemove: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const RemoveButton: FC<RemoveButtonProps> = ({handleRemove, placement = 'outside', size = 'medium'}) => {
	const triggerRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		handleRemove(e)
	}
	return (
		<StyledIconButton onClick={triggerRemove} placement={placement} size={size}>
			<CloseIcon />
		</StyledIconButton>
	)
}

export default RemoveButton