import SvgIconWrapper, { IconSize, SvgIconWrapperProps } from "./SvgIconWrapper";

interface DeleteIconProps extends SvgIconWrapperProps {}

const DeleteIcon = ({ iconSize = 'large', iconColor, onClick }: DeleteIconProps) => {
	return (
		<SvgIconWrapper iconSize={iconSize} iconColor={iconColor} onClick={onClick}>
			<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="m336-307.69 144-144 144 144L652.31-336l-144-144 144-144L624-652.31l-144 144-144-144L307.69-624l144 144-144 144L336-307.69ZM480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Z"/></svg>
		</SvgIconWrapper>
	)
}

export default DeleteIcon