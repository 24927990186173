import type { Customer } from "../../types/storeTypes"

interface InitialCustomerState extends Customer {
	status: string;
	stateValid: boolean;
	validation: any;
}
const INITIAL_STATE: InitialCustomerState = {
	id: '',
	companyName: '',
	email: '',
	address: '',
	postalCode: '',
	city: '',
	country: 'SE',
	ORGnumber: '',
	status: '', // Don't save
	stateValid: true, // Don't save
	validation: {}, // Don't save
}

export const customerState = () => INITIAL_STATE