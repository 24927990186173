import Dinero from 'dinero.js'
import currency from 'currency.js'

Dinero.globalLocale = 'se-SV'
Dinero.defaultCurrency = 'SEK'
Dinero.globalFormat = '0.0 SEK'


type CurrencyFunction = (value: number) => currency;

const SEK_CURRENCY: CurrencyFunction = (value) => currency(value, { symbol: "SEK", separator: " ", decimal: ",", precision: 0, pattern:"# !" })

export enum Currency {
	SEK = 'SEK',
}

export type CurrenciesType = {
	[key in Currency]: CurrencyFunction
}

export const CURRENCIES: CurrenciesType = {
	SEK: SEK_CURRENCY
}