import { auth } from "../../Firebase";
import {
	signInWithEmailAndPassword,
	onAuthStateChanged,
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	AuthError,
	UserCredential,
} from 'firebase/auth';

export type FirebaseUser = Exclude<typeof auth.currentUser, null>

export function getValidatedUser() {
	console.log('GETTING VALIDATED USER');
	return new Promise((resolve, reject) => {
		console.log('Waiting for resolve...');
	  const unsubscribe = onAuthStateChanged(
			auth,
			(user) => {
				console.log('RECIEVED', user);
				unsubscribe();
				resolve(user);
			},
			reject // pass up any errors attaching the listener
		);
	});
  }

export const currentUser = () => {
	if (auth.currentUser) return auth.currentUser.uid
	return false
}

export const requestPasswordReset = async (userEmail: string) => {
	try {
		console.log('Requesting reset for', userEmail);
		const actionCode = {
			url: `${window.location.origin}/reset-password`
		}
		const resetRequested = await sendPasswordResetEmail(auth, userEmail, actionCode)
		console.log(resetRequested);
		return
	} catch (error) {
		console.log(error);
	}
}

export const doPasswordReset = async (oobCode: string, newPassword: string) => {
	try {
		console.log('Doing reset', oobCode, newPassword);
		const passwordReset = await confirmPasswordReset(auth, oobCode, newPassword)
		console.log(passwordReset);
		return { pwdReset: true }
	} catch (error: any) {
		console.log(error.code);
		return {
			pwdReset: false,
			error: error.code
		}
	}
}

type SignInUserPasswordResponse = {
	signedIn: boolean;
	user: FirebaseUser | null;
	error: null | AuthError;
}

export const signInUserPassword = async (userEmail: string, password: string): Promise<SignInUserPasswordResponse> => {
	try {
		const userCredential: UserCredential = await signInWithEmailAndPassword(auth, userEmail, password )
		const user: FirebaseUser = userCredential.user
		console.log('Sign in User PWD', user);
		return {
			signedIn: true,
			user: user,
			error: null,
		}
	} catch (error: any) {
		console.log('Authentication', error);
		
		return {
			signedIn: false,
			user: null,
			error: error.code
		}
	}
}

export const createUserPassword = async (userEmail: string, password: string): Promise<SignInUserPasswordResponse> => {
	try {
		const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, userEmail, password)
		console.log('userCred', userCredential);
		
		const user: FirebaseUser = userCredential.user
		return {
			signedIn: true,
			user: user,
			error: null,
		}
	} catch (error: any) {
		return {
			signedIn: false,
			user: null,
			error: error.code
		}
	}
}

export const signInLink = async (userEmail = false) => { }
//     try {
//         const { email } = store.getState().user
//         console.log('email', userEmail, email);
// 		userEmail = email.includes('@') ? email : userEmail


//         if (!userEmail) return false
//         // const email = 'r.svartholm@gmail.com'
//         const { pathname, origin } = window.location
//         const currentURL = `${origin}/verifylink?redirect=${encodeURIComponent(pathname)}`

//         const actionCodeSettings = {
//             url: currentURL,
//             handleCodeInApp: true,
//         };

//         console.log('should create link');
//         await sendSignInLinkToEmail(auth, userEmail, actionCodeSettings)
//         window.localStorage.setItem('emailForSignIn', userEmail);
//         console.log('Link sent');
//         return true
//     } catch (error) {
//         console.log('error generating signInLink');
//         console.log(error);
//     }
// }

export const verifySingInLink = async () => { }
//     try {
//         console.log(auth);
//         if (isSignInWithEmailLink(auth, window.location.href)) {
//             let email = window.localStorage.getItem('emailForSignIn');
//             const { currentUser } = auth
//             const credential = EmailAuthProvider.credentialWithLink(email, window.location.href);
//             const userCred = await linkWithCredential(currentUser, credential)
//             console.log('User is linked', userCred);
//             window.localStorage.removeItem('emailForSignIn');
//         } else {
//             alert('not valid link!')
//         }
//     } catch (error) {
//         console.log(error);
//     }
// }