import { Paper, PaperProps, styled } from "@mui/material"
import { UploadedFile } from "../../store/types/fileTypes";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/reducers/reducers";
import { selectFileFromMessage } from "../../store/reducers/message/messageSelectors";
import RemoveButton from "../buttons/RemoveButton";

interface StyledPaperProps extends PaperProps {
	bgImage?: string; 
}

const StyledPaper = styled(Paper, {
	shouldForwardProp: prop => prop !== 'bgImage'
})<StyledPaperProps>(({bgImage = false}) => ({
	position: 'relative',
	width: '100%',
	height: '300px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	...(bgImage && {
		backgroundImage: `url(${bgImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	})
}))

interface MessageUploadedFilesProps {
	file: UploadedFile;
	onFileRemove: (id: string) => void;
}

const MessageUploadedFiles = ({file, onFileRemove}: MessageUploadedFilesProps) => {

	const selectedFile = useAppSelector((state: RootState) => selectFileFromMessage(state, file.id))

	const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onFileRemove(file.id)
	}

	return (
		<StyledPaper elevation={0} square bgImage={selectedFile?.fileURL}>
			<RemoveButton handleRemove={handleRemove} />
		</StyledPaper>
	)
}

export default MessageUploadedFiles