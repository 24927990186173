import { Box, styled } from "@mui/material"
import RoundButton from "../../buttons/RoundButton"
import SpinningDots from "../../animations/SpinningDots";
import { ApiStatus } from "../../../store/types/storeTypes";

const FloatingBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'boxPosition'
})<{ boxPosition: 'right' | 'left'; }>(({theme, boxPosition}) => ({
	position: 'fixed',
	zIndex: '2',
	[theme.breakpoints.down('sm')]: {
		...(boxPosition === 'right' ? { right: '16px' } : { left: '16px' } ),
		bottom: '80px',
	},
	[theme.breakpoints.only('sm')]: {
		...(boxPosition === 'right' ? { right: '16px' } : { left: '16px' } ),
		bottom: '200px',
	},
	[theme.breakpoints.up('md')]: {
		...(boxPosition === 'right' ? { right: 'calc(50vw - 400px + 16px)' } : { left: 'calc(50vw - 400px + 16px)' } ),
		bottom: '200px',
	}
}))

interface FloatingSaveButton {
	isLoading?: ApiStatus;
	label?: string;
	position?: 'left' | 'right';
	onSave: () => void;
}

const FloatingSaveButton = ({ isLoading, label = 'Save', position = 'right', onSave }: FloatingSaveButton) => {
	const loading = isLoading === ApiStatus.PENDING
	return (
		<FloatingBox boxPosition={position}>
			<RoundButton onClick={onSave} btnColor="black" animate={true}>{ loading ? <SpinningDots /> : label }</RoundButton>
		</FloatingBox>
		
	)
}

export default FloatingSaveButton