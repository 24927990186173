import { styled } from "@mui/material"

const Svg = styled('svg')(({theme}) => ({
	width: '100%',
	height: 'auto',
}))

const Text = styled('text')(({theme}) => ({
	fontFamily: theme.typography.logo.fontFamily,
	fill: theme.palette.misc.logo,
}))

const PejmeLogo = () => {
	return (
		<Svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 82 14"
			>
			<Text x={0} y={13}>PEJME</Text>
		</Svg>
	)
}

export default PejmeLogo