import { Box, Typography, styled } from "@mui/material";

type EntityType = 'contact' | 'item' | 'email';

const TextMap = new Map<EntityType, { plural: string; singular: string; }>([
	['contact', {
		singular: 'contact',
		plural: 'contacts',
	}],
	['item', {
		singular: 'item',
		plural: 'items',
	}],
	['email', {
		singular: 'email',
		plural: 'emails',
	}]
])

const EmptyListBox = styled(Box)(({}) => ({
	height: '40vh',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
}))

interface EmptyListProps {
	entityType: EntityType;
	onEmptyClick: () => void;
}

const EmptyList = ({onEmptyClick, entityType}: EmptyListProps) => {

	const copy = TextMap.get(entityType)

	return (
		<EmptyListBox onClick={onEmptyClick}>
			<Box>
				<Typography sx={{textDecoration: 'underline'}} display={'inline'}>Click here</Typography>
				<Typography display={'inline'}> to upload your first {copy?.singular}.</Typography>
			</Box>
			
		</EmptyListBox>
	)
}

export default EmptyList