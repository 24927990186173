import { createAsyncThunk } from "@reduxjs/toolkit";
import { ref, uploadBytes } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid';
import { avatarRef } from "../../Firebase";
import { awaitFileURLFromPath } from "../utils/uploadUtil";

export const uploadFile = async (file, authId) => {
    try {
		if (!authId) throw new Error('No authId provided')
        const objectUUID = uuidv4();
        const fileRef = ref(avatarRef, `${authId}/${objectUUID}`);
        const snapshot = await uploadBytes(fileRef, file.arrayBuffer, { contentType: file.meta.type })
        return {
            thumbnailPath: snapshot.metadata.fullPath.replace("avatars", "thumbnails"),
            fullPath: snapshot.metadata.fullPath,
			uploaded: true,
        };
    } catch (error) {
		return {
			uploaded: false
		}
    }
}

export const saveAvatar = createAsyncThunk('user/saveAvatar', async (args, thunkAPI) => {
    try {
        const { id } = thunkAPI.getState().user
		if (!id) throw new Error('No user found')
        const uploadedFile = await uploadFile(args, id)
		if (!uploadedFile.uploaded) throw new Error('Failed to upload file')
        const thumbnailDataURL = await awaitFileURLFromPath(uploadedFile.thumbnailPath)
        return {
			thumbnailPath: uploadedFile.thumbnailPath,
			fullPath: uploadFile.fullPath,
			URL: thumbnailDataURL
		}
    } catch (error) {
		return {
			uploaded: false,
			error: error.message,
		}
    }
})