import { PAYMENT_OPTIONS } from "../../types/invoiceTypes";
import { ApiStatus, DEFAULT_AVATAR } from "../../types/storeTypes"
import { User } from "../user/userState"

interface InitialPublicUserState extends User {
	status: ApiStatus;
}

export const INITIAL_STATE: InitialPublicUserState = {
	companyName: '',
	slug: '',
	address: '',
	postalCode: '',
	city: '',
	country: 'SE',
	email: '',
	VATnumber: '',
	ORGnumber: '',
	links: [],
	bio: '',
	profession: '',
	avatar: { ...DEFAULT_AVATAR },
    id: '',
	customers: [], // Private
	invoices: [], // Private
	defaultPaymentOptions: { ...PAYMENT_OPTIONS },
	defaultTheme: 'pinkOrange',
	status: ApiStatus.SUCCESS
}