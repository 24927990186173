import { Box } from "@mui/material"
import { PropsWithChildren } from "react"


interface BoxPaddingProps extends PropsWithChildren {

}

const BoxPadding = ({ children }: BoxPaddingProps) => {

	return (
		<Box sx={{ width: '100%', height: '100%', padding: '1em', boxSizing: 'border-box'}}>
			{ children }
		</Box>
	)
}

export default BoxPadding