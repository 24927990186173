import { ItemID } from "./itemTypes";

export enum ApiStatus {
	PENDING = "PENDING",
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}

export const SliceEntityMap: { [key: string]: string } = {
    'items': 'item',
    'messages': 'message',
};

export const API_STATUS = {
	'PENDING': 'PENDING',
	'DONE': 'DONE',
	'ERROR': 'ERROR',
}

export type AnyIdType = ItemID | string;

export interface Avatar {
	URL: string;
	fullPath: string;
	thumbnailPath: string;
}

export const DEFAULT_AVATAR: Avatar = {
	URL: '',
	fullPath: '',
	thumbnailPath: '',
}



export interface Customer {
	id: string;
	companyName: string;
	email: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	ORGnumber: string;
}


