import { Container, Typography } from '@mui/material'
import { Fragment } from 'react'
import WtsFooter from '../components/molecules/wtsfooter/WtsFooter'
import Navbar from '../components/organisms/navbar/Navbar'
import { useRouteError } from 'react-router-dom'

type RouteErrorType = {
	status: string;
}

const ErrorPage = () => {
	
	const error = useRouteError() as RouteErrorType;

	return (
		<Fragment>
			<Navbar />
			<Container>
				<Typography>A {error.status} error occured</Typography>
			</Container>
			<WtsFooter />
		</Fragment>
	)
}

export default ErrorPage