import ContactList from "../../components/organisms/contactlist/ContactList"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectContacts, selectContactsLoading } from "../../store/reducers/contact/contactSelectors"
import OutlineButton from "../../components/atoms/outlinebutton/OutlineButton"
import { createOrUpdateContact } from "../../store/reducers/contact/contactSlice"
import { Contact } from "../../store/reducers/contact/contactState"
import { useState } from "react"
import CoverModal from "../../components/modals/CoverModal"
import AddContact from "../../components/organisms/addcontact/AddContact"
import EmptyList from "../../components/molecules/emptylist/EmptyList"

const Contacts = () => {

	const dispatch = useAppDispatch()

	const contacts = useAppSelector(selectContacts)
	const contactsLoading = useAppSelector(selectContactsLoading)

	const hasContacts = contacts.length > 0

	const [addContactModalOpen, setAddContactModalOpen] = useState<boolean>(false);
	const handleOpenAddContact = () => setAddContactModalOpen(true)

	const handleAddContact = (contact: Contact) => {
		dispatch( createOrUpdateContact(contact) )
		setAddContactModalOpen(false)
	}

	return (
		<>
			{ hasContacts && <OutlineButton label="Add contact" onClick={handleOpenAddContact} stretch /> }
			<ContactList contacts={contacts} />
			{ !hasContacts && <EmptyList entityType={'contact'} onEmptyClick={handleOpenAddContact}/> }
			<CoverModal
				title={'Add contact'}
				isOpen={addContactModalOpen}
				onClose={() => setAddContactModalOpen(false)}>
				<AddContact onSubmit={handleAddContact} />
			</CoverModal>
		</>
	)
}


export default Contacts