import { Fragment } from 'react'
import { Paper, PaperProps, Typography, styled, useTheme } from "@mui/material"
import UploadComponent from "./UploadComponent"
import { UploadedFile } from "../../store/types/fileTypes";
import MessageUploadedFiles from './MessageUploadedFiles';

interface StyledPaperProps extends PaperProps {
	bgImage?: string; 
}

const StyledPaper = styled(Paper, {
	shouldForwardProp: prop => prop !== 'bgImage'
})<StyledPaperProps>(({theme}) => ({
	position: 'relative',
	width: '100%',
	height: '100px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '2em',
	borderStyle: 'solid',
	borderWidth: '1px',
	borderColor: theme.palette.primary.light,
}))

interface MessageUploadListProps {
	onFileRemove: (id: string) => void;
	onFileUploaded: (e: any) => void;
	uploadedFiles: UploadedFile[];
	canEdit: boolean;
}

const MessageUploadList = ({ onFileUploaded, onFileRemove, uploadedFiles, canEdit = true }: MessageUploadListProps) => {

	const theme = useTheme();

	return (
		<Fragment>
			{ uploadedFiles.map((value) => <MessageUploadedFiles file={value} onFileRemove={onFileRemove}/>)}
			{ canEdit ? <UploadComponent onUploaded={onFileUploaded}>
				<StyledPaper elevation={0}>
					<Typography variant='h3' color={theme.palette.primary.light}>Add files</Typography>
				</StyledPaper>
			</UploadComponent> : null }
		</Fragment>
	)
}

export default MessageUploadList