import TextInput from "../inputs/text/TextInput"
import { ChangeEvent, Fragment, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectCompanyName, selectUserProfession } from "../../store/reducers/user/userSelectors"
import Column from "../layouts/column/Column"
import { createOrUpdateUser, setUserCompanyName, setUserProfession } from "../../store/reducers/user/userSlice"
import SaveButtons from "../organisms/savebuttons/SaveButtons"
import { ApiStatus } from "../../store/types/storeTypes"
import { useNavigate } from "react-router-dom"

type FormStates = 'name' | 'profession' | 'done'

interface GetStartedFormProps {}

const GetStartedForm = ({}: GetStartedFormProps) => {

	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [formState, setFormState] = useState<FormStates>('name');

	const handleNextState = async () => {
		if (formState === 'name') {
			setFormState('profession')
			return
		}
		if (formState === 'profession') {
			setFormState('done')
			await dispatch( createOrUpdateUser() )
			navigate('/admin/')
			return
		}
	}

	const companyName = useAppSelector(selectCompanyName)
	const userProfession = useAppSelector(selectUserProfession)

	const nameValid = companyName.length > 0
	const professionValid = userProfession.length > 0

	const handleSetCompanyName = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserCompanyName(e.currentTarget.value) )
	const handleSetProfession = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserProfession(e.currentTarget.value) )

	return (
		<Column alignItems="center" height={'50vh'} justifyContent="center">
			{{
				'name':
					<Fragment>
						<TextInput
							placeholder="Type your name"
							helperText="Seen in profile"
							value={companyName}
							onChange={handleSetCompanyName} />
							<SaveButtons onSave={handleNextState} saveStatus={ApiStatus.SUCCESS} disabled={!nameValid} label="Next" />
					</Fragment>,
				'profession':
					<Fragment>
						<TextInput
							placeholder="Type your profession"
							helperText="Seen in profile"
							value={userProfession}
							onChange={handleSetProfession} />
							<SaveButtons onSave={handleNextState} saveStatus={ApiStatus.SUCCESS} disabled={!professionValid} label="Next" />
					</Fragment>,
				'done': <></>,
			}[formState]}
			
		</Column>
	)
}

export default GetStartedForm