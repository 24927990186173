import { createListenerMiddleware } from "@reduxjs/toolkit";
import { getUserBySlug } from "../reducers/publicUser/publicUserActions";
import { getItemsForUser } from "../reducers/item/itemSlice";
import { getHighlightedItemsForUser } from "../reducers/highlight/highlightSlice";

const publicUserMiddleware = createListenerMiddleware()

publicUserMiddleware.startListening({
	actionCreator: getUserBySlug.fulfilled,
	effect: async (args, listenerApi) => {
		const { payload } = args
		const params = {
			userId: payload.id
		}
		listenerApi.dispatch( getItemsForUser(params) )
		listenerApi.dispatch( getHighlightedItemsForUser(params) )
	}
})

export default publicUserMiddleware