import { styled } from "@mui/material"

interface StyledImageProps {
	size?: ImageSize;
	elevation?: number
}

const StyledImage = styled('img')<StyledImageProps>(({theme, size}) => ({
	width: '200px',
	height: '200px',
	...(size === 'small' && {
		width: '100px',
		height: '100px',
	}),
	...(size === 'large' && {
		width: '300px',
		height: '300px',
	}),
	...(size === 'responsive' && {
		width: '100%',
		height: 'auto',
	}),
}))

type ImageSize = 'small' | 'medium' | 'large' | 'responsive';

interface ImageCardProps {
	bgImage?: string;
	size?: ImageSize;
}

const ImageCard = ({ bgImage, size = 'medium' }: ImageCardProps) => {	
	
	return (
		<StyledImage src={bgImage} size={size} />
	)
}

export default ImageCard