import { Box, styled } from '@mui/material'
import useEmblaCarousel from 'embla-carousel-react'
import Slide from './Slide'

interface SliderProps {
	slideNodes: JSX.Element[];
}

const ContainerBox = styled(Box)(({theme}) => ({
	display: 'flex',
	// gridAutoFlow: 'column',
	// gridAutoColumns: '40%',
}))

const Slider = ({ slideNodes}: SliderProps) => {

	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'start',
	})

	return (
		<Box ref={emblaRef} className="embla" sx={{overflow: 'hidden'}}>
			<ContainerBox className="embla__container">
				{ slideNodes.map((slide, index) => <Slide key={`slide-${index}`}>{slide}</Slide>) }
			</ContainerBox>
		</Box>
	)
}

export default Slider

// https://www.embla-carousel.com/api/options