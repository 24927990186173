import { ChangeEvent } from "react"
import { Link, Typography } from "@mui/material"
import TextInput from "../inputs/text/TextInput"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectCompanyName, selectUserAddress, selectUserCity, selectUserEmail, selectUserPostalCode, selectUserSlug } from "../../store/reducers/user/userSelectors"
import Column from "../layouts/column/Column"
import { setUserAddress, setUserBio, setUserCity, setUserPostalCode, setUserProfession } from "../../store/reducers/user/userSlice"


const SettingsForm = () => {

	const dispatch = useAppDispatch()

	const userSlug = useAppSelector(selectUserSlug)
	const userAddress = useAppSelector(selectUserAddress)
	const userPostalCode = useAppSelector(selectUserPostalCode)
	const userCity = useAppSelector(selectUserCity)
	const userEmail = useAppSelector(selectUserEmail)
	const companyName = useAppSelector(selectCompanyName)

	const handleSetAddress = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserAddress(e.currentTarget.value) )
	const handleSetPostalCode = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserPostalCode(e.currentTarget.value) )
	const handleSetCity = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserCity(e.currentTarget.value) )

	return (
		<Column alignItems="flex-start">
			<Typography variant="h4">{companyName}</Typography>
			<TextInput placeholder="Address" label="Address" value={userAddress} onChange={handleSetAddress} />
			<TextInput placeholder="Postal code" label="Postal code" value={userPostalCode} onChange={handleSetPostalCode} />
			<TextInput placeholder="City"  label="City" value={userCity} onChange={handleSetCity} />
			<TextInput placeholder="Email" label="Email" canEdit={false} value={userEmail} onChange={() => {}} />
		</Column>
	)
}

export default SettingsForm