import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearValidationForParam, validateState } from "../../validation/validationTypes";
import { VALIDATE_MESSAGE_RULES } from "../../validation/validationRules";
import { createAddFileThunk, fileActionsReducer, createAddFileExtraReducers } from "../../actions/fileActions";
import { messageState } from "./messageState";
import { CtaFieldType, defaultMessage, FileUploadListFieldType, ItemListFieldType } from '../../types/messageTypes'
import { addCreateOrUpdateMessageCases, addGetMessagesForUserCases, addGetMessageCases, addRemoveMessageCases, addSendMessageCases, addItemToItemListMessageCases } from "./messageActions";
import { createUploadedFile, UploadedFile, UploadTask } from "../../types/fileTypes";
import { Item, ItemID } from "../../types/itemTypes";
import { ContactID } from "../contact/contactState";

const fileActions = fileActionsReducer('messages')
const addFileThunk = createAddFileThunk('messages')

interface UpdateFieldPayload {
	messageId: string;
    fieldKey: string;
    value: string; // Assuming the value is always a string
}

interface UpdateCtaFieldPayload extends UpdateFieldPayload {
    fieldValueToUpdate: 'link' | 'copy'; // Specify possible values for fieldValueToUpdate
}

interface UpdateFileFieldPayload {
	fieldKey: string;
	value: UploadTask;
}

interface RemoveFileFiledPayload {
	fieldKey: string;
	fileId: string;
}

interface UpdateItemListField {
	fieldKey: string;
	itemId: ItemID;
}

interface RemoveItemListField {
	fieldKey: string;
	itemId: string;
}

interface toggleContactAsRecipientPayload {
	messageId: string;
	contactId: ContactID;
}

interface SetSubject {
	messageId: string;
	subject: string;
}

export const messageSlice = createSlice({
	name: 'messages',
	initialState: { ...messageState() },
	reducers: {
		...fileActionsReducer('messages'),
		resetMessage: (state) => {
			state.message = defaultMessage({})
		},
		updateField: (state, action: PayloadAction<UpdateFieldPayload>) => {
			const { messageId, fieldKey, value } = action.payload
			let message;
			if (messageId === state.message.id) {
				const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey);
				if (index === -1) throw new Error('Field not found')
				state.message.fields[index].fieldValue = value; // Manipulate any field as needed
			} else {
				const messageIndex = state.messages.findIndex(message => message.id === messageId)
				message = state.messages[messageIndex]
				const fieldIndex = message.fields.findIndex(obj => obj.fieldKey === fieldKey);
				if (fieldIndex === -1) throw new Error('Field not found')
				state.messages[messageIndex].fields[fieldIndex].fieldValue = value
			}			
		},
		updateCtaField: (state, action: PayloadAction<UpdateCtaFieldPayload>) => {
			const { fieldKey, fieldValueToUpdate, value } = action.payload;
			const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey);
			if (fieldValueToUpdate && fieldValueToUpdate === 'link') {
				let field = state.message.fields[index] as CtaFieldType
				field.fieldValue.link = value
				state.message.fields[index] = field
			}else if (fieldValueToUpdate && fieldValueToUpdate === 'copy') {
				let field = state.message.fields[index] as CtaFieldType
				field.fieldValue.copy = value
				state.message.fields[index] = field
			}
		},
		updateUploadField: (state, action: PayloadAction<UpdateFileFieldPayload>) => {
			const { fieldKey, value } = action.payload
			const tempUploadedFile: UploadedFile = createUploadedFile({
				id: value.id,
			})
			const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey);
			if (index !== -1) {
				state.message.fields[index].fieldValue = tempUploadedFile
			}
		},
		removeUploadedFile: (state,action: PayloadAction<RemoveFileFiledPayload>) => {
			const { fieldKey, fileId } = action.payload
			const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey);
			console.log(index);
			
			if (index !== -1) {
				state.message.files = state.message.files.filter((item: UploadedFile) => item.id !== fileId)
			}
		},
		updateUploadListField: (state, action: PayloadAction<UpdateFileFieldPayload>) => {
			const { fieldKey, value } = action.payload
			const tempUploadedFile: UploadedFile = createUploadedFile({
				id: value.id,
			})
			const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey);
			if (index !== -1) {
				let selectedField = state.message.fields[index] as FileUploadListFieldType
				const fileIndex = selectedField.fieldValue.findIndex(obj => obj.id === value.id)
				if (fileIndex !== -1) {
					selectedField.fieldValue[fileIndex] = tempUploadedFile
					state.message.fields[index] = selectedField
				} else {
					selectedField.fieldValue.push(tempUploadedFile)
					state.message.fields[index] = selectedField
				}
			}
		},
		removeUploadListField: (state, action: PayloadAction<RemoveFileFiledPayload>) => {
			const { fieldKey, fileId } = action.payload
			const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey);
			if (index !== -1) {
				let selectedField = state.message.fields[index] as FileUploadListFieldType
				const fileIndex = selectedField.fieldValue.findIndex(obj => obj.id === fileId)
				console.log('remove upload file index', fileIndex);
				
				if (fileIndex !== -1) {
					selectedField.fieldValue = selectedField.fieldValue.filter((item: UploadedFile) => item.id !== fileId)
					state.message.fields[index] = selectedField
				}
				state.message.files = state.message.files.filter((item: UploadedFile) => item.id !== fileId)
			}
		},
		updateItemListField: (state, action: PayloadAction<UpdateItemListField>) => {
			const { fieldKey, itemId } = action.payload
			const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey)
			console.log('index', index);
			if (index !== -1) {
				let selectedField = state.message.fields[index] as ItemListFieldType
				console.log('SelectedField', JSON.parse(JSON.stringify(selectedField)), 'Searching for:', itemId);
				
				const itemIndex = selectedField.fieldValue.findIndex(val => val === itemId)
				console.log('itemIndex', itemIndex);
				if (itemIndex === -1) {
					selectedField.fieldValue.push(itemId)
					state.message.fields[index] = selectedField
				}
			}
		},
		removeItemListField: (state, action: PayloadAction<RemoveItemListField>) => {
			const { fieldKey, itemId } = action.payload
			const index = state.message.fields.findIndex(obj => obj.fieldKey === fieldKey);
			if (index !== -1) {
				let selectedField = state.message.fields[index] as ItemListFieldType
				const itemIndex = selectedField.fieldValue.findIndex(item => item === itemId)
				console.log('remove upload file index', itemIndex);
				if (itemIndex !== -1) {
					selectedField.fieldValue = selectedField.fieldValue.filter((item: ItemID) => item !== itemId)
					state.message.fields[index] = selectedField
				}
			}
		},
		setSubject: (state, action: PayloadAction<SetSubject>) => {
			if (state.message.id === action.payload.messageId) {
				state.message.subject = action.payload.subject
			} else {
				const index = state.messages.findIndex(msg => msg.id === action.payload.messageId)
				if (index === -1) throw new Error('Message not found')
				let message = state.messages[index]
				message.subject = action.payload.subject
				state.messages[index] = message
			}
		},
		setMessageTitle: (state, action) => {
			state.message.messageTitle = action.payload
		},
		toggleCustomerAsRecipient: (state, action) => {
			const idToAddOrRemove = action.payload;
			const index = state.message.recipientCustomers.indexOf(idToAddOrRemove);
			if (index !== -1) {
				// If the ID exists in the array, remove it
				state.message.recipientCustomers.splice(index, 1);
			} else {
				// If the ID doesn't exist in the array, add it
				state.message.recipientCustomers.push(idToAddOrRemove);
			}
		},
		toggleContactAsRecipient: (state, action: PayloadAction<toggleContactAsRecipientPayload>) => {
			const { messageId, contactId } = action.payload
			let foundMessage;
			const messageIndex = state.messages.findIndex((msg) => msg.id === messageId)
			if (messageIndex === -1) {
				foundMessage = state.message
			}else {
				foundMessage = state.messages[messageIndex]
			}
			const ctIndex = foundMessage.recipientCustomers.indexOf(contactId)
			if (ctIndex !== -1) {
				foundMessage.recipientCustomers.splice(ctIndex, 1)
			} else {
				foundMessage.recipientCustomers.push(contactId)
			}
			if (messageIndex === -1) {
				state.message = foundMessage
			} else {
				state.messages[messageIndex] = foundMessage
			}
		},
		setRecipientEmail: (state, action) => {
			const emailToAddOrRemove = action.payload;
			const index = state.message.recipientEmails.indexOf(emailToAddOrRemove);
			if (index !== -1) {
				// If the ID exists in the array, remove it
				state.message.recipientEmails.splice(index, 1);
			} else {
				// If the ID doesn't exist in the array, add it
				state.message.recipientEmails.push(emailToAddOrRemove);
			}
		},
		clearValidation: clearValidationForParam,
		...fileActions,
	},
	extraReducers: builder => {
		const { pendingReducer, fulfilledReducer, rejectedReducer } = createAddFileExtraReducers('messages')
		builder.addCase(addFileThunk.pending, pendingReducer)
		builder.addCase(addFileThunk.rejected, rejectedReducer)
		builder.addCase(addFileThunk.fulfilled, fulfilledReducer)

		addCreateOrUpdateMessageCases(builder)
		addGetMessagesForUserCases(builder)
		addGetMessageCases(builder)
		addRemoveMessageCases(builder)
		addSendMessageCases(builder)
		addItemToItemListMessageCases(builder)		
	}
})

export const {
	resetMessage,
	updateField,
	setMessageTitle,
	setRecipientEmail,
	toggleCustomerAsRecipient,
	toggleContactAsRecipient,
	clearValidation,
	updateCtaField,
	setSubject,
	updateUploadField,
	removeUploadedFile,
	updateUploadListField,
	removeUploadListField,
	updateItemListField,
	removeItemListField,
	// setNameForFile,
	// removeFile,
} = messageSlice.actions

export { addFileThunk as addFileToMessage }

export default messageSlice.reducer