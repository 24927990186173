import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../reducers";


const contactSlice = (state: RootState) => state.contact

export const selectContacts = createSelector(
	[contactSlice],
	(slice) => slice.contacts
)

export const selectContactsLoading = createSelector(
	[contactSlice],
	(slice) => slice.status
)