import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "../reducers";
import type { Message, TitleFieldType } from "../../types/messageTypes";

const messageSlice = (state: RootState) => state.messages
const selectId = (state: RootState, id: string): string => id;

export const selectCurrentMessage = createSelector(
	[messageSlice],
	(slice) => slice.message
)
export const selectMessages = createSelector(
	[messageSlice],
	(slice) => slice.messages
)

export const selectMessageById = createSelector(
	[selectMessages, selectId],
	(messages, id) => {
		return messages.find((message) => message.id === id)
	}
)

export const currentMessageFiles = (state: RootState) => state.messages.message.files

export const selectMessageStatus = createSelector(
	[messageSlice],
	(slice) => slice.status
)

export const selectSortedMessages = createSelector(
	[selectMessages],
	(messages: Message[]) => {
		return [...messages].sort((a,b) =>
			a.messageTitle.localeCompare(b.messageTitle)
		)
	}
)

export const selectMessageTitle = createSelector(
	[selectMessageById],
	(message) => {
		const titleField =  message?.fields.find((msg) => msg.fieldKey === 'messageTitle') as TitleFieldType
		return titleField?.fieldValue || ''
	}
)

const selectUploads = (state: RootState) => state.messages.uploads
const selectFiles = (state: RootState) => state.messages.files

const selectObjectId = (state: RootState, id?: string) => id;

export const selectUploadById = createSelector(
	[selectUploads, selectObjectId],
	(uploads, id) => {
		return uploads.find((upload) => upload.id === id)
	}
)

export const selectFileById = createSelector(
	[selectFiles, selectObjectId],
	(files, id) => {
		return files.find((file) => file.id === id)
	}
)

export const selectFileFromMessage = createSelector(
	[currentMessageFiles, selectObjectId],
	(files, id) => {
		return files.find((file) => file.id === id)
	}
)

export const selectMessageContacts = createSelector(
	[selectMessageById],
	(message) => message?.recipientCustomers || []
)