import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Contact, ContactID, contactState } from "./contactState";
import { RootState } from "../reducers";
import { getEntitiesForUser, removeEntityById, saveOrCreateEntity } from "../../utils/firebaseUtils";
import { ApiStatus } from "../../types/storeTypes";
import { IS_EMAIL } from "../../validation/validationTypes";

type createOrUpdateContactResp = {
	saved: boolean;
	contact: Contact;
}
type createOrUpdateContactArg = Contact

export const createOrUpdateContact = createAsyncThunk<createOrUpdateContactResp, createOrUpdateContactArg>(
	'contact/create',
	async (args, thunkApi) => {
		try {
			const { user } = thunkApi.getState() as RootState
			const result = await saveOrCreateEntity('contacts', args, user.id)
			if (!result.saved) throw new Error('Failed to save contact')
			const savedContact = result.entity as Contact
			return {
				saved: true,
				contact: savedContact
			}
		} catch (error) {
			console.log(error);
			throw error
		}
	}
)

type addContactForUserArg = {
	contact: Contact;
	userId: string;
}

export const addContactForUser = createAsyncThunk<createOrUpdateContactResp, addContactForUserArg>(
	'contact/add',
	async (args, _) => {
		try {
			const { contact, userId } = args
			if (!IS_EMAIL(contact.email)) throw new Error('Invalid email')
			const result = await saveOrCreateEntity('contacts', contact, userId)
			console.log(result);
			
			if (!result.saved) throw new Error('Failed to add contact')
			const savedContact = result.entity as Contact
			return {
				saved: true,
				contact: savedContact
			}
		} catch (error) {
			console.log(error);
			throw error
		}
	}
)

type getContactsForUserResp = Contact[];

export const getContactsForUser = createAsyncThunk<getContactsForUserResp>(
	'contact/get',
	async (_, thunkApi) => {
		try {
			const { user } = thunkApi.getState() as RootState;
			const { result } = await getEntitiesForUser('contacts', user.id)
			return result as Contact[]
		} catch (error) {
			console.log(error);
			throw error
		}
	}	
)

type RemoveContactID = ContactID;
type removeContactResp = ContactID;

export const removeContact = createAsyncThunk<removeContactResp, RemoveContactID>(
	'contact/remove',
	async (args, thunkAPI) => {
		try {
			const { removed, removedId } = await removeEntityById('contacts', args)
			if (!removed) throw new Error('')
			return removedId as ContactID
		} catch (error) {
			console.log(error);
			throw error
		}
	}
)

export const contactSlice = createSlice({
	name: 'contact',
	initialState: { ...contactState() },
	reducers: {

	},
	extraReducers(builder) {
		builder.addCase(createOrUpdateContact.pending, (state) => { state.status = ApiStatus.PENDING })
		builder.addCase(createOrUpdateContact.fulfilled, (state, action) => {
			state.contacts = state.contacts.concat(action.payload.contact)
			state.status = ApiStatus.SUCCESS
		})
		builder.addCase(createOrUpdateContact.rejected, (state) => { state.status = ApiStatus.SUCCESS })
		builder.addCase(getContactsForUser.pending, (state) => { state.status = ApiStatus.PENDING })
		builder.addCase(getContactsForUser.fulfilled, (state, action) => {
			state.contacts = action.payload
			state.status = ApiStatus.SUCCESS
		})
		builder.addCase(removeContact.pending, (state) => { state.status = ApiStatus.PENDING })
		builder.addCase(removeContact.fulfilled, (state, action) => {
			if (action.payload) {
				state.contacts = state.contacts.filter((contact) => contact.id !== action.payload)
			}
			state.status = ApiStatus.SUCCESS
		})
		builder.addCase(addContactForUser.pending, (state) => {
			state.status = ApiStatus.PENDING
		})
		builder.addCase(addContactForUser.fulfilled, (state, action) => {
			state.status = ApiStatus.SUCCESS
			state.contacts = state.contacts.concat(action.payload.contact)
		})
		builder.addCase(addContactForUser.rejected, (state) => {
			state.status = ApiStatus.ERROR
		})
	},
})

export const {
} = contactSlice.actions

export default contactSlice.reducer