import {
	createSlice,
	createAsyncThunk
} from '@reduxjs/toolkit'
import {
	collection,
	getDocs,
	query,
	where,
} from 'firebase/firestore'
import { db } from '../../../Firebase'
import { ApiStatus } from '../../types/storeTypes'
import { RootState } from '../reducers'
import { Invoice } from '../../types/invoiceTypes'

interface InitialInvoicesState {
	invoices: Invoice[];
	status: ApiStatus;
}

const INITIAL_STATE: InitialInvoicesState = {
	invoices: [],
	status: ApiStatus.SUCCESS,
}

export const fetchInvoices = createAsyncThunk(
	'invoices/get',
	async (_, thunkAPI) => {
		try {
			const { user } = thunkAPI.getState() as RootState
			const invoicesRef = collection(db, 'invoices')
			const q = query(invoicesRef, where('user', '==', user.id ))
			const snapshot = await getDocs(q)
			let invoices: Invoice[] = [];
			snapshot.forEach(doc => {
				const invoice = { id: doc.id, ...doc.data() } as Invoice
				invoices.push(invoice)
			})
			return invoices
		} catch (error) {
			console.log('ERROR FETCHING INVOICES');
			console.log(error);
			return []
		}
})


export const invoicesSlice = createSlice({
	name: 'invoices',
	initialState: { ...INITIAL_STATE },
	reducers: {
		invoiceRemoved: (state, action) => {
			state.invoices = state.invoices.filter((item) => item.id !== action.payload.removedId )
		},
		resetInvoices: (state) => {
			state = Object.assign({}, INITIAL_STATE)
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchInvoices.pending, (state, action) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(fetchInvoices.fulfilled, (state, action) => {
				state.invoices = action.payload
				state.status = ApiStatus.SUCCESS
			})

	}
})

export const {
	invoiceRemoved,
	resetInvoices,
} = invoicesSlice.actions

export default invoicesSlice.reducer