import { logPageview } from "../helpers/AnalyticsHelper"
import ProtectedRoute from "../helpers/auth/ProtectedRoute"
import CreateProfile from "../pages/auth/CreateProfile"
import ResetPassword from "../pages/auth/ResetPassword"
import VerifyLink from "../pages/auth/VerifyLink"

const AuthRouter = [
	{
		path: "/verifylink",
		element:
			<ProtectedRoute>
				<VerifyLink />
			</ProtectedRoute>,
		loader: () => { logPageview({
		  page_title: 'Verify link',
		  page_location: window.location.origin + '/verifylink'
		}); return null },
	},
	{
		path: "/forgot-password",
		element: <ResetPassword />,
		loader: () => { logPageview({
		  page_title: 'Reset password',
		  page_location: window.location.origin + '/reset-password'
		}); return null },
	},
	{
		path: "/create-profile",
		element: <CreateProfile />,
	}
]

export default AuthRouter