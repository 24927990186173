import UploadArea from "../upload/UploadArea"
import TextInput from "../inputs/text/TextInput"
import { Item } from "../../store/types/itemTypes"
import { addFileThunk, removeItem, setIndexItemDescription } from "../../store/reducers/item/itemSlice"
import { UploadTask } from "../../store/types/fileTypes"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { getItemIdDescription } from "../../store/reducers/item/itemSelectors"
import Column from "../layouts/column/Column"
import { toggleHighlightedItem } from "../../store/reducers/highlight/highlightSlice"
import { selectIsItemHighlighted } from "../../store/reducers/highlight/highlightSelectors"
import OutlineButton from "../atoms/outlinebutton/OutlineButton"
import usePlainReduxUpdateIndex from "../inputs/hooks/plainReduxUpdateIndex"
import { useNavigate } from "react-router-dom"

interface ItemFormProps {
	item: Item;
}

const ItemForm = ({ item }: ItemFormProps) => {

	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const highlighted = useAppSelector((state) => selectIsItemHighlighted(state, item.id))
	
	const onFileUploaded = (uploadedFile: UploadTask) => {
		dispatch( addFileThunk({ ...uploadedFile }) )
	}

	const onHighlighted = () => {
		dispatch( toggleHighlightedItem(item.id) )
	}

	const onDelete = async () => {
		await dispatch( removeItem(item.id) )
		navigate(-1)
	}

	const itemDescription = usePlainReduxUpdateIndex({
		type: 'text',
		entityId: item.id,
		entityType: 'item',
		selector: getItemIdDescription,
		action: setIndexItemDescription,
		
	})

	const { files } = item
	const uploadedFile = files.length > 0 ? files[0] : undefined;

	return (
		<Column>
			<UploadArea onFileUploaded={onFileUploaded} uploadedFile={uploadedFile} canUpload={true} />
			{ uploadedFile ?
			<>
				<TextInput multiRow={true} minRows={1} placeholder="Type your caption" label="Caption" {...itemDescription} />
				<Column alignItems="flex-start" rowGap={'1em'} paddingTop={'48px'}>
					<OutlineButton onClick={onHighlighted} label={highlighted ? "Pinned" : "Pin"} color={highlighted ? 'black' : 'outline' } />
					<OutlineButton onClick={onDelete} label="Delete" color={'outline' } />
				</Column>
			</>
			: <></>}
		</Column>
	)
}

export default ItemForm