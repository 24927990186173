export const darkRed = {
	palette: {
		primary: {
		  light: '#d1432b',
		  main: '#990000',
		  dark: '#650000',
		  contrastText: '#ffffff'
		},
		secondary: {
		  light: '#ff9794',
		  main: '#e06666',
		  dark: '#aa363c',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		// https://meyerweb.com/eric/tools/color-blend/#D42222:000000:1:hex
		frame: {
			col1: '#990000',
			col2: '#e06666',
			mid: '#BD3333',
		},
		pinkOrange: {
		  col1: '#dce775',
		  col2: '#3949ab',
		  mid: '#FF7F54',
		},
	  },
}