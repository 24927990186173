import { LoaderFunctionArgs } from "react-router-dom";
import { logPageview } from "../helpers/AnalyticsHelper";
import Profile from "../pages/profile/Profile";
import ProfileItem from "../pages/profile/ProfileItem";
import ProfileItems from "../pages/profile/ProfileItems";
import { getUserBySlug } from "../store/reducers/publicUser/publicUserActions";
import generateStore from "../store/store";
import { LoaderParams } from "./AppRouter";
import PageContent from "../pages/elements/PageContent";

const ProfileRouter = [
	{
		path: '/profile/:slug',
		element: <PageContent />,
		children: [
			{
				index: true,
				element: <Profile />,
				handle: {
					title: ' ',
				},
				loader: async ({params}: LoaderFunctionArgs) => {
					const slug = params?.slug
					logPageview({
						page_title: 'View Profile',
						page_location: window.location.origin + '/profile/' + slug
					});
					generateStore({}).dispatch( getUserBySlug( slug as string ) )
					return null
				}
			},
			{
				path: "items",
				element: <ProfileItems />,
				handle: {
					title: ' ',
				},
			},
			{
				path: 'items/:itemId',
				element: <ProfileItem />,
				handle: {
					title: ' ',
				},
			},
		]
	}
]

export default ProfileRouter