import { styled } from '@mui/material';
import { keyframes, SxProps } from "@mui/system";
import Button from '@mui/material/Button';
import { CSSProperties, PropsWithChildren, MouseEvent } from 'react';

const nextAnimation = keyframes`
  0%, 50%, 80%, 100% { transform: scale(1); }
  20% { transform: scale(1.2); }
  65% { transform: scale(1.1); }    
`

const FilledStyleButton = styled(Button, {
	shouldForwardProp: prop => prop !== 'btnStyle' && prop !== 'stretch' && prop !== 'animate' && prop !== 'alignSelf' && prop !== 'btnColor' && prop !== 'btnWidth'
})<FilledButtonProps>(({theme, btnColor, btnStyle, size, stretch, animate, alignSelf, btnWidth}) => ({
	...(btnStyle === 'rounded' && { borderRadius: '500px', }),
	...(size === 'large' && {
		...theme.typography.buttonLg
	}),
	...(size === 'medium' && {
		...theme.typography.button
	}),
	...(size === 'small' && {
		...theme.typography.buttonSm
	}),
	backgroundColor: theme.palette.primary.contrastText,
	'&:hover': { 
		backgroundColor: theme.palette.primary.contrastText,
	 },
	 ...(btnColor === 'black' && {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark,
		'&:hover': { 
			backgroundColor: theme.palette.primary.dark,
	 	},
		['&.Mui-disabled']: {
			color: theme.palette.primary.contrastText,
			backgroundColor: theme.palette.grey[400],
		},
	 }),
	 ...(btnColor === 'email' && {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.frame.col1,
		'&:hover': { 
			backgroundColor: theme.palette.primary.light,
	 	},
	 }),
	 ...(!stretch && {
		width: 'fit-content',
		paddingLeft: '0.5em',
		paddingRight: '0.5em',
		// minWidth: '200px',
		alignSelf: alignSelf,
	 }),
	 ...(btnWidth === 'tight' && {
		width: 'fit-content',
		paddingLeft: '0.5em',
		paddingRight: '0.5em',
		// minWidth: '200px',
		alignSelf: alignSelf,
	 }),
	 ...(btnWidth === 'wide' && {
		width: 'fit-content',
		paddingLeft: '2em',
		paddingRight: '2em',
		// minWidth: '200px',
		alignSelf: alignSelf,
	 }),
	...(animate && {
		'&:hover': {
			animation: `${nextAnimation} 300ms linear`,
		},
	}),
}))

type BtnStyles = 'square' | 'rounded';
type BtnColors = 'white' | 'black' | 'email';
type BtnSizes = 'small' | 'medium' | 'large';
type BtnWidth = 'tight' | 'wide' | 'stretch'

interface FilledButtonProps extends PropsWithChildren {
	alignSelf?: CSSProperties['alignSelf'];
	stretch?: boolean;
	btnWidth?: BtnWidth;
	btnStyle?: BtnStyles;
	btnColor?: BtnColors;
	size?: BtnSizes;
	animate?: boolean;
	disabled?: boolean;
	sx?: SxProps;
	onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const FilledButton = ({
	children,
	alignSelf = 'center',
	stretch = true,
	btnWidth = 'tight',
	btnStyle = 'square',
	btnColor = "white",
	size = 'large',
	animate = false,
	onClick,
	...inputProps
}: FilledButtonProps) => {
	return (
		<FilledStyleButton onClick={onClick} stretch={stretch} alignSelf={alignSelf} btnColor={btnColor} btnStyle={btnStyle} btnWidth={btnWidth} size={size} animate={animate} {...inputProps} variant='outlined'>
			{ children }
		</FilledStyleButton>
	)
}

export default FilledButton