import { Card, CardContent, CardMedia, CardMediaProps, Typography, styled } from "@mui/material";
import { Item, ItemID } from "../../../store/types/itemTypes";

interface CardLabelProps {
	item: Item;
}

interface StyledCardProps {
	setHeight: boolean;
}

const StyledCard = styled(Card, {
	shouldForwardProp: prop => prop !== 'setHeight',
})<StyledCardProps>(({theme, setHeight}) => ({
	...(setHeight && { height: '100%' }),
	overflow: 'visible',
}))

const StyledCardContent = styled(CardContent)(({theme}) => ({
	padding: '0.5em 0',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	[theme.breakpoints.down('sm')]: { maxWidth: '200px' },
	[theme.breakpoints.up('sm')]: { maxWidth: '300px' },
}))

const CardLabel = ({item}: CardLabelProps) => {
	return (
		<StyledCardContent>
			<Typography variant="body2">{item.description}</Typography>
		</StyledCardContent>
	)
}

const StyledCardMedia = styled(CardMedia)<CardMediaProps>(({theme}) => ({
	height: '100%',
	objectFit: 'contain',
}))


interface ItemCardProps {
	item: Item;
	showLabels?: boolean;
	bgSrc?: string;
	label?: string;
	sublabel?: string;
	constrainHeight: boolean;
	onClick?: (itemId: ItemID) => void;
}

const ItemCard = ({item, onClick, showLabels = true, constrainHeight}: ItemCardProps) => {

	const handleClick = () => {
		if (onClick) onClick(item.id)
	}

	const firstFile = item.files[0]
	
	return (
		<StyledCard elevation={0} onClick={handleClick} square setHeight={constrainHeight}>
			<StyledCardMedia image={firstFile.fileURL} component={'img'} />
			{ showLabels ? <CardLabel item={item} /> : null }
		</StyledCard>
	)
}

export default ItemCard