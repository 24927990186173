import { createListenerMiddleware } from "@reduxjs/toolkit";
import { createOrUpdateUser, createUserEmailPassword, userLoggedIn, userLogout } from "../reducers/user/userSlice";
import { unSetCustomer } from "../reducers/customer/customerSlice";
import { unSetInvoice } from "../reducers/invoice/invoiceSlice";
import { fetchInvoices, resetInvoices } from "../reducers/invoices/invoicesSlice";
import { fetchCustomers, resetCustomers } from "../reducers/customers/customersSlice";
import { getItemsForCurrentUser } from "../reducers/item/itemSlice";
import { getMessagesForUser } from "../reducers/message/messageActions";
import { getContactsForUser } from "../reducers/contact/contactSlice";
import { getHighlightedItems } from "../reducers/highlight/highlightSlice";

const userMiddleware = createListenerMiddleware()

userMiddleware.startListening({
	actionCreator: userLogout.fulfilled,
	effect: async (_, listenerApi) => {
		listenerApi.dispatch( unSetCustomer() )
		listenerApi.dispatch( unSetInvoice() )
		listenerApi.dispatch( resetInvoices() )
		listenerApi.dispatch( resetCustomers({}) )
	}
})

userMiddleware.startListening({
	actionCreator: userLoggedIn.fulfilled,
	effect: async (_, listenerApi) => {
		listenerApi.dispatch( fetchInvoices() )
		listenerApi.dispatch( fetchCustomers() )
		listenerApi.dispatch( getItemsForCurrentUser() )
		listenerApi.dispatch( getMessagesForUser() )
		listenerApi.dispatch( getContactsForUser() )
		listenerApi.dispatch( getHighlightedItems() )
	}
})

userMiddleware.startListening({
	actionCreator: createUserEmailPassword.fulfilled,
	effect: async (_, listenerApi) => {
		listenerApi.dispatch( createOrUpdateUser() )
	}
})

export default userMiddleware