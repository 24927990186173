import { useEffect } from "react";
import { useLocation } from "react-router-dom"

const ScrollToTop = () => {
	const { pathname } = useLocation();
	console.log('Mounted');
	
	useEffect(() => {
		console.log('Scroll to top');
		
		window.scrollTo({ top: 0, behavior: 'smooth' })
	},[pathname])
	return null;
}

export default ScrollToTop;