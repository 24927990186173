import { Box, Typography, styled } from "@mui/material"
import Slider from "../slider/Slider"
import { Item, ItemID } from "../../../store/types/itemTypes"
import { useNavigate, useLocation } from "react-router-dom"
import ItemCard from "../itemcard/ItemCard"

const StyledBox = styled(Box)(({theme}) => ({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	alignItems: 'flex-start',
	rowGap: '1em',
	paddingTop: '2em',
}))

interface HighlightsProps {
	items: Item[];
}

const Highlights = ({ items }: HighlightsProps) => {

	const navigate = useNavigate()
	const { pathname } = useLocation();

	if (items.length === 0) return (<></>)

	const goToItem = (itemId: ItemID) => {
		const paths: string[] = pathname.split('/')
		const itemsIndex: number = paths.findIndex(path => path === 'items')
		if (itemsIndex === -1) {
			const newPath = paths.concat(['items', itemId]).join('/')
			navigate(newPath)
		} else {
			const newPath = paths.slice(0, -1).concat(itemId).join('/')
			navigate(newPath)
		}
	}

	const slides = items.map((item) => <ItemCard item={item} showLabels={false} onClick={goToItem} constrainHeight={true} />)

	return (
		<StyledBox>
			<Typography variant="h4" textAlign={'left'}>Highlights</Typography>
			<Slider slideNodes={slides} />
		</StyledBox>
	)
}


export default Highlights