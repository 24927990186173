import { v4 as uuidv4 } from 'uuid';

export const isUploadTaskFilter = (i: UploadTask | any): i is UploadTask => i.blobURL;

export type UploadTask = {
	name: string,
	meta: { type: string },
	blobURL: string,
	id: string,
}

export const isUploadedFileFilter = (i: UploadedFile | any): i is UploadedFile => i.fileURL;

export type UploadedFile = {
	name: string,
	meta: { type: string },
	fileURL: string,
	fullPath: string,
	id: string,
}

type CreateUploadedFileInput = {
	id: string;
	name?: string;
	fileType?: string;
	fileURL?: string;
	fullPath?: string;
}
export const createUploadedFile = ({id = uuidv4(), name = '', fileType = '', fileURL = '', fullPath = ''}: CreateUploadedFileInput): UploadedFile => {
    return {
        name: name || '',
        meta: { type: fileType },
        fileURL: fileURL || '',
        fullPath: fullPath || '',
        id: id,
    };
};

export const defaultFile = (): UploadedFile => ({
	meta: { type: '' },
	name: '',
	fileURL: '',
	fullPath: '',
	id: uuidv4(),
});
