import { Stack } from "@mui/material"
import { FORM_PROPS } from "../../helpers/FormProps"
import type { Message } from "../../store/types/messageTypes"
import { useAppDispatch } from "../../store/hooks"
import { setSubject } from "../../store/reducers/message/messageSlice"
import MessageField from "../inputs/MessageField"
import TextInput from "../inputs/fields/TextInput"
import { ChangeEvent } from "react"



interface MessageFormProps {
	message: Message;
	canEdit: boolean;
}

const MessageForm = ({ message, canEdit }: MessageFormProps) => {

	const dispatch = useAppDispatch()

	const handleSubject = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch( setSubject({ messageId: message.id, subject: e.currentTarget.value }) )
	}

	const { fields, subject, id } = message
	
	return (
		<Stack spacing={FORM_PROPS.ROW_SPACING}>
			<TextInput canEdit={canEdit} onChange={handleSubject} value={subject} placeholder="Subject" displayType="message" typography="p" />
			{ fields.map((field, index) => <MessageField messageId={id} canEdit={canEdit} key={`message-field-${index}`} {...field} />) }
		</Stack>
	)
}

export default MessageForm