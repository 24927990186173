import { v4 as uuidv4 } from 'uuid';
import { UploadTask, UploadedFile } from './fileTypes';
import { Branded } from '../../types/brand';
import dayjs from 'dayjs';


export const isItemFilter = (i: Item | any): i is Item => i.type === "Item";

export type ItemID = Branded<string, "ItemID">

export interface Item {
	id: ItemID;
	type: "Item";
	uploads: UploadTask[];
	files: UploadedFile[]; // Specify the type of files array
	description: string;
	title: string;
	amountExclVAT: number;
	currentVAT: number;
	itemVAT: number;
	discount: number;
	itemAmount: number;
	itemNetAmountExclVAT: number;
	created: number;
}

export const defaultItem = ({ id, description }: Partial<Item>): Item => ({
	id: id || uuidv4() as ItemID,
	type: "Item",
	uploads: [],
	files: [],
	title: '',
	description: description || "",
	amountExclVAT: 0,
	currentVAT: 0,
	itemVAT: 0,
	discount: 0,
	itemAmount: 0,
	itemNetAmountExclVAT: 0, // netto ex moms utan rabatt
	created: dayjs().unix(),
});

export const TEST_ITEM = [{
	description: 'TestDescription',
	amountExclVAT: 8000,
	currentVAT: 2000,
	discount: 1000,
	itemAmount: 10000,
	itemNetAmount: 100,
}];
