import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import { saveInvoice } from '../../invoiceActions';
import { fetchInvoice } from './invoiceActions';
import { API_STATUS } from '../../types/storeTypes'
import { AvailablePaymentOptions, PAYMENT_OPTIONS, PaymentOption } from '../../types/invoiceTypes'
import { defaultItem } from "../../types/itemTypes";
import { validateState, clearValidationForParam } from '../../validation/validationTypes';
import { VALIDATE_INVOICE_RULES } from '../../validation/validationRules';
import { invoiceState } from './invoiceState';
import { createOrUpdateInvoice, removeInvoice } from './invoiceActions';
import { recalculateTotal } from './invoiceCalculateActions';


export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: { ...invoiceState() },
  reducers: {
	overrideInvoiceState: (state, action) => {
		return Object.assign({}, state, action.payload)
	},
	unSetInvoice: (state) => {
		state = Object.assign(state, invoiceState())
	},
    saveItem: (state, action) => {
      
      let invoiceCopy = Object.assign({}, action.payload)

      const {
        netAmount,
        totalAmount,
        totalVAT,
        allItems
      } = recalculateTotal(state, invoiceCopy.currentItem.id, 'add')

      //Update state
      state.totalVAT = totalVAT
      state.netAmount = netAmount
      state.totalAmount = totalAmount
      state.currentItem = defaultItem({})
      state.items = allItems
    },
    removeItem: (state, action) => {
		let invoiceCopy = Object.assign({}, state)
		const idToRemove = action.payload
		const {
			netAmount,
			totalAmount,
			totalVAT,
			allItems
		} = recalculateTotal(invoiceCopy, idToRemove, 'remove')
		state.totalVAT = totalVAT
		state.netAmount = netAmount
		state.totalAmount = totalAmount
		state.currentItem = defaultItem({})
		state.items = allItems
    },
    createItem: (state) => {
		const newItem = defaultItem({})
		console.log('creating', newItem);
      	state.currentItem = newItem
    },
    setCurrentItem: (state, action) => {
      const selectedItem = state.items.find(it => it.id === action.payload)
	  if (selectedItem) state.currentItem = selectedItem
    },
    setItemNetAmountExclVAT: (state, action) => {
      state.currentItem.itemNetAmountExclVAT = action.payload
    },
    setDescription: (state, action) => {
      state.currentItem.description = action.payload
    },
    setCurrentVAT: (state, action) => {
      state.currentItem.currentVAT = action.payload
    },
    setDiscount: (state, action) => {
      state.currentItem.discount = action.payload
    },
    setReference: (state, action) => {
      state.reference = action.payload
    },
    setPaymentTerms: (state, action) => {
      const newDate = dayjs.unix(state.invoiceDate).add(action.payload, 'days')
      state.dueDate = newDate.unix()
      state.paymentTerms = action.payload
    },
    setInvoiceDate: (state, action) => {
      const newDate = dayjs.unix(action.payload).add(state.paymentTerms, 'days')
      state.dueDate = newDate.unix()
      state.invoiceDate = action.payload
    },
    setInvoiceNumber: (state, action) => {
      state.invoiceNumber = action.payload
    },
    setCustomerForInvoice: (state, action) => {
      state.customer = action.payload
    },
    setUserForInvoice: (state, action) => {
      state.user = action.payload
    },
    setPaymentOption: (state, action) => {
		const paymentLabel = action.payload.id as AvailablePaymentOptions
      	const paymentOpt: PaymentOption = {
        	enabled: action.payload.value ? true : false,
        	value: action.payload.value,
        	label: PAYMENT_OPTIONS[paymentLabel].label,
      	}
      	state.paymentOptions[paymentLabel] = paymentOpt
    },
    setInvoice: (state, action) => {
      state.items = action.payload.items
      state.totalAmount = action.payload.totalAmount
      state.netAmount = action.payload.netAmount
      state.totalVAT = action.payload.totalVAT
      state.invoiceDate = action.payload.invoiceDate
      state.dueDate = action.payload.dueDate
      state.invoiceNumber = action.payload.invoiceNumber
      state.customer = action.payload.customer
      state.user = action.payload.user
      state.id = action.payload.id
    },
	validateInvoice: (state) => {
		const { stateValid, validation } = validateState(state, VALIDATE_INVOICE_RULES)
		state.stateValid = stateValid
		state.validation = validation
	},
	clearValidation: clearValidationForParam,
	setInvoiceTheme: (state, action) => {
		state.invoiceTheme = action.payload
	},
	setAllPaymentOptions: (state, action) => {
		state.paymentOptions = action.payload
	},
  },
  extraReducers: builder => {
    builder
      .addCase(createOrUpdateInvoice.pending, (state, action) => {
        state.status = API_STATUS.PENDING
      })
      .addCase(createOrUpdateInvoice.fulfilled, (state, action) => {
		if(action.payload.invoice?.id) {
			state.id = action.payload?.invoice?.id
		}
        state.status = API_STATUS.DONE
      })
      .addCase(fetchInvoice.pending, (state, action) => {
        state.status = API_STATUS.PENDING
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
        state.status = API_STATUS.DONE
      })
	  .addCase(saveInvoice.fulfilled, (state, action) => {
        state.id = action.payload
      })
	  .addCase(removeInvoice.pending, (state) => {
		state.status = API_STATUS.PENDING
	  })
	  .addCase(removeInvoice.fulfilled, (state) => {
		state.status = API_STATUS.DONE
	  })
  }
})

type TestTYpe = ReturnType<typeof setItemNetAmountExclVAT>

export const {
	overrideInvoiceState,
	unSetInvoice,
	saveItem,
	removeItem,
	setItemNetAmountExclVAT,
	setCurrentVAT,
	setDiscount,
	createItem,
	setCurrentItem,
	setDescription,
	setInvoiceDate,
	setReference,
	setPaymentTerms,
	setPaymentOption,
	setInvoiceNumber,
	setCustomerForInvoice,
	setUserForInvoice,
	setInvoice,
	validateInvoice,
	clearValidation,
	setInvoiceTheme,
	setAllPaymentOptions,
} = invoiceSlice.actions

export default invoiceSlice.reducer
