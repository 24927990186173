import SettingsForm from "../../components/forms/SettingsForm"
import SaveButtons from "../../components/organisms/savebuttons/SaveButtons"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectUserLoading } from "../../store/reducers/user/userSelectors"
import { createOrUpdateUser } from "../../store/reducers/user/userSlice"



const Settings = () => {

	const dispatch = useAppDispatch()

	const loading = useAppSelector(selectUserLoading)

	const handleSaveSettings = () => {
		dispatch( createOrUpdateUser() )
	}

	return (
		<>
			<SettingsForm />
			<SaveButtons onSave={handleSaveSettings} saveStatus={loading} disabled={false} />
		</>
	)
}


export default Settings