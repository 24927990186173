import { useAppSelector, useAppDispatch } from "../../store/hooks"
import FloatingSaveButton from "../../components/organisms/floatingsavebuttons/FloatingSaveButtons"
import { useEffect, useState } from "react"
import { resetMessage } from "../../store/reducers/message/messageSlice"
import MessageForm from "../../components/forms/MessageForm"
import { selectCurrentMessage } from "../../store/reducers/message/messageSelectors"
import { createOrUpdateMessage, sendMessage, sendMessagePreview } from "../../store/reducers/message/messageActions"
import ContactSelect from "../../components/organisms/contactselect/ContactSelect"

type MessageStates = 'content' | 'contacts' | 'preview' | 'send';

const nextLabel = new Map<MessageStates, string>([
	['content', 'Next'],
	['contacts', 'Next'],
	['preview', 'Send preview'],
	['send', 'Send']
])

const AddEmail = () => {

	const dispatch = useAppDispatch();

	const [messageState, setMessageState] = useState<MessageStates>('content')

	useEffect(() => {
		dispatch( resetMessage() )
	}, [])
	
	const currentMessage = useAppSelector(selectCurrentMessage)

	const handleNextClick = () => {
		if (messageState === 'content') return setMessageState('contacts')
		if (messageState === 'contacts') {
			dispatch( createOrUpdateMessage({}) )
			setMessageState('preview')
		}
		if (messageState === 'preview') {
			dispatch( sendMessagePreview(currentMessage.id) )	
		}
		if (messageState === 'send') {
			dispatch( sendMessage(currentMessage.id) )	
		}
	}

	const handlePrevClick = () => {
		if (messageState === 'content') return
		if (messageState === 'contacts') return setMessageState('content')
		if (messageState === 'preview') return setMessageState('contacts')
		if (messageState === 'send') return setMessageState('preview')
	}

	return (
		<>
			{{
				'content' : <MessageForm canEdit={true} message={currentMessage} />,
				'contacts' : <ContactSelect messageId={currentMessage.id} />,
				'preview' : <MessageForm canEdit={false} message={currentMessage} />,
				'send': <></>,
			}[messageState]}
			{ messageState !== 'content' && <FloatingSaveButton onSave={handlePrevClick} label="Back" position="left" /> }
			<FloatingSaveButton onSave={handleNextClick} label={nextLabel.get(messageState)} />
		</>
	)
}

export default AddEmail