import Row from "../../layouts/row/Row"
import { ApiStatus } from "../../../store/types/storeTypes";
import SaveButton from "../../molecules/savebutton/SaveButton";


interface SaveButtonsProps {
	label?: string;
	onSave: () => void;
	disabled: boolean;
	saveStatus: ApiStatus;	
}

const SaveButtons = ({onSave, saveStatus, disabled, label = 'Save'}: SaveButtonsProps) => {

	return (
		<Row justifyContent="flex-end">
			<SaveButton onSave={onSave} saveStatus={saveStatus} label={label} disabled={disabled} />
		</Row>
	)
}

export default SaveButtons