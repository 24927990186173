import Chip from '@mui/material/Chip'
import { styled } from '@mui/material';
import DeleteIcon from '../icons/DeleteIcon';
import { MouseEvent } from 'react';

interface StyledChipProps {
	selected?: boolean;
	chipStyle?: 'outline' | 'secondary' | 'primary';
	deleteIcon?: JSX.Element;
}

const StyledChip = styled(Chip,{
	shouldForwardProp: (prop) => prop !== 'chipStyle'
})<StyledChipProps>(({theme, selected = false, chipStyle = 'outline', deleteIcon}) => ({
	...theme.typography.button,
	maxWidth: '95vw',
	textOverflow: 'ellipsis',
	backgroundColor: theme.palette.background.default,
	borderColor: theme.palette.primary.dark,
	borderStyle: 'solid',
	borderWidth: '1px',
	'&:hover': { // Add hover styles
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark, // Change background color on hover
		cursor: 'pointer', // Change cursor to pointer on hover
	},
	...(chipStyle === 'secondary' && {
		backgroundColor: theme.palette.grey[300],
		borderColor: theme.palette.grey[300],
		borderStyle: 'solid',
		borderWidth: '1px',
		'&:hover .MuiChip-deleteIcon': { // Add hover styles
			color: theme.palette.primary.light,
			fill: theme.palette.primary.contrastText, // Change background color on hover
			cursor: 'pointer', // Change cursor to pointer on hover
		},
	}),
	...(chipStyle === 'primary' && {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.dark,
		borderColor: theme.palette.primary.dark,
		borderStyle: 'solid',
		borderWidth: '1px',
	}),
	transition: 'background-color 0.3s, color 0.3s',
	'.MuiChip-label': {
		padding: '20px',
		...(deleteIcon && { padding: '10px 5px 10px 15px' }),
	},
	'> svg': {
		marginRight: '6px',
	},
	...(selected && {
		backgroundColor: 'blue',
	}),
}))

interface ChipAtomProps {
	onClick?: () => void;
	label: string;
	link?: string;
	chipStyle?: 'outline' | 'secondary' | 'primary';
	handleDelete?: () => void;
}

const ChipAtom = ({ label, chipStyle, link, onClick, handleDelete }: ChipAtomProps) => {

	const deleteProps = handleDelete ? {
		onDelete: (e: MouseEvent<HTMLDivElement>) => { handleDelete() },
		deleteIcon: <DeleteIcon iconSize={'1.5rem'} iconColor='mediumGrey' />
	} : {}

	return (
		<StyledChip
			{ ...deleteProps }
			onClick={onClick}
			chipStyle={chipStyle}
			label={label}
			/>
	)
}

export default ChipAtom