import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import './App.css';
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SELocale from 'dayjs/locale/sv';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { router } from './router/AppRouter';
import CustomThemeProvider from './helpers/theme/themeProvider';
import AuthProvider from './helpers/auth/AuthProvider';
import generateStore from './store/store';
import ModalProvider from './helpers/ModalProvider';


const App = () => {

	const store = generateStore()

	return (
		<div className="App">
			<div className="AppContent">
				<Provider store={store}>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={SELocale}>
						<CustomThemeProvider>
							<AuthProvider>
								<HelmetProvider>
									<ModalProvider>
										<RouterProvider router={router} />
									</ModalProvider>
								</HelmetProvider>
							</AuthProvider>
						</CustomThemeProvider>
					</LocalizationProvider>
				</Provider>
			</div>
		</div>
  );
}

export default App;
