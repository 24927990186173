import Box from '@mui/material/Box';


function CenterWrap(props){
    const { children } = props;
    return (
      <Box sx={{
        width: {
          s: '100%',
          md: '800px',
        },
        height: '100%',
        display:'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}>
        { children }
      </Box>
    )
  }

  export default CenterWrap