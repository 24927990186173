import { ApiStatus } from "../../types/storeTypes";
import { defaultItem, Item } from "../../types/itemTypes";

interface InitialItemState {
	items: Item[];
	item: Item;
	user: boolean;
	loading: ApiStatus; // Specify the correct type for loading
	error: string;
	status: ApiStatus; // Specify the correct type for status
	stateValid: boolean;
	validation: Record<string, any>; // Specify the correct type for validation
}

const INITIAL_STATE: InitialItemState = {
	items: [],
	item: { ...defaultItem({}) },
	user: false,
	loading: ApiStatus.SUCCESS,
	error: '',
	status: ApiStatus.SUCCESS,
	stateValid: true,
	validation: {},
}

export const itemState = () => INITIAL_STATE