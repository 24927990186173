import { createSlice } from "@reduxjs/toolkit";

export const INITIAL_STATE = {
	showValidationOverlay: false,
	showOverlay: false,
	overlayMsg: '',
	overlayMsgArr: [],
	// overlayMsg: 'Here is what we believe is missing or faulty:\n- your info\n- your info\n',
	validToSend: false,
	validation: { },
}

export const miscSlice = createSlice({
    name: 'misc',
    initialState: {...INITIAL_STATE },
    reducers: {
		clearValidation: (state, action) => {
			/* Used for matching validation  */
			const test = action.payload
		},
		showValidationOverlay: (state) => {
			state.showValidationOverlay = true
		},
		hideValidationOverlay: (state) => {
			state.showValidationOverlay = false
		},
		clearValidationForParam: () =>{ },
        showOverlay: (state, action) => {
            state.showOverlay = true
            state.overlayMsg = action.payload.message
            state.overlayMsgArr = action.payload.messageArr
        },
        hideOverlay: (state, action) => {
            state.showOverlay = false
            state.overlayMsg = ''
			state.overlayMsgArr = []
        },
		initInvoice: (state, action) => { },
    },
    extraReducers: builder => {}
})

export const {
	clearValidationForParam,
	showValidationOverlay,
	hideValidationOverlay,
    showOverlay,
    clearValidation,
    hideOverlay,
	initInvoice,
} = miscSlice.actions

export default miscSlice.reducer