import { v4 as uuidv4 } from 'uuid';
import dayjs from "dayjs"
import { Branded } from "../../../types/brand"
import { ApiStatus } from "../../types/storeTypes";

export const isContactFilter = (i: Contact | any): i is Contact => i.type === 'Contact';

export type ContactID = Branded<string, "ContactID">

export type Contact = {
	id: ContactID;
	name: string;
	email: string;
	createdAt: number;
}

export const defaultContact = ({ email, name }: Partial<Contact>): Contact => {
	if (!email) throw new Error('Email is missing')
	return {
		id: uuidv4() as ContactID,
		email: email,
		name: name || '',
		createdAt: dayjs().unix(),
	}
}


interface InitialContactState {
	contacts: Contact[];
	status: ApiStatus;
}

const INITIAL_STATE: InitialContactState = {
	contacts: [],
	status: ApiStatus.SUCCESS,
}

export const contactState = () => INITIAL_STATE