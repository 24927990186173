import { logPageview } from "../helpers/AnalyticsHelper"
import About from "../pages/pages/About"
import ComingSoon from "../pages/pages/ComingSoon"
import Home from "../pages/pages/Home"
import WtsHome from '../pages/pages/WtsHome'
import Team from "../pages/Team"
import TestPage from "../pages/pages/Test"
import Why from "../pages/pages/Why"

const PagesRouter = [
	{
		path: '/',
		element: <WtsHome />,
		loader: () => { logPageview({
			page_title: 'Home',
			page_location: window.location.origin + '/'
		}); return null },
	},
	{
		path: '/why',
		element: <Why />,
	}
	// {
	// 	path: "/about",
	// 	element: <About />,
	// 	loader: () => { logPageview({
	// 	  page_title: 'About',
	// 	  page_location: window.location.origin + '/about'
	// 	}); return null },
	// },
]

export default PagesRouter