import React from 'react'
import { Paper, PaperProps, styled } from "@mui/material"
import UploadComponent from "./UploadComponent"
import AddIcon from '@mui/icons-material/Add'
import { UploadedFile } from "../../store/types/fileTypes";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/reducers/reducers";
import { selectFileFromMessage } from "../../store/reducers/message/messageSelectors";
import RemoveButton from "../buttons/RemoveButton";

interface StyledPaperProps extends PaperProps {
	bgImage?: string; 
}

const StyledPaper = styled(Paper, {
	shouldForwardProp: prop => prop !== 'bgImage'
})<StyledPaperProps>(({bgImage = false}) => ({
	position: 'relative',
	width: '100%',
	height: '300px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	...(bgImage && {
		backgroundImage: `url(${bgImage})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	})
}))

interface MessageUploadProps {
	onFileRemove: (id: string) => void;
	onFileUploaded: (e: any) => void;
	uploadedFile?: UploadedFile;
}

const MessageUpload = ({ onFileUploaded, onFileRemove, uploadedFile }: MessageUploadProps) => {

	const fileId = uploadedFile?.id || ''
	const file = useAppSelector((state: RootState) => selectFileFromMessage(state, fileId))
	const fileSrc = file?.fileURL

	const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onFileRemove(fileId)
	}

	return (
		<UploadComponent onUploaded={onFileUploaded}>
			<StyledPaper elevation={1} square bgImage={fileSrc}>
				{ file && <RemoveButton handleRemove={handleRemove}/> }
				{ !file && <AddIcon sx={{ width: '75%', height: '75%' }}/> }
			</StyledPaper>
		</UploadComponent>
	)
}

export default MessageUpload