import SvgIconWrapper, { IconSize, SvgIconWrapperProps } from "./SvgIconWrapper";

interface AddIconProps extends SvgIconWrapperProps {}

const AddIcon = ({ iconSize = 'large', iconColor, onClick }: AddIconProps) => {
	return (
		<SvgIconWrapper iconSize={iconSize} iconColor={iconColor} onClick={onClick}>
			<svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#e8eaed"><path d="M460-300h40v-160h160v-40H500v-160h-40v160H300v40h160v160Zm20.13 180q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
		</SvgIconWrapper>
	)
}

export default AddIcon