import { configureStore } from '@reduxjs/toolkit'
import formValidationMiddleware from './middlewares/validationMiddleware'
import initInvoiceMiddleware from './middlewares/initInvoiceMiddleware'
import invoicesMiddleware from './middlewares/invoicesMiddleware'
import customersMiddleware from './middlewares/customersMiddleware'
import { rootReducer } from './reducers/reducers'
import userMiddleware from './middlewares/userMiddleware'
import invoiceMiddleWare from './middlewares/invoiceMiddleware'
import itemMiddleWare from './middlewares/itemMiddleware'
import publicUserMiddleware from './middlewares/publicUserMiddleware'

let store: any;

const generateStore = (preloadedState: any) => {
	console.log('GENERATING STORE');
	
	if (store) return store
	store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultmiddleware) =>
		getDefaultmiddleware()
		.concat([
			userMiddleware.middleware,
			formValidationMiddleware.middleware,
			initInvoiceMiddleware.middleware,
			invoicesMiddleware.middleware,
			customersMiddleware.middleware,
			invoiceMiddleWare.middleware,
			itemMiddleWare.middleware,
			publicUserMiddleware.middleware,
		]),
		preloadedState,
	})	
	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('./reducers/reducers', () => {
			if (store) store.replaceReducer(rootReducer)
		})
		window.store = store
	}

	return store
}

// const testStore = (initialState: any) => configureStore({
// 	reducer: testRootReducer,
// 	middleware: getDefaultMiddleware => getDefaultMiddleware()
// 	.concat([
// 		formValidationMiddleware.middleware,
// 		initInvoiceMiddleware.middleware,
// 		invoicesMiddleware.middleware,
// 		customersMiddleware.middleware,
// 		invoiceMiddleWare.middleware,
// 	]),
// 	preloadedState: initialState,
// })

const clearStore = () => {
	store = undefined;
}

export {
	// testStore,
	store,
	clearStore,
}

// https://stackoverflow.com/questions/61976204/how-to-get-the-appdispatch-typescript-type-in-redux-toolkit-when-store-is-initia
export type AppDispatch = ReturnType<typeof generateStore>["dispatch"]
// export type AppDispatch = typeof testStore.dispatch


export default generateStore
