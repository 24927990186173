import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormWrapper from '../../components/deprecated/FormWrapper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { signInAnon, signInLink, verifySingInLink } from '../../helpers/auth/Authentication';
import { signOut } from 'firebase/auth';
import { auth } from '../../Firebase';


function VerifyLink() {
  
  useEffect(() => {
    console.log('verifying');
    verifySingInLink()
  }, [])

  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirect')
  
  const proceedToApp = () => {
    navigate( redirectTo )
  }

  // const 
  return (
    <FormWrapper heading="Account verified!">
      <Box
        sx={{backgroundColor: 'white',}}
        padding='20px'
        >
          <Typography>Your email is verified!</Typography>
          <Button onClick={proceedToApp}>Back to app</Button>

      </Box>
    </FormWrapper>
  );
}

export default VerifyLink;