import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../reducers"
import { getEntityByID, removeEntityById, saveEntityForUser, saveOrCreateEntity } from "../../utils/firebaseUtils"
import { convertToInvoiceObject } from "../../types/invoiceTypes"
import { Invoice } from "../../types/invoiceTypes"


export const createOrUpdateInvoice = createAsyncThunk(
	'invoice/create',
	async (args, thunkAPI) => {
		try {
			const { invoice, user, customer } = thunkAPI.getState() as RootState
			let invoiceToSave: Invoice = convertToInvoiceObject(invoice)
			invoiceToSave.user = user.id
			invoiceToSave.customer = customer.id
			const result = await saveOrCreateEntity('invoices', invoiceToSave, user.id)
			if (!result.saved) {
				throw new Error('Failed to save invoice')
			}
			const savedInvoice = result.entity as Invoice
			await saveEntityForUser('invoice', savedInvoice.id, user.id)
			return {
				saved: true,
				invoice: savedInvoice
			}
		} catch (error: any) {
			return {
				saved: false,
				error: error.message
			}
		}
	}
)

type FetchInvoiceId = string;
export const fetchInvoice = createAsyncThunk<Invoice, FetchInvoiceId>(
	'invoice/fetch',
	async (invoiceId): Promise<Invoice> => {
		try {
			const invoice = await getEntityByID('invoices', invoiceId) as Invoice
			return invoice
		} catch (error) {
			throw error;
		}
	}
)

type RemoveInvoiceId = string;

export const removeInvoice = createAsyncThunk<RemoveInvoiceId, RemoveInvoiceId>(
	'invoice/remove',
	async (args) => {
	try {
		const invoiceId = args;
		await removeEntityById('invoices', invoiceId)
		return invoiceId
	} catch (error) {
		throw error
	}
})