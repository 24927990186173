import { Stack } from "@mui/material"
import { PropsWithChildren, CSSProperties } from "react"

interface RowProps extends PropsWithChildren {
	alignItems?: CSSProperties['alignItems'];
	justifyContent?: CSSProperties['justifyContent'];
	columnGap?: CSSProperties['columnGap'];
	paddingTop?: CSSProperties['paddingTop'];
}

const Row = ({alignItems = 'center', justifyContent = 'space-between', paddingTop, columnGap, children}: RowProps) => {
	return (
		<Stack display={'flex'} paddingTop={paddingTop} flexDirection={'row'} alignItems={alignItems} justifyContent={justifyContent} sx={{width: '100%'}} columnGap={columnGap}>
			{children}
		</Stack>
	)
}

export default Row