import type { Themes } from "../../../helpers/theme/themeFactory";
import { ApiStatus, Avatar, DEFAULT_AVATAR } from "../../types/storeTypes"
import { PAYMENT_OPTIONS, PaymentOptions } from "../../types/invoiceTypes";
import { Branded } from "../../../types/brand";
import { v4 as uuid } from "uuid";

export type QuickLinkID = Branded<string, 'QuickLinkID'>

export type QuickLink = {
	id: QuickLinkID;
	label: string;
	link: string;
}

export interface User {
	id: string;
	companyName: string;
	slug: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	email: string;
	VATnumber: string;
	ORGnumber: string;
	links: QuickLink[];
	bio: string;
	profession: string;
	customers: string[];
	invoices: string[];
	defaultTheme: Themes;
	avatar: Avatar;
	defaultPaymentOptions: PaymentOptions;
}

interface InitialUserState extends User {
	status: ApiStatus;
	userFetchStatus: ApiStatus;
	uploading: boolean;
	stateValid: boolean;
	validation: any;
}

export const INITIAL_USER_STATE: InitialUserState = {
	companyName: '',
	slug: '',
	address: '',
	postalCode: '',
	city: '',
	country: 'SE',
	email: '',
	VATnumber: '',
	ORGnumber: '',
	links: [],
	bio: '',
	profession: '',
	avatar: { ...DEFAULT_AVATAR },
    id: '',
	customers: [], // Private
	invoices: [], // Private
	defaultPaymentOptions: { ...PAYMENT_OPTIONS },
	defaultTheme: 'pinkOrange',
	status: ApiStatus.SUCCESS, // Don't save
	userFetchStatus: ApiStatus.SUCCESS, // Don't save
	uploading: false, // Don't save
	stateValid: true, // Don'' save
	validation: {}, // Don't save
}

export const generateUser = ({ id, email }: { id: string, email: string | null }): User => ({
	id: id,
	companyName: '',
	slug: uuid(),
	address: '',
	postalCode: '',
	city: '',
	country: '',
	email: email || '',
	VATnumber: '',
	ORGnumber: '',
	links: [],
	bio: '',
	profession: '',
	customers: [],
	invoices: [],
	defaultTheme: 'pinkOrange',
	avatar: { ...DEFAULT_AVATAR },
	defaultPaymentOptions: { ...PAYMENT_OPTIONS },
})

export const getUserToSave = (state: InitialUserState) => {
	const { validation, stateValid, uploading, status, ...userToSave } = state
	return userToSave
}