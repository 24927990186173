import { createListenerMiddleware } from "@reduxjs/toolkit";
import { fetchInvoice } from "../reducers/invoice/invoiceActions";
import { getCustomer } from "../reducers/customer/customerSlice";
import { getUserById } from "../reducers/publicUser/publicUserActions";

const invoiceMiddleWare = createListenerMiddleware()

invoiceMiddleWare.startListening({
	actionCreator: fetchInvoice.fulfilled,
	effect: async (action, listenerApi) => {
		const { invoice } = listenerApi.getState()
		listenerApi.dispatch( getCustomer(invoice.customer) )
		listenerApi.dispatch( getUserById( invoice.user) )
	}
})

export default invoiceMiddleWare