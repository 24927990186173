import { PropsWithChildren, createContext, useState } from "react"

interface ModalContextType {
	shownFollow: string[];
	dismissFollow: (profileId: string) => void;
	shouldShowFollow: (profileId: string) => boolean;
}

export const ModalContext = createContext<ModalContextType>({
	shownFollow: [],
	dismissFollow: (profileId: string) => {},
	shouldShowFollow: (profileId: string) => false,
})

interface ModalProviderProps extends PropsWithChildren {}

const ModalProvider = ({ children }: ModalProviderProps) => {

	const storage = localStorage.getItem('shownFollow')
	const shownFollowLocalStorage: string[] = storage ? JSON.parse(storage) : []

	const [_shownFollow, _setShowFollow] = useState(shownFollowLocalStorage)

	const _dismissedFollow = (profileId: string) => {
		const valToSave = _shownFollow.concat(profileId)
		localStorage.setItem('shownFollow', JSON.stringify(valToSave))
		_setShowFollow(valToSave)
	}

	const _shouldShowFollow = (profileId: string) => {
		if (!profileId) return false
		return _shownFollow.includes(profileId) ? false : true
	}

	const contextValue = {
		shownFollow: _shownFollow,
		dismissFollow: (profileId: string) => _dismissedFollow(profileId),
		shouldShowFollow: (profileId: string) => _shouldShowFollow(profileId),
	}

	return(
		<ModalContext.Provider value={contextValue}>
			{ children }
		</ModalContext.Provider>
	)
}

export default ModalProvider