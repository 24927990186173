import { createRef, forwardRef, useEffect } from 'react'
import Link from '@mui/material/Link';
import { useMatches, useNavigate } from 'react-router-dom';
import { Typography, styled } from '@mui/material';
import { Transition, TransitionStatus } from 'react-transition-group';
import { useScrollDirection } from '../../../helpers/ScrollDirection';

const TRANSITION_STYLES = {
	entering: { opacity: 1 },
	entered:  { opacity: 1 },
	exiting:  { opacity: 0 },
	exited:  { opacity: 0 },
	unmounted: { opacity: 0},
};

const AnimatedTypography = styled(Typography)<TextTitleProps>(({ state }) => ({
	transition: `opacity 300ms ease-in-out`,
	position: 'absolute',
	marginLeft: 'auto',
	marginRight: 'auto',
	width: '150px',
	left: 0,
	right: 0,
	textAlign: 'center',
	...TRANSITION_STYLES[state],
}))

interface TextTitleProps {
	navTitle?: string;
	state: TransitionStatus;
}

type Ref = HTMLElement;

const TextTitle = forwardRef<Ref, TextTitleProps>((props, ref) => {
	const { navTitle, state, ...compProps } = props
	const variant = navTitle === 'PEJME' ? 'logo' : 'h4'
	return(
		<AnimatedTypography state={state} variant={variant} ref={ref}>{navTitle}</AnimatedTypography>
	)
})

type MatchType = {
	handle: {
		title?: string;
	}
}

const Navmid = () => {
	
	const matches = useMatches()
	const { handle } = matches[matches.length - 1] as MatchType
	const title = handle && handle?.title || 'PEJME'

	useEffect(() => {
		if (title) {
			document.title = `${title} | Invoices made easy`
		}
	}, [title])
	
	
	const navigate = useNavigate();
	const { direction, topPosition } = useScrollDirection({ threshold: 30 })
	const nodeRef = createRef<Ref>()

	const inProp = title && direction === 'DOWN' ? true : false
	
	return(
		<Link onClick={() => navigate('/')} sx={{
			cursor: 'pointer',
			justifySelf: 'center',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			}}>
			<TextTitle navTitle={title} state={'entered'} />
			{/* <Transition nodeRef={nodeRef} in={inProp} timeout={500}>
				{state => (
					<TextTitle ref={nodeRef} navTitle={title} state={state} />
				)}
			</Transition>
			<Transition nodeRef={nodeRef} in={!inProp} timeout={500}>
				{state => (
					<TextTitle ref={nodeRef} navTitle={'PEJME'} state={state} />
				)}
			</Transition> */}
		</Link>
	)
}

export default Navmid