import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { logButtonClick, logCustomEvent } from '../../helpers/AnalyticsHelper';
import { styled } from '@mui/material/styles';
import CenterWrap from '../../components/deprecated/CenterWrap';
import FilledButton from '../../components/buttons/FilledButton';
import FluidBackgroundBox from '../../components/deprecated/FluidBackgroundBox';

const FireEmoji = styled('img')({
	width: '1.8em',
	height: '1.8em',
	marginLeft: '-0.3em',
	marginRight: '-0.3em',
	marginTop: '-1.2em',
	verticalAlign: 'bottom',
})

const WtsHome = () => {

	const navigate = useNavigate();

	const handleStart = (origin: string) => {
		logButtonClick({ buttonName: 'Start', buttonPlacement: origin })
		logCustomEvent({ eventName: 'StartInvoice'})
		navigate('/admin')
	}

	return (
		<Fragment>
		<FluidBackgroundBox sx={{height: '70vh'}}>
			<CenterWrap>
				<Stack spacing={4} sx={{
					display: 'flex',
					width: '100%',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '60px',
					padding: '20px',
					borderRadius: '5px',
					marginBottom: '60px'}}
					>
					<Typography
						variant='h1'
						sx={{color: 'white', textAlign: 'center', fontSize: { xs: '2.5em', sm: '3em', md: '3em', lg: '4em' }, fontWeight: 800 }}
						>Share your<br />super-<FireEmoji src={`${process.env.PUBLIC_URL}/fire.gif`} /> portfolio!
					</Typography>
					<Box sx={{ width: '100%', maxWidth: { sm: '400px' }}}>
						<Stack spacing={1}>
							<FilledButton
								size='large'
								btnColor="white"
								btnStyle='rounded'
								btnWidth='wide'
								// sx={{width: '100%', maxWidth: { sm: '400px' } }}
								onClick={() => handleStart('HeroButton')}>
									Try it out!
							</FilledButton>
						</Stack>
					</Box>
				</Stack>
			</CenterWrap> 
		</FluidBackgroundBox>
		<Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', }}>
			<CenterWrap>
				<Stack spacing={4} sx={{padding: '20px', paddingTop: '60px', paddingBottom: '60px', backgroundColor: 'white'}}>
					<Typography variant='h2' sx={{textAlign:'left'}}>Build a beautiful portfolio in minutes and capture new customers.</Typography>
					<Typography variant="p">
						A light-version of the real world. We provide features you'll need to facilitate growing business in the digital domain. We offer only the minimum, but that means that it is also easier to understand, faster and more affordable.
					</Typography>
					<FilledButton
						onClick={() => handleStart('BottomButton')}
						btnColor="black"
						btnStyle='rounded'
						size='large'
						sx={{
							alignSelf: 'center',
							width: '100%',
							maxWidth: { sm: '400px' },
						}}
						>
						Try it out!
					</FilledButton>
				</Stack>
			</CenterWrap>
		</Box>
		</Fragment>
	)
}

export default WtsHome;