import { useNavigate } from "react-router-dom"
import IdCardIcon from "../../atoms/icons/IdCardIcon"
import { useAppSelector } from "../../../store/hooks"
import { selectUserSlug } from "../../../store/reducers/user/userSelectors"
import { AuthContext } from "../../../helpers/auth/AuthProvider"
import { useContext, MouseEvent } from "react"
import { Box, styled } from "@mui/material"
import { selectHasCompleteProfile } from "../../../store/utils/compoundSelectors"

const StyledBox = styled(Box)(({}) => ({
	padding: '0.5em',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}))

const ProfileButton = () => {

	const navigate = useNavigate()
	const { authenticated } = useContext(AuthContext)
	const userSlug = useAppSelector(selectUserSlug)
	const showProfile = useAppSelector(selectHasCompleteProfile)

	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		navigate(`/profile/${userSlug}`)
	}
	if (!authenticated) return <></>
	if (!showProfile) return <></>
	return (
		<StyledBox onClick={handleClick}>
			<IdCardIcon iconSize="1.5em" iconColor="black" />
		</StyledBox>
	)
}

export default ProfileButton