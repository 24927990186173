import { Themes } from '../../helpers/theme/themeFactory';
import { Currency } from '../../helpers/MoneyHelper';
import { Item } from "./itemTypes";
import { InitialInvoiceState } from '../reducers/invoice/invoiceState';


export interface Invoice {
	id: string;
	items: Item[];
	totalAmount: number;
	netAmount: number;
	paymentOptions: PaymentOptions;
	totalVAT: VATType;
	reference: string;
	paymentTerms: number;
	invoiceDate: number;
	dueDate: number;
	invoiceNumber: string;
	currency: Currency;
	invoiceURL: string;
	customer: string;
	user: string;
	invoiceTheme: Themes;
}

export type VATRateType = 0 | 6 | 12 | 25 | 'total'

export type VATType = {
	0: number,
	6: number,
	12: number,
	25: number,
	'total': number,
  }

  export const DEFAULT_VAT: VATType = {
    '0': 0,
    '6': 0,
    '12': 0,
    '25': 0,
    'total': 0,
  };
  

export type AvailablePaymentOptions = 'Bankgiro' | 'Swish' | 'BankTransferName' | 'BankTransferClearing' | 'BankTransferAccount'

export interface PaymentOption {
	enabled: boolean;
	value: string;
	label: string;
}
  
export interface PaymentOptions {
	Bankgiro: PaymentOption;
	Swish: PaymentOption;
	BankTransferName: PaymentOption;
	BankTransferClearing: PaymentOption;
	BankTransferAccount: PaymentOption;
}

export const PAYMENT_OPTIONS: PaymentOptions = {
	Bankgiro: { enabled: false, value: '', label: 'Bankgiro' },
	Swish: { enabled: false, value: '', label: 'Swish' },
	BankTransferName: { enabled: false, value: '', label: 'Bank' },
	BankTransferClearing: { enabled: false, value: '', label: 'Clearing' },
	BankTransferAccount: { enabled: false, value: '', label: 'Account' },
}

export const convertToInvoiceObject = (invoiceState: InitialInvoiceState): Invoice => {
    const {
		id,
        items,
        totalAmount,
        netAmount,
        paymentOptions,
        totalVAT,
        reference,
        paymentTerms,
        invoiceDate,
        dueDate,
        invoiceNumber,
        currency,
        invoiceURL,
        customer,
        user,
        invoiceTheme
    } = invoiceState;

    // Construct and return the Invoice object
    return {
		id,
        items,
        totalAmount,
        netAmount,
        paymentOptions,
        totalVAT,
        reference,
        paymentTerms,
        invoiceDate,
        dueDate,
        invoiceNumber,
        currency,
        invoiceURL,
        customer,
        user,
        invoiceTheme
    };
}