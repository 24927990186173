import { grey } from '@mui/material/colors';

export const components = {
	components: {
		MuiTextField: {
		  styleOverrides: {
			root: {
			  '&.frontPage': {
				'&>label': {
				  color: 'white',
				},
				'&>div>input': {
				  color: 'white',
				},
			  }
			}
		  },
		},
		MuiButton: {
		  styleOverrides: {
			root: {
			  textTransform: 'none',
			}
		  },
		  variants: [
			{
			  props: { variant: 'white' },
			  style: {
				backgroundColor: 'white',
				color: 'black',
				'&:hover': {
				  backgroundColor: 'black',
				  color: 'white',
				}
			  }
			},
			{
				props: { variant: 'black' },
				style: {
				  backgroundColor: 'black',
				  color: 'white',
				  '&:hover': {
					backgroundColor: 'white',
					color: 'black'
				  }
				}
			}
		  ]
		},
		// MuiInputLabel: {
		//   styleOverrides: {
		// 	root: {
		// 	  "&.Mui-focused":{
		// 		color: "#ff9300"
		// 	  },
		// 	  "&.Mui-error":{
		// 		color: "#d32f2f"
		// 	  },
		// 	}
		//   }
		// },
		MuiInput: {
		  styleOverrides: {
			// underline: {
			//   "&:after": {
			// 	borderBottomColor: "#ff9300",
			//   }
			// },
			root: {
			  letterSpacing: '0.08em',
			}
		  }
		},
		MuiTableCell: {
		  styleOverrides: {
			root: {
			  backgroundColor: grey[100],
			  borderBottom: 0,
			}
		  },
		  variants: [
			{
			  props: { variant: 'white' },
			  style: {
				backgroundColor: 'inherit',
			  }
			}
		  ]
		},
	  },
}