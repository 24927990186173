import {
	createSlice,
	createAsyncThunk
} from '@reduxjs/toolkit'
import {
	collection,
	getDocs,
	query,
	where,
} from 'firebase/firestore'
import { db } from '../../../Firebase'
import { ApiStatus, Customer } from '../../types/storeTypes'
import { RootState } from '../reducers';

interface CustomersInitialState {
	customers: Customer[];
	status: ApiStatus;
}

const INITIAL_STATE: CustomersInitialState = {
	customers: [],
	status: ApiStatus.SUCCESS,
}

export const fetchCustomers = createAsyncThunk('customers/get', async (args, thunkAPI) => {
	try {
		const { user } = thunkAPI.getState() as RootState
		const customersRef = collection(db, 'customers')
		const q = query(customersRef, where('user', '==', user.id ))
		const snapshot = await getDocs(q)
		let customers: Customer[] = [];
		snapshot.forEach(doc => customers.push({ id: doc.id, ...doc.data() } as Customer))
		return customers
	} catch (error) {
		console.log('ERROR FETCHING CUSTOMERS');
		console.log(error);
		return [];
	}
})


export const customersSlice = createSlice({
	name: 'customers',
	initialState: { ...INITIAL_STATE },
	reducers: {
		customerRemoved: (state, action) => {
			state.customers = state.customers.filter((item) => item.id !== action.payload.removedId )
		},
		resetCustomers: (state, action) => Object.assign({}, INITIAL_STATE)
	},
	extraReducers: builder => {
		builder
			.addCase(fetchCustomers.pending, (state, action) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(fetchCustomers.fulfilled, (state, action) => {
				state.customers = action.payload
				state.status = ApiStatus.SUCCESS
			})

	}
})

export const {
	customerRemoved,
	resetCustomers,
} = customersSlice.actions

export default customersSlice.reducer