import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ItemID } from "../../../store/types/itemTypes";

type ItemActionPayloadString = {
	value: string;
	id: ItemID;
}
type AnyActionPayloadString = {
	value: string;
	id: string;
}
type ItemActionPayloadNumber = {
	value: number;
	id: ItemID;
}
type AnyActionPayloadNumber = {
	value: number;
	id: string;
}

type StringActionPayload<T> =
	T extends 'item' ? ItemActionPayloadString :
    T extends 'message' ? AnyActionPayloadString :
	never;

type NumberActionPayload<T> =
	T extends 'item' ? ItemActionPayloadNumber :
    T extends 'message' ? AnyActionPayloadNumber :
	never;

interface SimpleHookInputNumber<T extends 'item' | 'message'> {
	type: 'number';
	entityId: string;
	entityType: T;
	selector: any;
	action: ActionCreatorWithPayload<NumberActionPayload<T>, string>;
}

interface SimpleHookInputText<T extends 'item' | 'message'> {
	type: 'text';
	entityId: string;
	entityType: T;
	selector: any;
	action: ActionCreatorWithPayload<StringActionPayload<T>, string>;
}

interface SimpleHookNumber {
	onChange: OnChangeEvent;
	handleChange: OnChangeNumber;
	value: number;
}

interface SimpleHookText {
	onChange: OnChangeEvent;
	handleChange: OnChangeText
	value: string;
}

type OnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => void;
type OnChangeNumber = (value: number) => void;
type OnChangeText = (value: string) => void;
type OnChangeParam = React.ChangeEvent<HTMLInputElement> | number | string;

function usePlainReduxUpdateIndex({type, entityId, entityType, action, selector}: SimpleHookInputText<'item'>): SimpleHookText;
function usePlainReduxUpdateIndex({type, entityId, entityType, action, selector}: SimpleHookInputText<'message'>): SimpleHookText;
function usePlainReduxUpdateIndex({type, entityId, entityType, action, selector}: SimpleHookInputNumber<'item'>): SimpleHookNumber;
function usePlainReduxUpdateIndex({type, entityId, entityType, action, selector}: SimpleHookInputNumber<'message'>): SimpleHookNumber;
function usePlainReduxUpdateIndex<T>({type, entityId, entityType, action, selector}: SimpleHookInputText<'item' | 'message'> | SimpleHookInputNumber<'item' | 'message'>): SimpleHookText | SimpleHookNumber  {
	const dispatch = useAppDispatch();
	const value = useAppSelector((state) => selector(state, entityId))
	
	const onChange: OnChangeEvent = (event) => {
		if (type !== 'text') throw new Error('Missing type')
		const idOfEntity = entityType === 'item' ? entityId as ItemID : entityId
		const payloadValue = event.currentTarget.value as string
		const payload = {
			id: idOfEntity,
			value: payloadValue,
		}
		dispatch( action(payload) )
	}
	
	const handleChangeNumber: OnChangeNumber = (value) => {
		const idOfEntity = entityType === 'item' ? entityId as ItemID : entityId
		const payload = {
			id: idOfEntity,
			value,
		}
		if(type === 'number') dispatch( action(payload))
	}

	const handleChangeText: OnChangeText = (value) => {
		const idOfEntity = entityType === 'item' ? entityId as ItemID : entityId
		const payload = {
			id: idOfEntity,
			value,
		}
		if(type === 'text') dispatch( action(payload))
	}

	if (type == 'text') {
		return {
			onChange,
			handleChange: handleChangeText,
			value: value as string,
		}
	}else {
		return {
			onChange,
			handleChange: handleChangeNumber,
			value: value as number,
		}
	}
}

export default usePlainReduxUpdateIndex