import {
  createSlice,
  createAsyncThunk,
 } from '@reduxjs/toolkit'
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  deleteDoc,
  arrayUnion,
} from "firebase/firestore"; 
import { db } from '../../../Firebase';
import { clearValidationForParam, validateState } from '../../validation/validationTypes';
import { VALIDATE_CUSTOMER_RULES } from '../../validation/validationRules';
import { customerState } from './customerState';
import { API_STATUS } from '../../types/storeTypes';
import { getEntityByID, removeEntityById } from '../../utils/firebaseUtils';

const getCustomerToSave = (customerSlice) => {
	const { status, stateValid, validation, ...customerToSave } = customerSlice
	return customerToSave
}

const getPublicFields = (customerSlice) => {
	const { status, ...publicCustomer } = customerSlice
	return publicCustomer
}

const USE_STATE = customerState(process.env.NODE_ENV)

export const createOrUpdateCustomer = createAsyncThunk('customer/create', async (args, thunkAPI) => {
  try {
    const { customer, user } = thunkAPI.getState()
	let { id, ...customerToSave } = getCustomerToSave(customer)
	console.log('Saving customer', id, customerToSave, 'for user:', user.id);
    customerToSave.user = user.id
    if (id) {
      await updateDoc(doc(db, 'customers', id), customerToSave)
	  customerToSave.id = id
    } else {
      const { id: savedId } = await addDoc(collection(db, 'customers'), customerToSave)
	  customerToSave.id = savedId
    }
	const res = await setDoc(doc(db, 'entitiesForUser', user.id), { customers: arrayUnion(customerToSave.id) }, { merge: true })
    return customerToSave
  } catch (e) {
    console.log('ERROR SAVING CUSTOMER');
    console.log(e);
    console.log('----');
  }
})

export const getCustomer = createAsyncThunk('customer/get', async (customerId, thunkAPI) => {
  try {
	const customer = await getEntityByID('customers', customerId)
    return customer
  } catch (error) {
    console.log(error);
	return {}
  }
})

export const removeCustomer = createAsyncThunk('customer/remove', async (customerId, thunkAPI) => {

	try {
		const resp = await removeEntityById('customers', customerId)
		return resp
	} catch (error) {
		console.log(error)
		return {}
	}
})

export const customerSlice = createSlice({
  name: 'customer',
  initialState: { ...USE_STATE },
  reducers: {
	overrideCustomerState: (state, action) => {
		return Object.assign({}, state, action.payload)
	},
	unSetCustomer: (state) => {
		state = Object.assign(state, USE_STATE)
	},
	setCustomer: (state, action) => {
		state = Object.assign(state, action.payload)
	},
    setCustomerCompanyName: (state, action) => {
      state.companyName = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
    setCity: (state, action) => {
      state.city = action.payload
    },
    setAddress: (state, action) => {
      state.address = action.payload
    },
    setPostalCode: (state, action) => {
      state.postalCode = action.payload
    },
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setORGnumber: (state, action) => {
      state.ORGnumber = action.payload
    },
	validateCustomer: (state) => {
		const { stateValid, validation } = validateState(state, VALIDATE_CUSTOMER_RULES)
		state.stateValid = stateValid
		state.validation = validation
	},
	clearValidation: clearValidationForParam,
  },
  extraReducers: builder => {
    builder
      .addCase(createOrUpdateCustomer.pending, (state, action) => {
        state.status = API_STATUS.PENDING;
      })
      .addCase(createOrUpdateCustomer.fulfilled, (state, action) => {
        console.log('create or update payload', action.payload);
        state.id = action.payload.id;
        state.status = API_STATUS.DONE;
      })
      .addCase(getCustomer.pending, (state, action) => {
        state.status = API_STATUS.PENDING
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
		console.log('get customer', action.payload);
        Object.assign(state, action.payload)
        state.status = API_STATUS.DONE
      })
	  .addCase(removeCustomer.pending, (state, action) => {
		state.status = API_STATUS.PENDING
	  })
	  .addCase(removeCustomer.fulfilled, (state, action) => {
		state.status = API_STATUS.DONE
	  })
  }
})

export const {
	overrideCustomerState,
	unSetCustomer,
	setCustomer,
	setCustomerCompanyName,
	setEmail,
	setCity,
	setAddress,
	setPostalCode,
	setCountry,
	setORGnumber,
	validateCustomer,
	clearValidation,
} = customerSlice.actions

export default customerSlice.reducer
