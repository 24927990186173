import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./publicUserState";
import { getUserById, getUserBySlug } from "./publicUserActions";
import { ApiStatus } from "../../types/storeTypes";

export const publicUserSlice = createSlice({
	name: 'publicUser',
	initialState: { ...INITIAL_STATE },
	reducers: {
		resetPublicUser: (state, action) => {
			return Object.assign({}, INITIAL_STATE)
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserBySlug.pending, (state) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(getUserBySlug.fulfilled, (state, action) => {
				state = Object.assign(state, action.payload, { status: ApiStatus.SUCCESS})
			})
			.addCase(getUserBySlug.rejected, (state) => {
				state.status = ApiStatus.SUCCESS
			})
			.addCase(getUserById.pending, (state) => {
				state.status = ApiStatus.PENDING
			})
			.addCase(getUserById.fulfilled, (state, action) => {
				state = Object.assign(state, action.payload, { status: ApiStatus.SUCCESS})
			})
			.addCase(getUserById.rejected, (state) => {
				state.status = ApiStatus.SUCCESS
			})
	}
})

export const {
	resetPublicUser
} = publicUserSlice.actions

export default publicUserSlice.reducer