import { Box, Grid, Stack, Typography, useTheme } from "@mui/material"
import { FORM_PROPS } from "../../helpers/FormProps"
import TextInput from "../inputs/text/TextInput"
import ChipAtom from "../atoms/chip/ChipAtom"
import { ChangeEvent, useState } from "react"
import CoverModal from "../modals/CoverModal"
import FilledButton from "../buttons/FilledButton"
import { v4 as uuidv4 } from 'uuid';
import LogoUpload from "../LogoSelect/LogoUpload"
import LogoTemplate from "../LogoSelect/LogoTemplate"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectCompanyName, selectQuickLinks, selectUploadingLogo, selectUserBio, selectUserProfession } from "../../store/reducers/user/userSelectors"
import Column from "../layouts/column/Column"
import { QuickLink, QuickLinkID } from "../../store/reducers/user/userState"
import { addQuickLink, removeQuickLink, setUserBio, setUserCompanyName, setUserProfession } from "../../store/reducers/user/userSlice"
import ChevronRightIcon from "../atoms/icons/ChevronRightIcon"

interface EditQuickLinkProps {
	onSubmit: (quickLink: QuickLink) => void;
}

const EditQuickLink = ({ onSubmit }: EditQuickLinkProps) => {

	const theme = useTheme()

	const [qlLabel, setQlLabel] = useState<string>('');
	const [qlLink, setQlLink] = useState<string>('');

	const qlValid = qlLink.length > 0

	const handleLabel = (e: ChangeEvent<HTMLInputElement>) => {
		setQlLabel(e.currentTarget.value)
	}
	const handleLink = (e: ChangeEvent<HTMLInputElement>) => {
		setQlLink(e.currentTarget.value)
	}

	const handleSubmit = () => {
		const ql: QuickLink = {
			id: uuidv4() as QuickLinkID,
			label: qlLabel,
			link: qlLink,
		}
		onSubmit(ql)
	}

	return (
		<Stack spacing={FORM_PROPS.ROW_SPACING}>
			<TextInput placeholder="https://" label="Link" value={qlLink} onChange={handleLink} />
			<TextInput placeholder="Customize text" label="Text" value={qlLabel} onChange={handleLabel} />
			<FilledButton btnStyle="rounded" size="medium" btnColor="black" alignSelf="flex-end" stretch={false} onClick={handleSubmit} disabled={!qlValid}>
				Add
			</FilledButton>
		</Stack>
	)
}

const ProfileForm = () => {

	const dispatch = useAppDispatch()

	const theme = useTheme()

	const uploadingLogo = useAppSelector(selectUploadingLogo)
	const quickLinks = useAppSelector(selectQuickLinks)
	const userCompanyName = useAppSelector(selectCompanyName)
	const userBio = useAppSelector(selectUserBio)
	const userProfession = useAppSelector(selectUserProfession)

	const handleSetCompanyName = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserCompanyName(e.currentTarget.value) )
	const handleSetProfession = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserProfession(e.currentTarget.value) )
	const handleSetBio = (e: ChangeEvent<HTMLInputElement>) => dispatch( setUserBio(e.currentTarget.value) )

	const [quickLinkModalOpen, setQuickLinkModalOpen] = useState<boolean>(false)

	const handleOpenQLModal = () => {
		setQuickLinkModalOpen(true)
	}

	const handleAddLink = (ql: QuickLink) => {
		dispatch( addQuickLink(ql) )
		setQuickLinkModalOpen(false)
	}

	const handleRemoveLink = (id: QuickLinkID) => {
		console.log('Remove',id);
		
		dispatch( removeQuickLink(id))
	}

	return (
		<Column alignItems="flex-start">
			<LogoTemplate loading={uploadingLogo} size="large" selected={true} sx={{paddingBottom: '1em'}}>
				<LogoUpload />
			</LogoTemplate>
			<TextInput placeholder="Type your name" label="Name" value={userCompanyName} onChange={handleSetCompanyName} />
			<TextInput placeholder="Type your profession" label="Profession" value={userProfession} onChange={handleSetProfession} />
			<TextInput placeholder="Type your bio" label="Bio" value={userBio} onChange={handleSetBio} multiRow={true} minRows={1} />
			<Grid sx={{ borderBottom: '1px solid rgba(0,0,0,0.42)'}} container direction="row" columnGap={FORM_PROPS.COLUMN_SPACING} rowGap={FORM_PROPS.ROW_SPACING} paddingTop={'16px'}>
				<Grid item onClick={handleOpenQLModal}>
					<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', columnGap: '0em'}}>
						<Typography variant="textInputLabel" color={theme.palette.grey[600]}>Add external links</Typography>
						<ChevronRightIcon iconSize="medium" iconColor="darkGrey" />
					</Box>
				</Grid>
			</Grid>
			<Grid container direction="row" columnGap={FORM_PROPS.COLUMN_SPACING} rowGap={FORM_PROPS.ROW_SPACING}>
				{ quickLinks.map((ql, index) => (
				<Grid item position={'relative'} key={`chip-${index}`}>
					<ChipAtom onClick={() => {}} handleDelete={() => handleRemoveLink(ql.id)} {...ql} key={`key-${index}`}/>
				</Grid>
				))}
			</Grid>
			<CoverModal
				title={'Add link'}
				isOpen={quickLinkModalOpen}
				onClose={() => setQuickLinkModalOpen(false)}>
				<EditQuickLink onSubmit={handleAddLink}/>
			</CoverModal>
		</Column>
	)
}

export default ProfileForm

{/* <Grid item><ChipAtom label="Add link" onClick={handleOpenQLModal}/></Grid> */}