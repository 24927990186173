import { Fragment, useContext, useEffect, useState, KeyboardEvent } from 'react'
import { Box, Typography, Button, Stack, TextField, CircularProgress, InputAdornment } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme, styled } from '@mui/material';
import { AuthContext } from '../../../helpers/auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import CoverModal from '../../modals/CoverModal';
import FilledButton from '../../buttons/FilledButton';
import LightningBolt from '../../atoms/lightningbolt/LightningBolt';


const VALIDATE_STATUS = {
	NOT_SENT: 'NOT_SENT',
	VALIDATING: 'VALIDATING',
	VALID: 'VALID',
	INVALID: 'INVALID',
}

const VALIDATE_CODE = (enteredCode = '') => {
	const matches = enteredCode.replace(/\D/g,"");
	if (matches.split("").reduce((part, num) => part + Number(num), 0) === 10) return true
	return false
}

const StyledTextField = styled(TextField)(({theme}) => ({
	input: {
		['&::placeholder'] : {
			color: 'black',
			opacity: 1,
		},
	}
}))

const InviteModal = () => {

	const authContext = useContext(AuthContext)

	const navigate = useNavigate()

	const { showInvite, setShowInvite, setShowCreateAccount, setValidCode } = authContext

	const [ validating, setValidating ] = useState(VALIDATE_STATUS.NOT_SENT)

	const [inviteCode, setInviteCode] = useState('');
	const [requestEmail, setRequestEmail] = useState('');

	const theme = useTheme();

	useEffect(() => {
		if (validating === VALIDATE_STATUS.VALIDATING) {
			const isValid = VALIDATE_CODE(inviteCode)
			const timeout = setTimeout(() => {
				if( isValid ) {
					setValidCode(true)
					setValidating(VALIDATE_STATUS.VALID)
				} else {
					setValidating(VALIDATE_STATUS.INVALID)
				}
			}, 1000)
			return () => {
				clearTimeout(timeout)
			}
		}
		if (validating === VALIDATE_STATUS.VALID) {
			closeModal()
			setShowCreateAccount(true)
			// const timeout = setTimeout(() => closeModal(), 0)
			// return () => {
			// 	clearTimeout(timeout)
			// }
		}
	}, [validating])

	const handleSubmit = () => {
		if (requestEmail.length > 1) {
			window.open('mailto:info@pejme.se?subject=Request code&body=Hi!%0D%0A%0D%0AI\'d like access to pejme, my email is: '+requestEmail+'%0D%0A%0D%0ACheers!')
			return
		} else {
			setValidating(VALIDATE_STATUS.VALIDATING)
		}
	}

	const closeAndRedirect = () => {
		navigate('/')
		setValidating(VALIDATE_STATUS.NOT_SENT)
		setShowInvite(false)
	}

	const closeModal = () => {
		setValidating(VALIDATE_STATUS.NOT_SENT)
		setShowInvite(false)
	}

	return(
		<CoverModal isOpen={showInvite} onClose={ closeAndRedirect}>
		<LightningBolt key={'bolt-left'} orientation='left'/>
		<LightningBolt key={'bolt-right'} orientation="right"/>
		{
			{
				'INVALID' :
				<Fragment>
					<Stack spacing={3}>
						<Typography variant='h2'>Sorry</Typography>
						<Typography variant="p" textAlign={'center'}>Wrong code</Typography>
						<Button sx={{...theme.typography.p}} variant="text" onClick={() => setValidating(VALIDATE_STATUS.NOT_SENT)}>Try again?</Button>	
					</Stack>
				</Fragment>	,
				'VALID' :
				<Fragment>
					<Stack spacing={5}>
						<Typography variant='h2'>Congrats!</Typography>
						<Typography variant="p" textAlign={'center'}>Access granted</Typography>
					</Stack>
				</Fragment>				
			}[validating] ||
				<Fragment>
					<Stack spacing={5}>
						<Stack spacing={5}>
							<Typography variant='h2'>Welcome!</Typography>
							<Box>
								<Typography variant='body2' color={theme.palette.grey[600]}>Do you have an invite?</Typography>
								<StyledTextField
									disabled={validating === VALIDATE_STATUS.VALIDATING}
									value={inviteCode}
									onChange={(e) => { setInviteCode(e.target.value)}}
									fullWidth
									placeholder="Invite code"
									type="text"
									variant="standard"
									autoComplete='off'
								/>
							</Box>
						</Stack>
						<Box>
							<Typography variant='body2' color={theme.palette.grey[600]}>Request an invite</Typography>
							<StyledTextField
								disabled={validating === VALIDATE_STATUS.VALIDATING}
								value={requestEmail}
								onChange={(e) => { setRequestEmail(e.target.value)}}
								fullWidth
								placeholder="Your email"
								type="email"
								variant="standard"
								autoComplete="email"
							/>
						</Box>
						<FilledButton
							btnStyle="rounded"
							btnColor="black"
							btnWidth="wide"
							disabled={false}
							onClick={handleSubmit}
						>
							Request
						</FilledButton>
					</Stack>
				</Fragment>
		}
		</CoverModal>
    )
}

export default InviteModal