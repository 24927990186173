import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from './themeFactory';
import { useSelector } from 'react-redux';


const CustomThemeProvider = (props) => {
	// eslint-disable-next-line react/prop-types
	const { children } = props

	const invoiceTheme = useSelector(state => state.invoice.invoiceTheme)
  
	const theme = getTheme(invoiceTheme)
  
	return (
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	)
  }
  
  export default CustomThemeProvider