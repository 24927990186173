import { ref, uploadBytes, getDownloadURL, uploadBytesResumable, StorageReference } from 'firebase/storage'
import { storage } from '../../Firebase';
import { delayMsPromise } from '../../helpers/GeneralHelper';
import { FileUploadStatus } from '../actions/fileActions';

interface UploadObjectOptions {
	fileId: string;
	folder: string;
	objectURL: string;
	userId?: string;
}

interface UploadResult {
	uploaded: boolean;
	fileId: string;
	fileURL: string;
	fullPath: string;
}

export const uploadObjectURL = async (options: UploadObjectOptions): Promise<UploadResult> => {

	const {
		fileId,
		folder,
		objectURL,
		userId,
	} = options

	try {
	  // Fetch the blob data associated with the ObjectURL
	  const response = await fetch(objectURL);
	  const blob = await response.blob();
  
	  // Create a reference to the file in the Firebase storage
	  const filePath: string = `${folder}/${userId}/${fileId}`
	  const fileRef: StorageReference = ref(storage, filePath);
  
	  // Upload the blob data using uploadBytesResumable
	  const uploadTask = uploadBytesResumable(fileRef, blob);
  
	  // Add event listeners for tracking upload progress and completion
	  return new Promise((resolve, reject) => {
		uploadTask.on('state_changed',
			(snapshot) => {
				// Status can be implemented here
				//   const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				//   statusCallback({ uploadId: fileId, uploadProgress, fileURL: '' });
			},
			(error) => {
				URL.revokeObjectURL(objectURL)
				reject(new Error(error.message));
			},
			async () => {
				const fileURL = await getDownloadURL(uploadTask.snapshot.ref);
				URL.revokeObjectURL(objectURL) 
				resolve({
					uploaded: true,
					fileId: fileId,
					fileURL: fileURL,
					fullPath: filePath,
				});
			}
		)
	  })
	} catch (error) {
		console.error(error)
	  	throw error
	}
};

// export const uploadBlobWithStatus = async (filename = 'testfile', folder = 'testFolder', blobContents: ArrayBuffer, statusCallback: (params: FileUploadStatus) => void, uploadId: string) => {
// 	try {
// 		const fileRef = ref(storage, `${folder}/${filename}`)
// 		const uploadTask = uploadBytesResumable(fileRef, blobContents)
// 		uploadTask.on('state_changed',
// 			(snapshot) => {
// 				const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
// 				statusCallback({uploadId, uploadProgress})
// 			},
// 			(error) => {
// 				throw new Error(error.message)
// 			},
// 			async () => {
// 				const fileURL = await getDownloadURL(uploadTask.snapshot.ref)
// 				statusCallback({uploadId, uploadProgress: 100, fileURL})
// 			}
// 		)
// 	} catch (error) {
// 		console.log(error)
// 	}
// }

export const uploadBlob = async (filename = 'testfile', folder = 'testFolder', blobContents: ArrayBuffer) => {
	try {
		const fileRef = ref(storage, `${folder}/${filename}`)
		await uploadBytes(fileRef, blobContents)
		const publicURL = await getDownloadURL(fileRef)
		return publicURL
	} catch (error) {
		console.error(error);
	}
}

export const awaitFileURLFromPath = async (filePath: string, maxRetries = 5, currentTry = 0): Promise<string> => {
    const fileRef = ref(storage, filePath)
    let fileURL: string = '';

    try {
        fileURL = await getDownloadURL(fileRef)
    } catch (error) {
        if (currentTry < maxRetries) {
            console.log('File not found, retrying..', currentTry);
            await delayMsPromise(10*1000)
            fileURL = await awaitFileURLFromPath(filePath, maxRetries, currentTry + 1)
        } else {
            console.error('No file found, give up');
            throw new Error('Thumbnail not found')
        }
    } finally {
        console.log('finally', fileURL);
        return fileURL
    }
}