import { IS_EMAIL, IS_ORG, IS_STRING, IS_VAT, IS_ARRAY, IS_NUMBER, paymenOptionValidation, VALID_PARAMS } from './validationTypes';

export const VALIDATE_INVOICE_RULES = {
	items: {
		req: true,
		func: IS_ARRAY,
	  },
	  totalAmount: {
		req: true,
		func: IS_NUMBER,
	  },
	  paymentOptions: {
		req: true,
		func: paymenOptionValidation,
	  },
	  invoiceNumber: {
		req: true,
		func: IS_STRING,
	  },
}

export const VALIDATE_USER_RULES = {
    companyName: {
		req: true,
		func: IS_STRING,
	},
	email: {
		req: true,
		func: IS_EMAIL,
	},
	address: {
		req: true,
		func: IS_STRING,
	},
	postalCode: {
		req: true,
		func: IS_STRING,
	},
	city: {
		req: true,
		func: IS_STRING,
	},
	ORGnumber: {
		req: true,
		func: IS_ORG,
	},
	VATnumber: {
		req: true,
		func: IS_VAT,
	},
	country: {
		req: true,
		func: IS_STRING,
	},
	defaultPaymentOptions: {
		req: true,
		func: paymenOptionValidation,
	}
}

export const VALIDATE_PUBLIC_USER_RULES = {
    companyName: {
		req: true,
		func: IS_STRING,
	},
	address: {
		req: true,
		func: IS_STRING,
	},
	postalCode: {
		req: true,
		func: IS_STRING,
	},
	city: {
		req: true,
		func: IS_STRING,
	},
	country: {
		req: true,
		func: IS_STRING,
	},
	email: {
		req: true,
		func: IS_EMAIL,
	},
}

export const VALIDATE_CUSTOMER_RULES = {
	companyName: {
		req: true,
		func: IS_STRING,
	  },
	  email: {
		req: true,
		func: IS_STRING,
	  },
	  address: {
		req: true,
		func: IS_STRING,
	  },
	  postalCode: {
		req: true,
		func: IS_STRING,
	  },
	  city: {
		req: true,
		func: IS_STRING,
	  },
	  ORGnumber: {
		req: true,
		func: IS_ORG,
	  },
}

export const VALIDATE_MESSAGE_RULES = {
	messageTitle: {
		req: true,
		func: IS_STRING,
	},
	messageBody: {
		req: true,
		func: IS_STRING,
	},
	recipientEmail: {
		req: true,
		func: IS_EMAIL,
	},
}

export const VALID_CUSTOMER_TEST = () => {
	let validObject = {}
	Object.keys(VALIDATE_CUSTOMER_RULES).forEach((key) => {
		let item = VALIDATE_CUSTOMER_RULES[key]
		if (item.req) {
			const funcToUse = item.func.name
			validObject[key] = VALID_PARAMS[funcToUse]()
		}
	})
	return validObject
}

export const VALID_PUBLIC_USER_TEST = () => {
	let validObject = {}
	Object.keys(VALIDATE_PUBLIC_USER_RULES).forEach((key) => {
		let item = VALIDATE_PUBLIC_USER_RULES[key]
		if (item.req) {
			const funcToUse = item.func.name
			validObject[key] = VALID_PARAMS[funcToUse]()
		}
	})
	return validObject
}

export const VALID_USER_TEST = () => {
	let validObject = {}
	Object.keys(VALIDATE_USER_RULES).forEach((key) => {
		let item = VALIDATE_USER_RULES[key]
		if (item.req) {
			const funcToUse = item.func.name
			validObject[key] = VALID_PARAMS[funcToUse]()
		}
	})
	return validObject
}

export const VALID_INVOICE_TEST = () => {
	let validObject = {}
	Object.keys(VALIDATE_INVOICE_RULES).forEach((key) => {
		let item = VALIDATE_INVOICE_RULES[key]
		if (item.req) {
			const funcToUse = item.func.name
			validObject[key] = VALID_PARAMS[funcToUse]()
		}
	})
	return validObject
}

export const INVALID_CUSTOMER_TEST = () => {

	const generate = () => {
		let hasFailed = false
		let validObject = {}
		Object.keys(VALIDATE_CUSTOMER_RULES).forEach((key, index) => {
			if (Math.round(Math.random()) > 0) {
				hasFailed = true
				return
			}
			let item = VALIDATE_CUSTOMER_RULES[key]
			if (item.req) {
				const funcToUse = item.func.name
				validObject[key] = VALID_PARAMS[funcToUse]()
			}
		})
		if (hasFailed) return validObject
		return generate()
	}

	const validObject = generate()

	return validObject
}



export const INVALID_USER_TEST = () => {
	
	const generate = () => {
		let hasFailed = false
		let validObject = {}
		Object.keys(VALIDATE_USER_RULES).forEach((key) => {
			if (Math.round(Math.random()) > 0) {
				hasFailed = true
				return
			}
			let item = VALIDATE_USER_RULES[key]
			if (item.req) {
				const funcToUse = item.func.name
				validObject[key] = VALID_PARAMS[funcToUse]()
			}
		})
		if (hasFailed) return validObject
		return generate()
	}

	const validObject = generate()

	return validObject
}



export const INVALID_INVOICE_TEST = () => {
	const generate = () => {
		let hasFailed = false
		let validObject = {}
		Object.keys(VALIDATE_INVOICE_RULES).forEach((key) => {
			if (Math.round(Math.random()) > 0) {
				hasFailed = true
				return
			}
			let item = VALIDATE_INVOICE_RULES[key]
			if (item.req) {
				const funcToUse = item.func.name
				validObject[key] = VALID_PARAMS[funcToUse]()
			}
		})
		if (hasFailed) return validObject
		return generate()
	}

	const validObject = generate()

	return validObject
}