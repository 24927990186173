import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../Firebase";
import { User } from "../user/userState";

type GetUserBySlugArgs = string;
type GetUserBySlugResp = User;

export const getUserBySlug = createAsyncThunk<GetUserBySlugResp, GetUserBySlugArgs>(
	'publicUser/getslug',
	async (slug, thunkAPI) => {
		try {
			const userColl = collection(db, 'users')
			const userQuery = query(userColl, where('slug', '==', slug))
			const snapshot = await getDocs(userQuery)
			if (snapshot.size < 1) return thunkAPI.rejectWithValue('No user found')
			if (snapshot.size > 1) {
				console.log('Multiple users found')
			}
			const userDoc = {
				id: snapshot.docs[0].id,
				...snapshot.docs[0].data()
			} as GetUserBySlugResp;
			return userDoc
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
)

type GetUserByIdArgs = string;
type GetUserByIdResp = User;

export const getUserById = createAsyncThunk<GetUserByIdResp, GetUserByIdArgs>(
	'publicUser/getid',
	async (userId, thunkAPI) => {
		try {
			const docRef = doc(db, 'users', userId)
			const docSnap = await getDoc(docRef)
			if (!docSnap.exists()) return thunkAPI.rejectWithValue('No user found')
			const userDoc = {
				id: docSnap.id,
				...docSnap.data()
			} as GetUserByIdResp
			return userDoc
		} catch (error) {
			console.log(error);
			throw error;
		}
	}
)