import { keyframes } from "@mui/system";

const FrameFlow = keyframes`
    0%{background-position:97% 0%}
    50%{background-position:4% 100%}
    100%{background-position:97% 0%}
`

export const FrameAnimation = (colors = '#ff6aa7, #ff9300') => ({
    background: `radial-gradient(circle, ${colors})`,
    backgroundSize: '200% 200%',
    webkitAnimation: `${FrameFlow} 15s ease infinite`,
    mozAnimation: `${FrameFlow} 15s ease infinite`,
    oAnimation: `${FrameFlow} 15s ease infinite`,
    animation: `${FrameFlow} 15s ease infinite`,
  })