import { ItemID } from "../../types/itemTypes";
import { ApiStatus } from "../../types/storeTypes";

export type Highlight = {
	items: ItemID[];
	id?: string;
}

interface InitialHighlightState extends Highlight {
	status: ApiStatus;
}

const INITIAL_STATE: InitialHighlightState = {
	items: [],
	status: ApiStatus.SUCCESS,
}

export const highlightState = () => INITIAL_STATE