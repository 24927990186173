import { createListenerMiddleware } from "@reduxjs/toolkit";
import { validateCustomer, clearValidation as clearValidationCustomer } from "../reducers/customer/customerSlice";
import { validateUser, clearValidation as clearValidationUser } from "../reducers/user/userSlice";
import { validateInvoice, clearValidation as clearValidationInvoice } from "../reducers/invoice/invoiceSlice";

const formValidationMiddleware = createListenerMiddleware()

formValidationMiddleware.startListening({
	type: 'customer/create/pending',
	effect: async (action, listenerApi) => {
		listenerApi.dispatch( validateCustomer() )
	}
})

formValidationMiddleware.startListening({
	type: 'user/create/pending',
	effect: async (action, listenerApi) => {
		listenerApi.dispatch( validateUser() )
	}
})

formValidationMiddleware.startListening({
	type: 'invoice/create/pending',
	effect: async (action, listenerApi) => {
		listenerApi.dispatch( validateInvoice() )
	}
})

formValidationMiddleware.startListening({
	type: 'invoice/generate/pending',
	effect: async (action, listenerApi) => {
		listenerApi.dispatch( validateCustomer() )
		listenerApi.dispatch( validateUser() )
		listenerApi.dispatch( validateInvoice() )
	}
})

formValidationMiddleware.startListening({
	type: 'misc/clearValidation',
	effect: async (action, listenerApi) => {
		const { payload: { slice, prop } } = action
		switch (slice) {
			case 'invoice':
				listenerApi.dispatch( clearValidationInvoice(prop) )
				break;
			case 'user':
				listenerApi.dispatch( clearValidationUser(prop) )
				break;
			case 'customer':
				listenerApi.dispatch( clearValidationCustomer(prop) )
				break
			default:
				console.warn('Clear validation did not match a reducer.')
				break;
		}
	}
})

export default formValidationMiddleware;