import { v4 as uuidv4 } from 'uuid';
import { UploadTask, UploadedFile, defaultFile } from './fileTypes';
import { Item, ItemID } from './itemTypes';

export enum MessageStatus {
	DRAFT = "DRAFT",
	SENT = "SENT"
}

export const isMessageFilter = (i: Message | any): i is Message => i.type === "Message";

export interface Message {
	id: string;
	type: "Message";
	template: MessageTypes;
	uploads: UploadTask[];
	files: UploadedFile[]; // Specify the type of files array
	subject: string;
	messageTitle: string;
	recipientEmails: string[];
	recipientCustomers: string[];
	fields: FieldType[];
	status: MessageStatus;
}

export const defaultMessage = ({ }: Partial<Message>): Message => {
	const defaultMessage: Message = defaultShareItemsMessage({});
	return defaultMessage;
};

export type MessageTypes = 'InviteMessage' | 'ShareItemsMessage' | 'OtherMessage'

export type TitleFieldType = {
	fieldKey: string;
	fieldTitle: string;
	inputType: 'title',
	fieldValue: string,
}

export type SubTitleFieldType = {
	fieldKey: string;
	fieldTitle: string;
	inputType: 'subtitle',
	fieldValue: string,
}

export type BodyFieldType = {
	fieldKey: string;
	fieldTitle: string;
	inputType: 'textbox';
	fieldValue: string;
}

type CtaFieldValue = {
	link: string;
	copy: string;
}

export type CtaFieldType = {
	fieldKey: string;
	inputType: 'cta';
	fieldValue: CtaFieldValue;
}

export type FileUploadFieldType = {
	fieldKey: string;
	inputType: 'upload';
	fieldValue: UploadedFile;
}

export type FileUploadListFieldType = {
	fieldKey: string;
	inputType: 'uploadList';
	fieldValue: UploadedFile[];
}

export type ItemListFieldType = {
	fieldKey: string;
	inputType: 'itemList';
	fieldValue: ItemID[];
}

export type FieldType = TitleFieldType | SubTitleFieldType | BodyFieldType | CtaFieldType | FileUploadFieldType | FileUploadListFieldType | ItemListFieldType;

const generateTitle = (fieldKey: string):TitleFieldType => ({
	fieldKey,
	fieldTitle: 'Headline',
	inputType: 'title',
	fieldValue: '',
})

const generateSubTitle = (fieldKey: string):SubTitleFieldType => ({
	fieldKey,
	fieldTitle: 'Headline',
	inputType: 'subtitle',
	fieldValue: '',
})

const generateBody = (fieldKey: string):BodyFieldType => ({
	fieldKey,
	fieldTitle: 'Bodytext',
	inputType: 'textbox',
	fieldValue: '',
})

const generateCta = (fieldKey: string):CtaFieldType => ({
	fieldKey,
	inputType: 'cta',
	fieldValue: {
		link: '',
		copy: '',
	},
})

const generateFileUpload = (fieldKey: string):FileUploadFieldType => ({
	fieldKey,
	inputType: 'upload',
	fieldValue: defaultFile()
})

const generateFileUploadList = (fieldKey: string):FileUploadListFieldType => ({
	fieldKey,
	inputType: 'uploadList',
	fieldValue: [],
})

const generateItemList = (fieldKey: string):ItemListFieldType => ({
	fieldKey,
	inputType: 'itemList',
	fieldValue: [],
})


export const defaultInviteMessage = ({}: Partial<Message>): Message => {

	const defaultMessage: Message = {
		id: uuidv4(),
		type: 'Message',
		subject: '',
		uploads: [],
		files: [],
		recipientEmails: [],
		recipientCustomers: [],
		template: 'InviteMessage',
		messageTitle: '',
		fields: [
			generateTitle('messageTitle'),
			generateSubTitle('messageIntro'),
			generateFileUpload('headerImage'),
			generateSubTitle('messageSubtitle'),
			generateBody('messageBody'),
			generateCta('cta'),
		],
		status: MessageStatus.DRAFT,
	}
	return defaultMessage
}

export const defaultShareItemsMessage = ({}: Partial<Message>): Message => {

	const defaultMessage: Message = {
		id: uuidv4(),
		type: 'Message',
		subject: '',
		uploads: [],
		files: [],
		recipientEmails: [],
		recipientCustomers: [],
		template: 'ShareItemsMessage',
		messageTitle: '',
		fields: [
			generateTitle('messageTitle'),
			generateBody('messageBody'),
			generateItemList('items'),
			generateCta('cta'),
		],
		status: MessageStatus.DRAFT,
	}
	return defaultMessage
}