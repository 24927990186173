export const greenBlue = {
	palette: {
		primary: {
		  light: '#ffffff',
		  main: '#d9ead3',
		  dark: '#a7b8a2',
		  contrastText: '#000000'
		},
		secondary: {
		  light: '#ffffff',
		  main: '#cfe2f3',
		  dark: '#9eb0c0',
		  contrastText: '#000000',
		},
		black: {
			main: '#000000'
		},
		misc: {
			error: "#d32f2f",
		},
		frame: {
			col1: '#d9ead3',
			col2: '#cfe2f3',
			mid: '#D4E6E3',
		},
		pinkOrange: {
		  col1: '#dce775',
		  col2: '#3949ab',
		  mid: '#FF7F54',
		},
	  },
}