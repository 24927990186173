import { ShareItemsEmail, ShareItemsEmailData } from "../../types/emailTypes"
import { BodyFieldType, CtaFieldType, ItemListFieldType, TitleFieldType } from "../../types/messageTypes";
import { v4 as uuid } from "uuid";
import { User } from "../user/userState";

type makeMessageProps = {
	user: User;
	preview: boolean;
}

interface makeShareItemsMessageProps extends ShareItemsEmailData, makeMessageProps { }

export const makeShareItemsMessage = ({message, items, preview, user}: makeShareItemsMessageProps): ShareItemsEmail => {

	if (message.template !== 'ShareItemsMessage') throw new Error('Template mismatch')

	let baseEmailData: Partial<ShareItemsEmail> = {
		id: uuid(),
		messageId: message.id,
		to: preview ? ['r.svartholm@gmail.com'] : message.recipientEmails,
		replyTo: '',
		from: user.email,
		user: user.id,
		subject: message.subject,
	}

	const title = message.fields.find((field) => field.fieldKey === 'messageTitle') as TitleFieldType
	const body = message.fields.find((field) => field.fieldKey === 'messageBody') as BodyFieldType
	const messageItems = message.fields.find((field) => field.fieldKey === 'items') as ItemListFieldType
	const cta = message.fields.find((field) => field.fieldKey === 'cta') as CtaFieldType

	const itemsToSend = messageItems.fieldValue.map((msgItemId) => {
		const item = items.find((item) => item.id === msgItemId)
		if (!item) throw new Error('Item not found')
		return {
			src: item.files[0].fileURL,
			description: item.description,
		}
	})

	return {
		...baseEmailData,
		template: {
			name: 'shareItem',
			data: {
				subject: title.fieldValue,
				description: title.fieldValue,
				messageTitle: title.fieldValue,
				messageBody: body.fieldValue,
				items: itemsToSend,
				ctaLink: cta.fieldValue.link,
				ctaCopy: cta.fieldValue.copy,
			}
		}
	} as ShareItemsEmail

}