import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../reducers';
import { Item, ItemID } from '../../types/itemTypes';

const itemSlice = (state: RootState) => state.items;

const selectItemsArray = (state: RootState) => state.items.items;

// Define the ID you want to search for
const selectObjectId = (state: RootState, id: ItemID): ItemID => id;
const selectObjectIds = (state: RootState, ids: ItemID[]): ItemID[] => ids;

export const selectCurrentItem = createSelector(
	[itemSlice],
	(items) => items.item
)

export const selectItemLoading = createSelector(
	[itemSlice],
	(items) => items.status
)

export const selectSortedItems = createSelector(
	[selectItemsArray],
	(items: Item[]) => {
		return items.sort((a, b) => a.created - b.created)
	}
)

export const selectItemsWithFile = createSelector(
	[selectItemsArray],
	(items: Item[]) => {
		const itemsWithFiles = items.filter((item: Item) => {
			if (item.files.length < 1) return false
			const hasFiles = item.files[0].fileURL ? true : false
			return hasFiles
		})
		return itemsWithFiles
	}
)

export const selectSortedItemsWithFile = createSelector(
	[selectItemsWithFile],
	(items) => items.sort((a,b) => {
		const aCreated = a.created ? a.created : 1704063600
		const bCreated = b.created ? b.created : 1704063600
		return bCreated - aCreated
	})
)

export const selectItemsExcludeItem = createSelector(
	[selectObjectId, selectItemsWithFile],
	(id, items) => {
		return items.filter((item: Item) => item.id !== id )
	}
)

// Selector to find the object by ID
export const selectItemById = createSelector(
  [selectItemsArray, selectObjectId],
  (itemsArray, id) => {
    return itemsArray.find(item => item.id === id);
  }
);

export const selectItemsById = createSelector(
	[selectItemsArray, selectObjectIds],
	(itemsArray, ids) => {
		return itemsArray.filter((item) =>  ids.includes(item.id))
	}
)

export const getIndexItemDescription = createSelector(
	[selectItemById],
	(item) => {
		return item?.description
	}
)

export const getCurrentItemUploads = createSelector(
    [selectCurrentItem],
    (item) => item.uploads
);

export const getCurrentItemFiles = createSelector(
    [selectCurrentItem],
    (item) => item.files
);

export const getCurrentItemDescription = createSelector(
	[selectCurrentItem],
	(item) => item.description
)

export const getCurrentItemAmountExclVAT = createSelector(
    [selectCurrentItem],
    (item) => item.amountExclVAT
);

export const getCurrentItemCurrentVAT = createSelector(
    [selectCurrentItem],
    (item) => item.currentVAT
);

export const getCurrentItemItemVAT = createSelector(
    [selectCurrentItem],
    (item) => item.itemVAT
);

export const getCurrentItemDiscount = createSelector(
    [selectCurrentItem],
    (item) => item.discount
);

export const getCurrentItemItemAmount = createSelector(
    [selectCurrentItem],
    (item) => item.itemAmount
);

export const getCurrentItemNetAmountExclVAT = createSelector(
    [selectCurrentItem],
    (item) => item.itemNetAmountExclVAT
);

export const getItemIdDescription = createSelector(
	[selectItemById],
	(item) => item?.description
)

export const selectHasItemsUploaded = createSelector(
	[selectItemsWithFile],
	(items) => items.length > 0 ? true : false
)